import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {getIconButtonStyle} from "../ConfigActionUtils";
import React, {useState} from "react";
import { config_options } from "../../../../../../store/dashboardConfigsSlice";
import { Modal, Box, Typography } from "@mui/material";
import { generateNewCreateEntryForm, generateSubmittedCreateEntryForm, renderForm } from "./renderForm";
import DirectRequest from "../../../../../../API/requests/DirectRequest";
import { acct_fields, form_fields } from "./createEntryForm";
import { useSelector, useDispatch } from "react-redux";
import iso8601Timestamp from "../../../../../../helpers/iso8601Timestamp";
import { setAlertNotificationArgs } from "../../../../../../store/alertsNotificationSlice";
import * as Constants from "../../../../../../Constants";
import CheckIcon from '@mui/icons-material/Check';
import { objInBody } from "../../DisplayConfigUpdateUtils";

const spanStyle = {fontFamily: "Barlow Semi Condensed, Arial, sans-serif", fontSize: '10px', lineHeight: "10px"}

const CreateEntryIcon = ({ disableActions, overridden, edit }) => {
  return (
    <Typography
      sx={{
        color: disableActions ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 0, 0, 0.54)",
        fontWeight: 600,
        border: `2px solid ${disableActions ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 0, 0, 0.54)"}`,
        borderRadius: "4px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "28px",
        width: "34px"
      }}
      component={"div"}
    >
      {overridden && (
        <span style={spanStyle}><CheckIcon/></span>
      )}
      {!overridden && !edit && (
        <>
          <span style={spanStyle}>Review</span>
          <span style={spanStyle}>draft</span>
        </>
      )}
      {!overridden && edit && (
        <span style={spanStyle}>Edit</span>
      )}
    </Typography>
  )
}

const ClientEmailManager = ({ getClientEmailArgs, handleClientEmail, row, displayConfigTableRow }) => {
  const dashboardUuid = useSelector(state => state.dashboard.uuid)
  const isInternal = useSelector((state) => state.role.isInternal);
	const user = useSelector((state) => state.role.name);
  const [missingAttributesNotificationArgs, setMissingAttributesNotificationArgs] = useState(null);
  const checkClientEmail = (res) => {
    if (res && res.file) {
      console.log("returned client email", res.file);
      const clientEmail = {...res.file}
      if (!isInternal && clientEmail.aiReviewState && clientEmail.aiReviewState === "llm_processed") {
        console.log("checking client email for attributes");
        /*
        create notification args if needed:
        if *attributes.adjustmentNeeded* is not present - display no info message and email team
        if *attributes.adjustmentNeeded* is present and true but *attributes.variance* is not present - display no info message and email team
        if *attributes.adjustmentNeeded* is present and false - alter info message to say "xyz@domain.com confirmed {getTimeAgo}"
        */
        const tempArgs = {
          url: Constants.SERVER_SEND_EMAIL,
          method: "POST",
          body: null
        }
        if (!clientEmail.attributes) {
          // missing attributes notification
          tempArgs.body = JSON.stringify({
            "body": `${user}'s clientEmail for dashboard ${dashboardUuid} is missing attributes.<br/><br/>
              clientEmail:<br/>${objInBody(clientEmail)}<br/><br/> 
              row:<br/>${objInBody(row)}<br/><br/>
              displayConfigTableRow:<br/>${objInBody(displayConfigTableRow)}<br/><br/>`,
            "subject": `${user}'s clientEmail is missing attributes (${window.location.origin} - ${iso8601Timestamp()})`,
            "from": "noreply@wiselayer.com",
            "toEmail": Constants.notificationList.join(","),
            "sendHTML": true
          })
          setMissingAttributesNotificationArgs(tempArgs)
        } else if (clientEmail.attributes && !Object.keys(clientEmail.attributes).includes("adjustmentNeeded")) {
          // missing attributes.adjustmentNeeded notification
          tempArgs.body = JSON.stringify({
            "body": `${user}'s clientEmail.attributes for dashboard ${dashboardUuid} is missing adjustmentNeeded.<br/><br/>
              clientEmail:<br/>${objInBody(clientEmail)}<br/><br/> 
              row:<br/>${objInBody(row)}<br/><br/>
              displayConfigTableRow:<br/>${objInBody(displayConfigTableRow)}<br/><br/>`,
            "subject": `${user}'s clientEmail.attributes is missing adjustmentNeeded (${window.location.origin} - ${iso8601Timestamp()})`,
            "from": "noreply@wiselayer.com",
            "toEmail": Constants.notificationList.join(","),
            "sendHTML": true
          })
          setMissingAttributesNotificationArgs(tempArgs)
        } else if (clientEmail.attributes && clientEmail.attributes.adjustmentNeeded && !Object.keys(clientEmail.attributes).includes("variance")) {
          // attributes.adjustmentNeeded === "true" && !attributes.variance notification
          tempArgs.body = JSON.stringify({
            "body": `${user}'s clientEmail.attributes for dashboard ${dashboardUuid} is missing variance.<br/><br/>
              clientEmail:<br/>${objInBody(clientEmail)}<br/><br/> 
              row:<br/>${objInBody(row)}<br/><br/>
              displayConfigTableRow:<br/>${objInBody(displayConfigTableRow)}<br/><br/>`,
            "subject": `${user}'s clientEmail.attributes is missing variance (${window.location.origin} - ${iso8601Timestamp()})`,
            "from": "noreply@wiselayer.com",
            "toEmail": Constants.notificationList.join(","),
            "sendHTML": true
          })
          setMissingAttributesNotificationArgs(tempArgs)
        }
      }
      handleClientEmail(clientEmail)
    }
  }
  return (
    <>
      <DirectRequest
        requestArgs={getClientEmailArgs}
        afterProcess={checkClientEmail}
      />
      <DirectRequest
        requestArgs={missingAttributesNotificationArgs}
        afterProcess={() => console.log("client email missing attributes notification sent")}
      />
    </>
  )
}

const CreateEntryFormWrapper = ({handleSubmit, handleDelete, handleClose, displayConfigTableRow, createEntryConfig, row}) => {
  const companyUuid = useSelector(state => state.dashboard.companyUuid);
  const [clientEmail, setClientEmail] = useState(null);
  const [getClientEmailArgs, setGetClientEmailArgs] = useState(() => {
    if (displayConfigTableRow && displayConfigTableRow.displayConfigOptions && displayConfigTableRow.displayConfigOptions[config_options.send_email]?.optionValue) {
      const tempArgs = {
        // TODO: write args - NEED NEW ENDPOINT FOR EMAIL RES
        url: companyUuid ? Constants.SERVER_SYSADMIN_GET_CLIENT_EMAIL_URL + companyUuid : Constants.SERVER_GET_CLIENT_EMAIL_URL,
        method: "POST",
        body: JSON.stringify({
          clientEmailUuid: displayConfigTableRow.displayConfigOptions[config_options.send_email]?.optionValue
        })
      }
      return tempArgs;
    } else {
      // return null;
      setClientEmail({ uuid: "" });
    }
  });
  
  const handleClientEmail = (returnedEmail) => {
    // console.log("returnedEmail", returnedEmail)
    // on a new create entry form supplant the value from this res

    // on a previously submitted create entry from, just make note of value update on form

    // we can set this value as emailValueUpdate and pass to renderForm 
    //  - if isPreviouslySubmitted we just display suggested update value from vendor
    //  - if !isPreviouslySubmitted we update the values and display suggested update value from vendor
    // if (res.file) {
    //   const returnedEmail = {...res.file};
    //   console.log("returned client email", returnedEmail);
    //   /*
    //   String uuid;
    //   String companyUuid;
    //   String developerAppUuid;
    //   String uniqueInteractionId;
    //   String displayConfigRowUuid; // Todo: swap this to the option id once refactor is merged in
    //   String entryPeriod;
    //   String recipientEmailAddress;
    //   List<String> additionalCcEmailAddresses;
    //   String subject;
    //   String body; ----------------------------------------- WILL BE NULL
    //   String responseBody; --------------------------------- WILL BE NULL
    //   Map<String, String> attributes; => ""
    //   String aiReviewState; => "llm_processed"
    //   String aiReviewStatus;
    //   String statusToChangeToOnAiResponse;
    //   Timestamp emailSendTimestamp;
    //   Timestamp emailResponseTimestamp;
    //   Date createdOn;
    //   Date modifiedOn;
    //   String createdBy;
    //   */
    //   setClientEmail(returnedEmail)
    // }
    setClientEmail(returnedEmail)
  }

  return (
    <>
      {/* <DirectRequest
        requestArgs={getClientEmailArgs}
        afterProcess={handleClientEmail}
      /> */}
      <ClientEmailManager
        getClientEmailArgs={getClientEmailArgs}
        handleClientEmail={handleClientEmail}
        row={row}
        displayConfigTableRow={displayConfigTableRow}
      />
      {clientEmail && (
        <CreateEntryForm
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          handleClose={handleClose}
          displayConfigTableRow={displayConfigTableRow}
          createEntryConfig={createEntryConfig}
          row={row}
          clientEmail={clientEmail}
        />
      )}
    </>
  )
}

const CreateEntryForm = ({handleSubmit, handleDelete, handleClose, displayConfigTableRow, createEntryConfig, row, clientEmail}) => {
  const tables = useSelector((state) => state.dashboard.tables);
  const companyUuid = useSelector((state) => state.dashboard.companyUuid)

  const [getSubmittedEntryArgs, setGetSubmittedEntryArgs] = useState(null);
  
  const [formState, setFormState] = useState(() => {
    if (displayConfigTableRow && displayConfigTableRow.displayConfigOptions && displayConfigTableRow.displayConfigOptions[config_options.create_entry]?.optionValue) {
      // Get created entry for this row - "create_entry" value here will be an entry uuid
      const tempArgs = {
        url: companyUuid ? Constants.SERVER_SYSADMIN_POST_GET_JOURNAL_ENTRY_URL + companyUuid : Constants.SERVER_POST_GET_JOURNAL_ENTRY_URL,
        method: "POST",
        body: JSON.stringify({
          erpEntryUuid: displayConfigTableRow.displayConfigOptions[config_options.create_entry].optionValue
        })
      }
      setGetSubmittedEntryArgs(tempArgs);
    } else {
      // Generate and return new form state
      return generateNewCreateEntryForm(createEntryConfig, row, tables, clientEmail);
    }
  });
  
  const handleSubmittedEntry = (res) => {
    // populate formState from previously submitted entry
    console.log("handleSubmittedEntry - res", res);
    setFormState(generateSubmittedCreateEntryForm(createEntryConfig, res.file, row, tables, clientEmail));
  }
  
  return (
    <>
      <DirectRequest
        requestArgs={getSubmittedEntryArgs}
        afterProcess={handleSubmittedEntry}
      />
      {formState && renderForm(formState, setFormState, handleSubmit, handleDelete, handleClose, createEntryConfig)}
    </>
  )
}

const rowAccountNameFlag = (createEntryConfig, row, tables) => {
  let result = false;
  let message = "";
  const unmatchedAccountNames = [];
  const {
    debitAccountNameListColumn,
    creditAccountNameListColumn,
    accountMappingConfig 
  } = createEntryConfig;
  // now we need to check both debitAccountNames and creditAccountNames against createEntryConfig.accountMappingConfig
  const debitAccountNameList = row[debitAccountNameListColumn].split(";") ?? [];
  const creditAccountNameList = row[creditAccountNameListColumn].split(";") ?? [];
  const allAccountNames = [...debitAccountNameList, ...creditAccountNameList];
   /*
      "accountMappingConfig": {
              "accountMappingTableName": "account_mapping_table",
			        "accountMappingIdColumnName": "account_id",
			        "accountMappingNameColumnName": "account_name"
            }
   */
  if (accountMappingConfig) {
    const {
      accountMappingTableName,
      accountMappingNameColumnName
    } = accountMappingConfig;
    const accountNamesTable = tables[accountMappingTableName]?.data
    if (accountNamesTable) {
      // ok we have a table -> now get the index position of the names
      const accountNameIndex = accountNamesTable[0].indexOf(accountMappingNameColumnName);
      console.log("accountNamesTable", accountNamesTable);
      if (accountNameIndex === -1) {
        // passed column name not in passed table
        result = true;
        message = `createEntryConfig.accountMappingConfig.accountMappingNameColumnName (${accountMappingNameColumnName}) does not match any columns in accountMappingTableName (${accountMappingTableName})`
      } else {
        // ok now we know the index position -> map out a list if there's rows
        const accountNamesAndIds = accountNamesTable.slice(1);
        if (accountNamesAndIds.length === 0) {
          result = true;
          message = `accountMappingTableName (${accountMappingTableName}) has no rows`;
        } else {
          const allowedAccountNames = accountNamesAndIds.map((row) => {
            return row[accountNameIndex];
          })
          allAccountNames.forEach((accountName) => {
            // if (result) return;// flag has already been tripped - need to show all unmatched names
            if (!allowedAccountNames.includes(accountName)) {
              result = true;
              message = `Some row level account names did not match any names in accountMappingTableName (${accountMappingTableName})`;
              unmatchedAccountNames.push(accountName);
            }
          })
        }
      }
    } else {
      // passed table name not in passed tables
      result = true;
      message = `createEntryConfig.accountMappingConfig.accountMappingTableName (${accountMappingTableName}) does not match any dashboard tables`
    }
  } else {
    result = true;
    message = "No createEntryConfig.accountMappingConfig found"
  }
  
  return {
    flag: result,
    message: message,
    unmatchedAccountNames: unmatchedAccountNames
  }
}

const generateAccountMappingConfigErrorNotification = (appUuid, message, unmatchedAccountNames, user, row) => {
  const rowInBody = Object.entries(row).map(([key, value]) => `${key}: ${value}<br/>`).join("");
  const getUnmatchedAccountNames = (accountNames) => {
    console.log("getUnmatchedAccountNames -> unmatchedAccountNames", unmatchedAccountNames);
    if (accountNames.length === 0) {
      return "";
    } else {
      return `Unmatched account names: ${accountNames.map(name => `"${name}"`).join(", ")}<br/><br/><br/>`;
    }
  }
  // notification of account mapping error
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": `Message: ${message}<br/><br/><br/>${getUnmatchedAccountNames(unmatchedAccountNames)}Row:<br/>${rowInBody}<br/><br/>App: ${appUuid} on ${window.location.href}<br/><br/>`,
      "subject": `accountMappingConfig error for ${user} viewing ${appUuid} on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  console.log("generateAccountMappingConfigErrorNotification -> tempArgs", tempArgs);
  console.log("body:", JSON.parse(tempArgs.body));
  return tempArgs;
}

export const CreateEntryConfigActionButton = ({handleConfigUpdate, configAction, displayConfigTableRow, createEntryConfig, statusTrackerConfig, disableActions, row, sourceTable}) => {
    const dispatch = useDispatch();
    const dashboardUuid = useSelector(state => state.dashboard.uuid);
    const tables = useSelector(state => state.dashboard.tables);
    const user = useSelector(state => state.role.name);
    const isInternal = useSelector((state) => state.role.isInternal);
    const companyUuid = useSelector(state => state.dashboard.companyUuid);

    const [open, setOpen] = useState(false);

    const handleToggle = () => {
      if (!createEntryConfig) return;
      setOpen((prevOpen) => !prevOpen);
      // now we check to see if all row account names (createEntryConfig.debitAccountNameListColumn && createEntryConfig.creditAccountNameListColumn) 
      // are in createEntryConfig.accountMappingConfig via rowAccountNameFlag
      const {
        flag,
        message,
        unmatchedAccountNames
      } = rowAccountNameFlag(createEntryConfig, row, tables);
      if (flag && !isInternal && !companyUuid) {
        // generate email notification if there's an issue with config refs and table values
        const accountMappingConfigErrorNotification = generateAccountMappingConfigErrorNotification(dashboardUuid, message, unmatchedAccountNames, user, row);
        dispatch(setAlertNotificationArgs(accountMappingConfigErrorNotification));
      }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (formState) => {
        /*
        handleConfigUpdate create entry args:
        {
          "displayConfigTableUuid": String,
          "primaryKeyValueMap": Map<String, String>,
          "displayConfigOptionName": String,
          "displayConfigOptionValue": Object,
          "rowConfigSetupInfo": {
            "tranDate": String,
            "postingPeriod": String,
            "journalMemo": String,
            "journalLineDebitAccountIds": String (semicolon-separated),
            "journalLineDebitAccountNames": String (semicolon-separated),
            "journalLineDebitAmounts": String (semicolon-separated),
            "journalLineCreditAccountIds": String (semicolon-separated),
            "journalLineCreditAccountNames": String (semicolon-separated),
            "journalLineCreditAmounts": String (semicolon-separated),
            "addReversalFlag": String,
            "reversalPostingPeriodId": String,
            "entryPeriod": String
          }
        }
        */
       // newConfigValue = { entryUuid, rowConfigSetupInfo } --> this is all you pass up to handleConfigUpdate (parameter as: newConfigValue)
      const entryUuid = displayConfigTableRow ? displayConfigTableRow.displayConfigOptions[config_options.create_entry]?.optionValue ?? "" : "";
      const rowConfigSetupInfo = {};
      rowConfigSetupInfo["sourceTableName"] = sourceTable;
      rowConfigSetupInfo["tranDate"] = row[createEntryConfig["jeTranDateColumn"]];
      if(createEntryConfig.periodMappingConfig){
        // IF periodMappingConfig -> periodNameToIdMap was generated for the form - use the periodNameToIdMap and their selection
        const postingPeriod = formState[form_fields.periodNameToIdMap][formState.postingPeriod];
        rowConfigSetupInfo["postingPeriod"] = postingPeriod;
        // if periodMappingConfig -> reversalTranDate in rowConfigSetupInfo should be periodMappingConfig's table's periodMappingConfig.periodMappingReversalDateColumnName
        const periodTable = tables[createEntryConfig.periodMappingConfig.periodMappingTableName].data;
        const idIndex = periodTable[0].indexOf(createEntryConfig.periodMappingConfig.periodMappingIdColumnName);
        const endDateIndex = periodTable[0].indexOf(createEntryConfig.periodMappingConfig.periodMappingEndDateColumnName);
        const reversalDateIndex = periodTable[0].indexOf(createEntryConfig.periodMappingConfig.periodMappingReversalDateColumnName);
        const postingPeriodEndDate = periodTable.slice(1).find(periodRow => periodRow[idIndex] === postingPeriod)[endDateIndex];
        const reversalTranDate = periodTable.slice(1).find(periodRow => periodRow[idIndex] === postingPeriod)[reversalDateIndex];
        rowConfigSetupInfo["postingPeriodEndDate"] = postingPeriodEndDate;
        rowConfigSetupInfo["reversalTranDate"] = reversalTranDate;
      } else {
        // OTHERWISE this field is not alterable -> use the default for the accrual row
        rowConfigSetupInfo["postingPeriod"] = row[createEntryConfig["jePostingPeriodIdColumn"]];
        rowConfigSetupInfo["reversalTranDate"] = row[createEntryConfig["reversalTranDateColumn"]] ?? "";//row[createEntryConfig["reversalreversalTranDateColumn"]];
      }

      // jornalMemo -> formState[form_fields.j]
      rowConfigSetupInfo["journalMemo"] = formState[form_fields.journalEntryMemo];

      // disassemble form accounts back into semicolon separated values
      const journalLineDebitAccountIds = formState[form_fields.debitAccounts].map((debitAcct) => debitAcct[acct_fields.id]).join(";");
      const journalLineDebitAccountNames = formState[form_fields.debitAccounts].map((debitAcct) => debitAcct[acct_fields.acct]).join(";");
      const journalLineDebitAmounts = formState[form_fields.debitAccounts].map((debitAcct) => debitAcct[acct_fields.amt]).join(";");

      const journalLineCreditAccountIds = formState[form_fields.creditAccounts].map((creditAcct) => creditAcct[acct_fields.id]).join(";");
      const journalLineCreditAccountNames = formState[form_fields.creditAccounts].map((creditAcct) => creditAcct[acct_fields.acct]).join(";");
      const journalLineCreditAmounts = formState[form_fields.creditAccounts].map((creditAcct) => creditAcct[acct_fields.amt]).join(";");

      rowConfigSetupInfo["journalLineDebitAccountIds"] = journalLineDebitAccountIds;
      rowConfigSetupInfo["journalLineDebitAccountNames"] = journalLineDebitAccountNames;
      rowConfigSetupInfo["journalLineDebitAmounts"] = journalLineDebitAmounts;

      rowConfigSetupInfo["journalLineCreditAccountIds"] = journalLineCreditAccountIds;
      rowConfigSetupInfo["journalLineCreditAccountNames"] = journalLineCreditAccountNames;
      rowConfigSetupInfo["journalLineCreditAmounts"] = journalLineCreditAmounts;

      //   "addReversalFlagColumn": "",
      //   "reversalPostingPeriodIdColumn": "",
      //   "destinationColumn": "",
      //   "entryTypeColumn": "",
      //   "referenceInfoColumn": "",
      //   "entryPeriod": ""
      rowConfigSetupInfo["addReversalFlag"] = formState[form_fields.addReversalFlag];
      rowConfigSetupInfo["reversalPostingPeriodId"] = row[createEntryConfig["reversalPostingPeriodIdColumn"]];
      rowConfigSetupInfo["entryPeriod"] = createEntryConfig["entryPeriod"];
      rowConfigSetupInfo["destination"] = row[createEntryConfig["destinationColumn"]];
      rowConfigSetupInfo["entryType"] = row[createEntryConfig["entryTypeColumn"]];

      
      /* 11/25: accrual_send_email_entry_update
      new fields for rowConfigSetupInfo:
        String reversalTranDate,
        String reversalPostingPeriodId,// handled above
        String subsidiaryId,
        String subsidiaryName,
        String departmentId,
        String departmentName,
        String locationId,
        String locationName,
        String[] journalLineMemos
        String journalLineDebitAccountNos = (String) entryArgs.get("journalLineDebitAccountNos");
        String journalLineCreditAccountNos = (String) entryArgs.get("journalLineCreditAccountNos");
      */
      
      rowConfigSetupInfo["subsidiaryId"] = row[createEntryConfig["jeSubsidiaryIdColumn"]] ?? "";//row[createEntryConfig["reversalreversalTranDateColumn"]];
      rowConfigSetupInfo["subsidiaryName"] = row[createEntryConfig["jeSubsidiaryNameColumn"]] ?? "";//row[createEntryConfig["reversalreversalTranDateColumn"]];
      rowConfigSetupInfo["departmentId"] = row[createEntryConfig["jeDepartmentIdColumn"]] ?? "";//row[createEntryConfig["reversalreversalTranDateColumn"]];
      rowConfigSetupInfo["departmentName"] = row[createEntryConfig["jeDepartmentNameColumn"]] ?? "";//row[createEntryConfig["reversalreversalTranDateColumn"]];
      rowConfigSetupInfo["locationId"] = row[createEntryConfig["jeLocationIdColumn"]] ?? "";//row[createEntryConfig["reversalreversalTranDateColumn"]];
      rowConfigSetupInfo["locationName"] = row[createEntryConfig["jeLocationNameColumn"]] ?? "";//row[createEntryConfig["reversalreversalTranDateColumn"]];
      // 11/25: accrual_send_email_entry_update - currently unused createEntryConfig vars but passing for when relevant
      rowConfigSetupInfo["docNum"] = row[createEntryConfig["jeDocNumColumn"]] ?? "";
      rowConfigSetupInfo["tranId"] = row[createEntryConfig["jeTranIdColumn"]] ?? "";
      rowConfigSetupInfo["currency"] = row[createEntryConfig["jeCurrencyColumn"]] ?? "";
      rowConfigSetupInfo["lineEntity"] = row[createEntryConfig["jeLineEntityColumn"]] ?? "";
      rowConfigSetupInfo["class"] = row[createEntryConfig["jeClassColumn"]] ?? "";
      rowConfigSetupInfo["reversalJournalMemo"] = row[createEntryConfig["reversalJeMemoTextColumn"]] ?? "";
      
      const debitLineMemos = formState[form_fields.debitAccounts].map((debitAcct) => debitAcct[acct_fields.line_memo]);
      const creditLineMemos = formState[form_fields.creditAccounts].map((creditAcct) => creditAcct[acct_fields.line_memo]);

      rowConfigSetupInfo["jeLineMemos"] = debitLineMemos.concat(creditLineMemos).join(";");
      
      if (Object.keys(createEntryConfig).includes("reversalAsSeparateLine")) {
        rowConfigSetupInfo["reversalAsSeparateLine"] = createEntryConfig["reversalAsSeparateLine"]
      }
      // Feb 03 - Sandhill update
      if (Object.keys(createEntryConfig).includes("journalLineAllAmountsColumn")) {
        rowConfigSetupInfo["journalLineAllAmounts"] = row[createEntryConfig["journalLineAllAmountsColumn"]] ?? ""
      }
      if (Object.keys(createEntryConfig).includes("customCol1Column")) {
        rowConfigSetupInfo["customCol1"] = row[createEntryConfig["customCol1Column"]] ?? ""
      }
      if (Object.keys(createEntryConfig).includes("customCol2Column")) {
        rowConfigSetupInfo["customCol2"] = row[createEntryConfig["customCol2Column"]] ?? ""
      }
      if (Object.keys(createEntryConfig).includes("customCol3Column")) {
        rowConfigSetupInfo["customCol3"] = row[createEntryConfig["customCol3Column"]] ?? ""
      }
      if (Object.keys(createEntryConfig).includes("customCol4Column")) {
        rowConfigSetupInfo["customCol4"] = row[createEntryConfig["customCol4Column"]] ?? ""
      }
      if (Object.keys(createEntryConfig).includes("customCol5Column")) {
        rowConfigSetupInfo["customCol5"] = row[createEntryConfig["customCol5Column"]] ?? ""
      }
      // end Feb 03 - Sandhill update
      rowConfigSetupInfo["journalLineDebitAccountNos"] = "";
      rowConfigSetupInfo["journalLineCreditAccountNos"] = "";

      const { accountMappingConfig } = createEntryConfig;
      console.log("accountMappingConfig", accountMappingConfig)
      if (accountMappingConfig && accountMappingConfig["accountMappingNoColumnName"]) {
        const { 
          accountMappingTableName,
          accountMappingNameColumnName,
          accountMappingNoColumnName 
        } = accountMappingConfig;
        const accountMappingTable = tables[accountMappingTableName]?.data;
        const acctNoColIndex = accountMappingTable[0].indexOf(accountMappingNoColumnName);
        if (acctNoColIndex !== -1) {
          // assemble: 
          // journalLineDebitAccountNos
          // journalLineCreditAccountNos
          const acctNameColIndex = accountMappingTable[0].indexOf(accountMappingNameColumnName);
          const debitAccountNames = formState[form_fields.debitAccounts].map((debitAcct) => debitAcct[acct_fields.acct]);
          const creditAccountNames = formState[form_fields.creditAccounts].map((creditAcct) => creditAcct[acct_fields.acct]);
          const journalLineDebitAccountNos = [];
          const journalLineCreditAccountNos = [];
          debitAccountNames.forEach((acctName) => {
            // find the row with this name
            const matchingRow = accountMappingTable.find((row, index) => index > 0 && row[acctNameColIndex] === acctName); // Skip header row
            if (matchingRow) {
              journalLineDebitAccountNos.push(matchingRow[acctNoColIndex]); // Add account number to the array
            }
          })
          creditAccountNames.forEach((acctName) => {
            // find the row with this name
            const matchingRow = accountMappingTable.find((row, index) => index > 0 && row[acctNameColIndex] === acctName); // Skip header row
            if (matchingRow) {
              journalLineCreditAccountNos.push(matchingRow[acctNoColIndex]); // Add account number to the array
            }
          })
          rowConfigSetupInfo["journalLineDebitAccountNos"] = journalLineDebitAccountNos.join(";");//row[createEntryConfig["reversalreversalTranDateColumn"]];
          rowConfigSetupInfo["journalLineCreditAccountNos"] = journalLineCreditAccountNos.join(";");//row[createEntryConfig["reversalreversalTranDateColumn"]];
        } else {
          console.log("accountMappingNoColumnName not found in accountMappingTable")
        }
      }

      const newConfigValue = { entryUuid: entryUuid, rowConfigSetupInfo: rowConfigSetupInfo };
      handleConfigUpdate(newConfigValue);
      setOpen(false);
    };

    const handleDelete = (formState) => {
      const deleteEntryUuid = "NOT_SET"
      const newConfigValue = { entryUuid: deleteEntryUuid, rowConfigSetupInfo: {} };
      handleConfigUpdate(newConfigValue);
      setOpen(false);
    }

    const isFinalStatus = () => {
      if (!statusTrackerConfig) return false;
      const { allowedStatusList } = statusTrackerConfig;
      const finalStatusIndex = allowedStatusList.length - 1;
      return row["_status_tracker"] === allowedStatusList[finalStatusIndex];
    }

    const disabled = () => {
      if (disableActions) {
        return true;
      } else if (!createEntryConfig) {
        return true;
      } else if (row[statusTrackerConfig?.overrideColName] || isFinalStatus()) {
        return true;
      } else {
        return false;
      }
    }

    const tooltipTitle = () => {
      if (disableActions) {
        return ""
      } else if (!createEntryConfig) {
        return "Coming soon"
      } else if (row[statusTrackerConfig?.overrideColName] || isFinalStatus()) {
        return "JE posted"
      } else if (displayConfigTableRow?.displayConfigOptions[config_options.create_entry]) {
        return "Edit approved draft"
      } else {
        return "Review draft"
      }
    }
    const icon = () => {
      if (row[statusTrackerConfig?.overrideColName] || isFinalStatus()) {
        // return done icon
        return (
          <CreateEntryIcon
            disableActions={disableActions}
            overridden={true}
          />
        )
      } else if (displayConfigTableRow?.displayConfigOptions[config_options.create_entry]) {
        // return edit entry
        return (
          <CreateEntryIcon
            disableActions={disableActions}
            overridden={false}
            edit={true}
          />
        )
      } else {
        // return create entry
        return (
          <CreateEntryIcon
            disableActions={disableActions}
            overridden={false}
            edit={false}
          />
        )
      }
    }
    return (
      <>
        <Tooltip title={tooltipTitle()} followCursor>
          <span> {/* Wrapping in a span to enable tooltip on disabled button */}
            <IconButton
              tabIndex={-1}
              sx={getIconButtonStyle(configAction)}
              onClick={handleToggle}
              disabled={disabled()}
            >
              {/* {getIcon(null, configAction, disableActions)} */}
              {icon()}
            </IconButton>
          </span>
        </Tooltip>

        {/* Modal component */}
        <Modal
          open={open}
          onClose={handleClose}
          disableRestoreFocus
          aria-labelledby="create-entry-modal-title"
          aria-describedby="create-entry-modal-description"
        >
          {/* Style for modal content */}
          <Box 
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800, // You can adjust the size as needed
              maxHeight: '100vh', // Limit the maximum height to 90% of the viewport height
              overflowY: 'auto', // Enable vertical scrolling if content overflows
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 3,
              // borderRadius: 1, // Optional, for a rounded corner effect
            }}
          >
            {/* <CreateEntryForm
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
              handleClose={handleClose}
              displayConfigTableRow={displayConfigTableRow}
              createEntryConfig={createEntryConfig}
              row={row}
            /> */}
            <CreateEntryFormWrapper
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
              handleClose={handleClose}
              displayConfigTableRow={displayConfigTableRow}
              createEntryConfig={createEntryConfig}
              row={row}
            />
          </Box>
        </Modal>
      </>
    );
}

export default CreateEntryConfigActionButton;