import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { setFilters, setMultipleFilters, defaultFilterPayload } from "../../../../store/dashboardFiltersSlice";

/**
 * QuickFilterButton is a component that allows the user to quickly filter the table by a specific column.
 * It is used in the FDRecipeTable component.
 * @param {Object} column - The column object from the dashboardJson table component.
 * @param {Object} row - The row object from the table.
 * @param {boolean} disableActions - Whether to disable the actions on the button.
 * @returns {JSX.Element} - A button that allows the user to quickly filter the table by a specific column.
 */
const QuickFilterButton = ({ column, row, disableActions }) => {
    const dispatch = useDispatch()
    const dashboardJson = useSelector(state => state.dashboard.dashboardJson);
    const { quick_filter_button_config } = column;
    if (!quick_filter_button_config) {
        console.warn("no quick_filter_button_config");
        return null;
    }
    const { filterComponentName, filterInputColumn } = quick_filter_button_config;
    if (!filterComponentName || !filterInputColumn) {
        console.warn("no filterComponentName or filterInputColumn");
        return null;
    }
    /*
    "quick_filter_button_config": {
        "filterComponentName": "test_filter_component_name",
        "filterInputColumn": "vendor_name"
    }
    */
    const triggerSearch = () => {
        console.log("triggerSearch", filterComponentName, filterInputColumn);
        // first, check if filterComponentName and filterInputColumn are semi-colon separated values (should apply to multiple filters)
        if (filterComponentName.includes(";")) {
            const filterComponentNames = filterComponentName.split(";");
            const filterInputColumns = filterInputColumn.split(";");
            const filters = [];
            for (let i = 0; i < filterComponentNames.length; i++) {
                const cleanedFilterComponentName = filterComponentNames[i] + "_element";
                const filterComponent = dashboardJson.components[cleanedFilterComponentName];
                const sourceTable = filterComponent?.sourceTable;
                const additionalTables = filterComponent?.additionalFilterTables;
                const newValue = row[filterInputColumns[i]];
                if (!newValue || !sourceTable || !filterComponent) {
                    console.warn("no newValue or sourceTable or filterComponent");
                    return;
                }
                filters.push(defaultFilterPayload(cleanedFilterComponentName, filterInputColumns[i], newValue, "search", sourceTable, additionalTables))
            }
            dispatch(setMultipleFilters(filters))
        } else {
            const cleanedFilterComponentName = filterComponentName + "_element";
            const filterComponent = dashboardJson.components[cleanedFilterComponentName];
            const sourceTable = filterComponent?.sourceTable;
            const additionalTables = filterComponent?.additionalFilterTables;
            const newValue = row[filterInputColumn];
            if (!newValue || !sourceTable || !filterComponent) {
                console.warn("no newValue or sourceTable or filterComponent");
                return;
            }
            dispatch(setFilters(defaultFilterPayload(cleanedFilterComponentName, filterInputColumn, newValue, "search", sourceTable, additionalTables)))
        }
    };

    return (
        <IconButton onClick={triggerSearch} sx={{ padding: "4px" }} disabled={disableActions}>
            <SearchIcon />
        </IconButton>
    )
}

export default QuickFilterButton;