import React, { useEffect, useState, useRef } from "react";
import {
	DataGridPro,
	GridToolbar,
	GRID_DETAIL_PANEL_TOGGLE_FIELD,
	gridPageCountSelector,
	gridPageSelector,
	gridRowCountSelector,
	gridColumnVisibilityModelSelector,
	useGridApiContext,
	useGridApiRef,
	useGridSelector,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { styled, useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from "@mui/material/Grid";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import {addMixpanelEvent} from "../../store/mixpanelTrackSlice";
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";
import {generateExportNotificationArgs} from "../AlertFeed/FeedTableUtils";

const StyledProGrid = styled(DataGridPro)(({ theme }) => ({
	fontSize: "12px",
	lineHeight: "18px",
	border: "none",
	"& .MuiDataGrid-columnHeaderTitle": {
		fontWeight: 500,
	},
	"& .MuiDataGrid-iconSeparator": {
		//display: "none",
	},
	"& .MuiButton-root": {
		marginRight: "1em",
	},
	"& .MuiDataGrid-footerContainer": {
		justifyContent: "center",
	},
	"& .MuiPaginationItem-root": {
		borderRadius: "0px !important",
		//backgroundColor: "transparent",
		color: theme.palette.color,
		opacity: 1,
	},
	"& .MuiPaginationItem-root.Mui-selected": {
		borderRadius: "0px !important",
		backgroundColor: theme.palette.secondary.main,
	},
	"&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
		paddingTop: "12px",
		paddingBottom: "12px",
	},
	// forced scrollbar below - Non-standard (incompatible with firefox)
	"* ::-webkit-scrollbar": {
		//"-webkit-appearance": "none",
		WebkitAppearance: "none",
		width: "7px",
		height: "7px"
	},
	"* ::-webkit-scrollbar-thumb": {
		borderRadius: "4px",
		backgroundColor: theme.palette.primary.main,
	},
	"& .alert--active": {
		backgroundColor: theme.palette.secondary.main,
	},
	"& .alert--new": {
		fontWeight: 600,
	},
	"& .sysadmin-subscribed-apps-sync-before-run": {
		border: "1px solid red", // Apply a red border
		boxSizing: "border-box" // Ensure the border is included in the row's total width and height
	}
}));

function CustomPagination(props) {
	const dispatch = useDispatch();
	const apiRef = useGridApiContext();
	const page = useGridSelector(apiRef, gridPageSelector);
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);
	const totalRowCount = useGridSelector(apiRef, gridRowCountSelector);
	const columnVisibilityModel = useGridSelector(apiRef, gridColumnVisibilityModelSelector);
	const isInternal = useSelector((state) => state.role.isInternal);
	const user = useSelector((state) => state.role.name);

	const handleExport = (options) => {
		const dynamicOptions = {...options}
		if (props.disableExportColumns) {
			// if disableExportColumns we need to manually define 'fields' for our options -> fields: ['field_name_1', 'field_name_2', ...]
			// Get all columns
			const allColumns = apiRef.current.getAllColumns();

			// filter columns by presence in columnVisibilityModel / disableExportColumns
			const columnsToExport = allColumns.filter(column => {
				console.log("handleExport columnsToExport -> column:", column);
				if (
					columnVisibilityModel[column.field] === false 
					|| column.field === "__detail_panel_toggle__" 
					|| column.field === "__check__"
				) {
					return false;
				} else return !props.disableExportColumns[column.field];
			})
			dynamicOptions['fields'] = columnsToExport.map(column => column.field);
		}
		// Trigger the default CSV export
		apiRef.current.exportDataAsCsv(dynamicOptions);//options
		const tempMixpanelEvent = {
			event: "Exported Data",
			additionalProperties: {
				"Exported fileName": options.fileName,
				"URL of export": window.location.href
			}
		}
		// console.log("tempMixpanelEvent", tempMixpanelEvent)
		if (!isInternal) {
			const exportedCsv = apiRef.current.getDataAsCsv(dynamicOptions);//options
			dispatch(addMixpanelEvent(tempMixpanelEvent));
			dispatch(setAlertNotificationArgs(generateExportNotificationArgs(options.fileName, user, exportedCsv)));
		}
	};
	return (
		<Grid container justifyContent="space-between">
			<Grid item flexGrow={1}>
				{totalRowCount > props.pageSize && !props.staticTable &&
					<Pagination
						color={"border"}
						count={pageCount}
						page={page + 1}
						onChange={(event, value) => apiRef.current.setPage(value - 1)}
					/>
				}
			</Grid>
			<Grid item>
				<Button
					variant="text"
					color="primary"
					size={"small"}
					startIcon={<SaveAltIcon />}
					onClick={() => handleExport({ fileName: props.fileName })}
				>
					Export
				</Button>
				{/*<GridToolbarExport csvOptions={{ fileName: props.fileName}} onExport={() => handleExport({ fileName: props.fileName }) } />*/}
			</Grid>
		</Grid>
	);
}

function CustomToolbar(props) {
	return (
		<GridToolbarContainer>
			{/*<GridToolbarFilterButton />
			<GridToolbarColumnsButton />
			<GridToolbarDensitySelector />*/}
			{/*<GridToolbarExport csvOptions={{ fileName: props.fileName}} />*/}
		</GridToolbarContainer>
	);
}

function FDGrid(props) {
	const theme = useTheme();
	const [detailPanelHeights, setDetailPanelHeights] = useState({});
	const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState([]);
	// detailPanelHeights is a map of heights - getDetailPanelContent will key off {"row_index": index} passed in row objects by FDRecipeTable (the row that's expanded)
	// - will pass setDetailPanelHeights to DetailPanelContent
	// DetailPanelContent will render a hidden div with the data and call setDetailPanelHeights - updating the object with the new row data and rendering the real content
	// getDetailPanelHeight - bound to detailPanelHeights will now have a row_index to read the non-default value from and the detail panel will grow/shrink to fit content
	
	// *** arrow-key scroll logic ***
	const apiRef = useGridApiRef();
	const gridDivRef = useRef(null);

	useEffect(() => {
		const handleKeyDown = (event) => {
			// Dynamically check if the cursor is over the table
			// console.log("gridDivRef.current", gridDivRef.current);
			if (gridDivRef.current && gridDivRef.current.matches(':hover')) {
				// console.log("hovering");
				if (event.key === 'ArrowRight') {
					// console.log("ArrowRight", apiRef.current.getScrollPosition());
					apiRef.current.scroll({ left: apiRef.current.getScrollPosition().left + 50 });
					event.preventDefault();
				} else if (event.key === 'ArrowLeft') {
					apiRef.current.scroll({ left: apiRef.current.getScrollPosition().left - 50 });
					event.preventDefault();
				}
			}
		};
	
		window.addEventListener('keydown', handleKeyDown);
		return () => window.removeEventListener('keydown', handleKeyDown);
	}, [apiRef]);
	// *** end arrow-key scroll logic ***
	
	// *** detail panel logic ***
	const collapse = () => {
		return <RemoveIcon sx={{ zIndex: 20, color: theme.palette.primary.main }} />;
	};

	const expand = () => {
		return <AddIcon sx={{ color: theme.palette.primary.main }} />;
	};

	const getDetailPanelContent = React.useCallback(({row}) => {
		// console.log("apiRef", apiRef)
		const DetailPanelContent = props.detailPanelContent;
		return (
			<DetailPanelContent
				row={row}
				setDetailPanelHeights={setDetailPanelHeights}
				parentGridRef={apiRef}
				detailPanelDisplayNameOrder={props.detailPanelDisplayNameOrder}
				detailPanelDisplayNameColumn={props.detailPanelDisplayNameColumn}
			/>
		)
	}, [apiRef]);

	const getDetailPanelHeight = React.useCallback(({row}) => {
		return detailPanelHeights[row.row_index] || 150;
	}, [detailPanelHeights]);

	const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds) => {
		console.log("handleDetailPanelExpandedRowIdsChange - newIds", newIds);
		// console.log("handleDetailPanelExpandedRowIdsChange - detailPanelHeights", detailPanelHeights);
		// clear detailPanelHeight for closed detailPanelIDs
		const newDetailPanelHeights = {};
		newIds.forEach((id) => {
			newDetailPanelHeights[id] = detailPanelHeights[id];
		})
		// set expanded detailPanels && detailPanel heights
		setDetailPanelExpandedRowIds(newIds);
		setDetailPanelHeights(newDetailPanelHeights);
	}, [detailPanelHeights]);

	useEffect(() => {
		if (props.detailPanelEnabled) {
			setDetailPanelHeights({});
			setDetailPanelExpandedRowIds([]);
		}
	}, [props.rows, props.detailPanelEnabled])
	// *** end detail panel logic ***

	const getTableType = () => {
		if (props.detailPanelRowName) {
			// console.log("FD Grid tableType1 selected"); // simple_1
			return (
				<div style={{ width: "100%" }}>
					<StyledProGrid
						{...props}
						pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
						pagination
						components={{
							Pagination: CustomPagination,
							Toolbar: GridToolbar,
							DetailPanelExpandIcon: expand,
							DetailPanelCollapseIcon: collapse,
						}}
						sx={{
							"& .Mui-hovered.MuiDataGrid-detailPanelToggleCell": {
								zIndex: "20 !important",
							},
						}}
						density={"standard"}
						getDetailPanelHeight={getDetailPanelHeight}
						disableVirtualization={process.env.TEST ? true : false}
						//getDetailPanelHeight={500}
					/>
				</div>
			);
		} else if (props.SimpleBuilderKeys) {
			// simple_1 (modified FDGrid for key builder)
			// console.log("FD Grid tableType2 selected");
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<StyledProGrid
						{...props}
						disableColumnMenu
						disableColumnFilter
						disableColumnSelector
						hideFooter
						disableSelectionOnClick
						density={"compact"}
						sx={{
							"& .simpleBuilderLink--highlight": {
								bgcolor: theme.palette.primary.light,
							},
						}}
						disableVirtualization={process.env.TEST ? true : false}
					/>
				</div>
			);
		} else {
			const today = new Date();
			const today_string = String(today.getMonth() + 1).padStart(2, '0') + "/" + String(today.getDate()).padStart(2, '0') + "/" + today.getFullYear();

			return (
				<div style={{ width: "100%" }} ref={gridDivRef}>
					<StyledProGrid
						apiRef={apiRef}
						{...props}
						//pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
						pagination={/*props.staticTable ? !props.staticTable : */true}
						hideFooterRowCount={props.staticTable ? props.staticTable : false}
						//rowSelection={props.staticTable ? !props.staticTable : true}
						disableSelectionOnClick
						disableColumnMenu={props.staticTable ? props.staticTable : false}
						components={{
							Pagination: CustomPagination,
							Toolbar: CustomToolbar,
							DetailPanelExpandIcon: expand,
							DetailPanelCollapseIcon: collapse,
						}}
						componentsProps={{
							// toolbar: { fileName: props.exportFilename ? (props.exportFilename + "_" + today_string) : ("WiseLayer_Export_" + today_string) },
							pagination: {
								pageSize: props.pageSize,
								fileName: props.exportFilename ? (props.exportFilename + "_" + today_string) : ("WiseLayer_Export_" + today_string),
								disableExportColumns: props.disableExportColumns ? props.disableExportColumns : false
							}
						}}
						density={props.density ?? "standard"}
						sx={{
							// prevent default cells from wrapping--we control this with wrapColumn
							'& .MuiDataGrid-cell': {
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
							},
							"& .Mui-hovered.MuiDataGrid-detailPanelToggleCell": {
								zIndex: "20 !important",
							},
							'& .MuiDataGrid-checkboxInput.MuiButtonBase-root': {
								padding: '4px',
								'& .MuiTouchRipple-root': {
										display: 'none'
								}
							},
							"& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
								outline: "none"
							}
						}}
						disableVirtualization={process.env.TEST ? true : false}
						getDetailPanelHeight={props.detailPanelEnabled ? getDetailPanelHeight : null}
						getDetailPanelContent={props.detailPanelEnabled ? getDetailPanelContent : null}
						onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
						detailPanelExpandedRowIds={detailPanelExpandedRowIds}
						// new row selection logic
						checkboxSelection={props.enableCheckboxSelection}
						onSelectionModelChange={props.enableCheckboxSelection ? props.handleSelectionModelChange : null}
						selectionModel={props.enableCheckboxSelection ? props.selectionModel : []}
						isRowSelectable={props.enableCheckboxSelection ? props.isRowSelectable : null}
						onColumnResize={props.handleColumnResize ? props.handleColumnResize : null}
						disableColumnReorder={true}
					/>
				</div>
			);
		}
	};

	return getTableType();
}

export default FDGrid;
