import { createSlice } from '@reduxjs/toolkit';
import { config_options } from "./dashboardConfigsSlice";

const initialDashboardFiltersState = {
  filters: {
    /*
      tableA: {
	      fieldNameA: { target, filterType }
	      displayConfig__configUuidA: { valueMap: {...}, displayConfigOptions: { hide_row: true } }
      },
      tableB: {
	      ...
      }
    */
  },
  filtersInputs: {
    /*
      <objectName>: {
        value:
      }
    */
  },
  displayConfigsToggleState: {
    hide_row: true,
    suppress_alert: true
  },
  loading: false,
}

export const displayConfigTogglePayload = (stateToToggle, newValue) => {
  return {
    stateToToggle: stateToToggle,
    newValue: newValue
  }
}

export const displayConfigFilterPayload = (configUuid, displayConfigTableRows, sourceTable) => {
  return {
    configUuid: configUuid,
    displayConfigTableRows: displayConfigTableRows,
    sourceTable: sourceTable
  }
}

export const defaultFilterPayload = (objectName, field, target, filterType, mainTable, additionalTables) => {
  return {
    objectName: objectName,
    field: field,
    target: target,
    filterType: filterType,
    mainTable: mainTable,
    additionalTables: additionalTables ?? []
  }
}

// displayConfig__configUuid__rowUuid: { valueMap: {...}, displayConfigOptions: { hide_row: true } }
const deleteDisplayConfigFilters = (configUuid, filters) => {
  // console.log("start deleteDisplayConfigFilters")
  // console.log("configUuid ", configUuid);
  const filtersClone = {...filters};
  const tables = Object.keys(filters);
  // console.log("tables ", tables)
  for (let i = 0; i < tables.length; i++) {
    const tableFilters = Object.keys(filters[tables[i]]);
    // console.log("tableFilters ", tableFilters)
    const filtersForThisConfig = tableFilters.filter((key) => key.includes(`displayConfig__${configUuid}`));
    // console.log("filtersForThisConfig ", filtersForThisConfig)
    if (filtersForThisConfig.length > 0) {
      filtersForThisConfig.forEach((filter) => {
        // console.log("deleting ->", filter, filtersClone[tables[i]][filter]);
        delete filtersClone[tables[i]][filter];
      })
    }
  }
  // console.log("filtersClone", filtersClone);
  // console.log("end deleteDisplayConfigFilters")
  return filtersClone
}

// displayConfig__configUuid__rowUuid: { valueMap: {...}, displayConfigOptions: { hide_row: true } }
const addDisplayConfigFilters = (configUuid, filters, sourceTable, displayConfigTableRows) => {
  const filtersClone = JSON.parse(JSON.stringify(filters));
  // you need to go row by row
  displayConfigTableRows.forEach((displayConfigTableRow) => {
    /*
    ::: displayConfigRow :::
    String uuid;                // this is constant
    String companyUuid;
    String createdBy;
    Map<String, Object> primaryKeyValueMap;
    Map<String, Map<String, Object>> linkedTableForeignValueMaps;
    Map<String, Object> displayConfigOptions;
    */
    const { uuid, primaryKeyValueMap, displayConfigOptions, linkedTableForeignValueMaps } = displayConfigTableRow;
    filtersClone[sourceTable][`displayConfig__${configUuid}__${uuid}`] = { valueMap: primaryKeyValueMap, displayConfigOptions: displayConfigOptions }
    if (linkedTableForeignValueMaps) {
      const linkedTables = Object.keys(linkedTableForeignValueMaps)
      if (linkedTables.length > 0) {
        linkedTables.forEach(linkedTable => {
          if (filtersClone[linkedTable]) {
            filtersClone[linkedTable][`displayConfig__${configUuid}__${uuid}`] = { valueMap: linkedTableForeignValueMaps[linkedTable], displayConfigOptions: displayConfigOptions }
          }
        })
      }
    }
  })
  return filtersClone;
}


const getSessionStorageDashboardFilters = (dashboardUuid) => {
  const sessionStorageDashboardFilters = sessionStorage.getItem(`dashboardFilters__${dashboardUuid}`);
  if (sessionStorageDashboardFilters) {
    const sessionStorageDashboardFiltersJson = JSON.parse(sessionStorageDashboardFilters);
    return sessionStorageDashboardFiltersJson;
  }
  return null;
}

const setSessionStorageDashboardFilters = (dashboardUuid, filters, filtersInputs) => {
  sessionStorage.setItem(`dashboardFilters__${dashboardUuid}`, JSON.stringify({ filters, filtersInputs }));
}

// when they setUpFilters, if nothing saved by dashboardUuid, then we need to generate filters and filtersInputs
// if there is a dashboardUuid, we should be able to use that to populate filters and filtersInputs

export const dashboardFiltersSlice = createSlice({
  name: "dashboardFilters",
  initialState: initialDashboardFiltersState,
  reducers: {
    // sets up a filter dict for each table
    setUpFilters: (state, action) => {
      console.log("dashboardFiltersSlice - setUpFilters:", action.payload);
      const { tables, dashboardJson, uuid } = action.payload;
      const sessionStorageDashboardFilters = getSessionStorageDashboardFilters(uuid);
      if (sessionStorageDashboardFilters) {
        console.log("dashboardFiltersSlice - setUpFilters - sessionStorageDashboardFilters", sessionStorageDashboardFilters);
        state.filters = sessionStorageDashboardFilters.filters;
        state.filtersInputs = sessionStorageDashboardFilters.filtersInputs;
        // TODO: do we need to set loading to true?
      } else {
        console.log("dashboardFiltersSlice - setUpFilters - no sessionStorageDashboardFilters");
        const tableNames = Object.keys(tables);
        const newFilters = {};
        tableNames.forEach(tableName => {
          newFilters[tableName] = {}
        })
        const newFiltersInputs = {};
        Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
          let value = "";
          if (component.type === "search") {
            // do nothing, default is ""
          } else if (component.type === "select" || component.type === "radio" || component.type === "toggle") {
            // need to calculate items to get default value
            const field = component.field;
            const sourceTable = component.sourceTable;
            // select: props.default ?? "All"
            // radio: (props.noAll ? props.items[0] : "All")
            // toggle: DEAD
            if (component.default) {
              value = component.default;
            } else if (component.date_config) {
              const fieldIndex = tables[sourceTable].data[0].indexOf(field);
              const items = [...new Set(tables[sourceTable].data.slice(1).map((item) => {
                let date = new Date(item[fieldIndex]);
                if (component.date_config.type === "year") {
                  return date.getFullYear();
                } else if (component.date_config.type === "month") {
                  return date.getMonth();
                }
              }))].sort((a, b) => a - b);
              if (component.descend) {
                items.reverse();
              }
              value = component.noAll ? items[0] : "All";
            } else if (!component.items) {
              const fieldIndex = tables[sourceTable].data[0].indexOf(field);
              let set = [...new Set(tables[sourceTable].data.slice(1).map((item) => item[fieldIndex]))].sort((a, b) => a - b);
              let items = [];
              if(!set.some(isNaN)) {
                items = set.sort((a, b) => a - b);
              } else {
                items = set.sort();
              }
              if (component.descend) {
                items.reverse();
              }
              value = component.noAll ? items[0] : "All";
            } else {
              const items = JSON.parse(JSON.stringify(component.items ?? []));
              if (component.descend) {
                items.reverse();
              }
              value = component.noAll ? items[0] : "All";
            }
          }
          
          if (component.type === "search" || component.type === "select" || component.type === "radio" || component.type === "toggle") {
            // set the value in the filtersInputs
            newFiltersInputs[objectName] = { value: value };
            
            // set the value in filters
            let filterType = "regular";
            if (component.date_config) {
              filterType = component.date_config.type ?? "regular";
            }
            if (component.type === "search") {
              filterType = "search";
            }

            const field = component.field;
            const sourceTable = component.sourceTable;
            const additionalTables = component.additionalFilterTables ?? [];
            const target = value;
            
            let all_tables = [sourceTable];
            if (additionalTables) all_tables = all_tables.concat(additionalTables)
            for (let i = 0; i < all_tables.length; i++) {
              if (
                (target === "All" &&
                  (filterType === "regular" ||
                    filterType === "year" ||
                    filterType === "month")) ||
                (target === "" && filterType === "search")
              ) {
                delete newFilters[all_tables[i]][field];
              } else {
                newFilters[all_tables[i]][field] = { target: target, filterType: filterType };
              }
            }
          }
        })
        state.filters = newFilters;
        state.filtersInputs = newFiltersInputs;
        // TODO: do we need to set loading to true?
      }
    },
    setFiltersInputs: (state, action) => {
      console.log("dashboardFiltersSlice - setFiltersInputs", action.payload);
      const { objectName, target } = action.payload;
      state.filtersInputs[objectName] = { value: target };
      //setSessionStorageFiltersInput(uuid, objectName, value); -> don't do this, the debounced setFilters will fire in 300ms and perform this
    },
    // one for filter components (objectName, defaultFilterPayload) -> sets filters and sets value in filterInputs for passed objectName
    // each filter component (search, select, radio) has useEffect logic to dispatch setFilters - this populates their initial input value in state.filtersInputs
    setFilters: (state, action) => {
      console.log("dashboardFiltersSlice - setFilters", action.payload)
      const {objectName, field, target, filterType, mainTable, additionalTables, uuid} = action.payload;
      const filtersClone = JSON.parse(JSON.stringify(state.filters));
      // console.log("filtersClone pre processing:", filtersClone)
      let all_tables = [mainTable];
      if (additionalTables) all_tables = all_tables.concat(additionalTables)
      for (let i = 0; i < all_tables.length; i++) {
        if (
          (target === "All" &&
            (filterType === "regular" ||
              filterType === "year" ||
              filterType === "month")) ||
          (target === "" && filterType === "search")
        ) {
          delete filtersClone[all_tables[i]][field];
        } else {
          filtersClone[all_tables[i]][field] = { target: target, filterType: filterType };
        }
      }
      const inputsClone = JSON.parse(JSON.stringify(state.filtersInputs))
      inputsClone[objectName] = {};
      inputsClone[objectName]["value"] = target;
      console.log("filters", filtersClone)
      console.log("filtersInputs", inputsClone)
      state.filters = filtersClone;
      state.filtersInputs = inputsClone;
      state.loading = true;
      setSessionStorageDashboardFilters(uuid, filtersClone, inputsClone);
    },
    setMultipleFilters: (state, action) => {
      console.log("setMultipleFilters", action.payload);
      const { filters, uuid } = action.payload;
      const filtersClone = JSON.parse(JSON.stringify(state.filters));
      const inputsClone = JSON.parse(JSON.stringify(state.filtersInputs));
      filters.forEach(filter => {
        const { objectName, field, target, filterType, mainTable, additionalTables } = filter;
        let all_tables = [mainTable];
        if (additionalTables) all_tables = all_tables.concat(additionalTables)
        for (let i = 0; i < all_tables.length; i++) {
          if (
            (target === "All" &&
              (filterType === "regular" ||
                filterType === "year" ||
                filterType === "month")) ||
            (target === "" && filterType === "search")
          ) {
            delete filtersClone[all_tables[i]][field];
          } else {
            filtersClone[all_tables[i]][field] = { target: target, filterType: filterType };
          }
        }
        inputsClone[objectName] = {};
        inputsClone[objectName]["value"] = target;
      })
      state.filters = filtersClone;
      state.filtersInputs = inputsClone;
      state.loading = true;
      setSessionStorageDashboardFilters(uuid, filtersClone, inputsClone);
    },
    setDisplayConfigFilters: (state, action) => {
      console.log("dashboardFiltersSlice - setDisplayConfigFilters", action.payload);
      const { configUuid, displayConfigTableRows, sourceTable } = action.payload;
      const currentFilters = JSON.parse(JSON.stringify(state.filters));
      // console.log("current filters", currentFilters);
      const cleanedFilters = deleteDisplayConfigFilters(configUuid, currentFilters);
      // console.log("cleanedFilters", cleanedFilters);
      const updatedFilters = addDisplayConfigFilters(configUuid, cleanedFilters, sourceTable, displayConfigTableRows);
      console.log("filters", updatedFilters);
      state.filters = updatedFilters;
      state.loading = true;
    },
    setDisplayConfigsToggleState: (state, action) => {
      console.log("dashboardFiltersSlice - setDisplayConfigsToggleState", action.payload);
      const { stateToToggle, newValue } = action.payload;
      state.displayConfigsToggleState[stateToToggle] = newValue;
      if (stateToToggle === config_options.hide_row) {
        state.loading = true;
      }
    },
    setLoading: (state, action) => {
      console.log("dashboardFiltersSlice - setLoading", action.payload);
      state.loading = action.payload;
    },
    resetDashboardFiltersSlice: (state, action) => {
      console.log("dashboardFiltersSlice - resetDashboardFilters")
      return initialDashboardFiltersState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setUpFilters,
  setFiltersInputs,
  setFilters,
  setMultipleFilters,
  setDisplayConfigFilters,
  setDisplayConfigsToggleState,
  setLoading,
  resetDashboardFiltersSlice
} = dashboardFiltersSlice.actions;

export default dashboardFiltersSlice.reducer;