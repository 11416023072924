import { Typography } from "@mui/material";
import ResetColumnsStateButton from "./ResetColumnsStateButton";

export const TableSettingsHeader = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px"
            }}
        >
            <Typography variant="subtitle2">Columns</Typography>
            <ResetColumnsStateButton />
        </div>
    )
}

export default TableSettingsHeader;