import CreateEntryInput from "./CreateEntryInput";
import { form_fields } from "./createEntryForm";
import { useCallback } from "react";

const modifyMemoValue = (formState, newMemo) => {
  return {
    ...formState,
    [form_fields.journalEntryMemo]: newMemo
  }
}

export const JournalEntryMemoInput = ({ memo, setFormState }) => {
  //value, handleUpdate, label, error, errorMessage, multiline, startAdornment
  const error = !memo
  const errorMessage = !memo ? <span style={{ fontSize: "8px" }}>Journal entry memo required</span> : "";

  const handleUpdate = useCallback(
    (newValue) => {
      setFormState((prevFormState) => {
        const updatedFormState = modifyMemoValue(prevFormState, newValue);
        return updatedFormState;
      });
    },
    [setFormState] // Only re-create the function when these values change
  );

  return (
    <CreateEntryInput
      value={memo}
      handleUpdate={handleUpdate}
      error={error}
      errorMessage={errorMessage}
      multiline={true}
    />
  )
}

export default JournalEntryMemoInput;