import * as Constants from "../../../../Constants";
import iso8601Timestamp from "../../../../helpers/iso8601Timestamp";
import { 
  config_options,
  createEntryConfig_fields,
  sendEmailConfig_fields,
  autoPerformConfig_fields,
  rowConfigSetupInfo_fields
} from "../../../../store/dashboardConfigsSlice";

export const getPrimaryKeyValueMap = (row, primaryKeyFieldList) => {
  const tempPrimaryKeyValueMap = {};
  primaryKeyFieldList.forEach((primaryKey) => {
    tempPrimaryKeyValueMap[primaryKey] = row[primaryKey];
  })
  // console.log("tempPrimaryKeyValueMap", tempPrimaryKeyValueMap)
  return tempPrimaryKeyValueMap;
}

export const getLinkedTableForeignValueMaps = (row, primaryKeyFieldList, linkedTableForeignFieldMap) => {
  //Map<String, List<String>> linkedTableForeignFieldMap
  const orderedPrimaryValues = primaryKeyFieldList.map((primaryKey) => row[primaryKey]);
  const tempLinkedTableForeignValueMap = {};
  Object.entries(linkedTableForeignFieldMap).forEach(([tableName, foreignFieldMap]) => {
    const tempForeignValueMap = {};
    foreignFieldMap.forEach((field, index) => {
      tempForeignValueMap[field] = orderedPrimaryValues[index];
    })
    tempLinkedTableForeignValueMap[tableName] = tempForeignValueMap;
  })
  // console.log("tempLinkedTableForeignValueMap", tempLinkedTableForeignValueMap);
  return tempLinkedTableForeignValueMap;
}

const getDisplayConfigOptionValue = (configAction, newConfigValue) => {
  console.log("getDisplayConfigOptionValue - configAction", configAction, "newConfigValue", newConfigValue);
  if (configAction === config_options.create_entry) {
    const { entryUuid } = newConfigValue;
    return entryUuid;
  } else if (configAction === config_options.send_email) {
    return "";
  } else {
    return newConfigValue;
  }
}

export const SINGLE_ROW = true;
export const MULTI_ROW = false;

export const generateDisplayConfigUpdateArgs = (
  companyUuid, 
  newConfigValue, 
  configAction, 
  row, 
  displayConfigUuid, 
  entryPeriod, 
  primaryKeyFieldList, 
  linkedTableForeignFieldMap, 
  statusTrackerConfig,
  singleRow = SINGLE_ROW
) => {
  console.log("generateDisplayConfigUpdateArgs - singleRow =", singleRow);
  const configUpdateArgsArray = [];
  const tempBody = {
    displayConfigTableUuid: displayConfigUuid,
    primaryKeyValueMap: getPrimaryKeyValueMap(row, primaryKeyFieldList),
    displayConfigOptionName: configAction,
    displayConfigOptionValue: getDisplayConfigOptionValue(configAction, newConfigValue),
    entryPeriod: entryPeriod
  }
  if (linkedTableForeignFieldMap) {
    tempBody["linkedTableForeignValueMaps"] = getLinkedTableForeignValueMaps(row, primaryKeyFieldList, linkedTableForeignFieldMap);
  }
  if (configAction === config_options.create_entry || configAction === config_options.send_email) {
    const { rowConfigSetupInfo } = newConfigValue
    tempBody["rowConfigSetupInfo"] = rowConfigSetupInfo;
  }

  let tempUpdateArgs;
  if (singleRow) {
    tempUpdateArgs = {
      url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL,
      method: "POST",
      body: JSON.stringify(tempBody)
    }
  } else {
    tempUpdateArgs = tempBody;
  }
  
  configUpdateArgsArray.push(tempUpdateArgs);
  /* 
    We're not done! Now we need to check statusTrackerConfig.allowedStatusActionMap 
    because if the configAction is in the map - we need to produce a second set of args 
    following the same logic as above except only for status_tracker config action (config_options.status_tracker) 
  */
  if (statusTrackerConfig) {
    if (statusTrackerConfig.allowedStatusActionMap) {
      if (configAction === config_options.create_entry && newConfigValue["entryUuid"] === "NOT_SET") {
        // don't send status update on entry deletion - server handles
      } else if (Object.keys(statusTrackerConfig.allowedStatusActionMap).includes(configAction) && row["_status_tracker"]) {
        // ok we can see current status row["_status_tracker"] - use this to figure out which status to change it to
        const currentStatus = row["_status_tracker"];
        const newStatus = statusTrackerConfig.allowedStatusActionMap[configAction][row["_status_tracker"]];
        if (newStatus === currentStatus) {
          // do nothing - status is already set to the desired status
          console.log("row status is already set to the desired status - skipping status update");
        } else {
          const tempStatusUpdateBody = {
            displayConfigTableUuid: displayConfigUuid,
            primaryKeyValueMap: getPrimaryKeyValueMap(row, primaryKeyFieldList),
            displayConfigOptionName: config_options.status_tracker,
            displayConfigOptionValue: newStatus,
            entryPeriod: entryPeriod
          }
          if (linkedTableForeignFieldMap) {
            tempStatusUpdateBody["linkedTableForeignValueMaps"] = getLinkedTableForeignValueMaps(row, primaryKeyFieldList, linkedTableForeignFieldMap);
          }
          console.log("tempStatusUpdateBody", tempStatusUpdateBody);
          // now construct rest of args
          let tempStatusUpdateArgs;
          if (singleRow) {
            tempStatusUpdateArgs = {
              url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL,// TODO - add
              method: "POST",
              body: JSON.stringify(tempStatusUpdateBody)
            }
          } else {
            tempStatusUpdateArgs = tempStatusUpdateBody;
          }
          configUpdateArgsArray.push(tempStatusUpdateArgs)
        }
      }
    }
  }
  console.log("configUpdateArgsArray", configUpdateArgsArray)
  return configUpdateArgsArray;
}

export const generateMultiRowDisplayConfigUpdateArgs = (argsArr, companyUuid) => {
  const tempMultiRowConfigUpdateArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROWS_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROWS_URL,
    method: "POST",
    body: JSON.stringify({
      displayConfigRowList: argsArr
    })
  }
  const multiRowConfigUpdateArgsArray = [tempMultiRowConfigUpdateArgs];// place in array to match single row args request logic 
  console.log("multiRowConfigUpdateArgsArray", multiRowConfigUpdateArgsArray);
  return multiRowConfigUpdateArgsArray;
}

export const objInBody = (obj) => {
  try {
    // Stringify it with nice formatting
    const formattedJSON = JSON.stringify(obj, null, 2);
    // Wrap in pre tags and style for email readability
    return `
      <pre style="
        background-color: #f5f5f5;
        padding: 10px;
        border-radius: 4px;
        font-family: monospace;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-size: 14px;
        color: #333;
        margin: 10px 0;
      ">${formattedJSON}</pre>
    `;
  } catch (e) {
    // Handle case where the obj can't be valid JSON
    return `<p style="color: #666;">Unable to stringify obj: ${obj}</p>`;
  }
}

const getNotificationBody = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid) => {
  const rowInBody = objInBody(row);//Object.entries(row).map(([key, value]) => `${key}: ${value}<br/>`).join("");
  if (configAction === config_options.create_entry) {
    const { entryUuid, rowConfigSetupInfo } = newConfigValue;
    const rowConfigSetupInfoInBody = objInBody(rowConfigSetupInfo);
    const deleted = entryUuid === "NOT_SET";
    return `<strong>${user}</strong> ${deleted ? "deleted entry" : entryUuid ? "updated entry " + entryUuid : "created entry"}<br/><br/>
      displayConfigUuid: ${displayConfigUuid}<br/><br/>
      appUuid: ${devAppUuid}<br/><br/>
      row: ${rowInBody}<br/><br/>
      rowConfigSetupInfo: ${rowConfigSetupInfoInBody}<br/><br/>
      on ${window.location.origin}<br/><br/>`
  } else if (configAction === config_options.send_email) {
    const { rowConfigSetupInfo } = newConfigValue;
    const rowConfigSetupInfoInBody = objInBody(rowConfigSetupInfo);
    return `<strong>${user}</strong> sent email<br/><br/>
      displayConfigUuid: ${displayConfigUuid}<br/><br/>
      appUuid: ${devAppUuid}<br/><br/>
      row: ${rowInBody}<br/><br/>
      rowConfigSetupInfo: ${rowConfigSetupInfoInBody}<br/><br/>
      on ${window.location.origin}<br/><br/>`
  } else {
    return `<strong>${user}</strong> set ${configAction} to ${newConfigValue}<br/><br/>
      display config uuid: ${displayConfigUuid}<br/><br/>
      appUuid: ${devAppUuid}<br/><br/>
      row: ${rowInBody}<br/><br/>
      on ${window.location.origin}<br/><br/>`
  }
}

const getNotificationSubject = (newConfigValue, configAction, user) => {
  if (configAction === config_options.create_entry) {
    const { entryUuid } = newConfigValue;
    const deleted = entryUuid === "NOT_SET";
    return `${user} ${deleted ? "deleted entry" : entryUuid ? "updated entry " + entryUuid : "created entry"} (${window.location.origin} - ${iso8601Timestamp()})`
  } else if (configAction === config_options.send_email) {
    const { rowConfigSetupInfo } = newConfigValue;
    const setup_fields = rowConfigSetupInfo_fields[config_options.send_email];
    const recipientEmailAddress = rowConfigSetupInfo[setup_fields.recipientEmailAddress];
    return `${user} sent email to ${recipientEmailAddress} (${window.location.origin} - ${iso8601Timestamp()})`
  } else {
    return `${user} set ${configAction} to ${newConfigValue} (${window.location.origin} - ${iso8601Timestamp()})`
  }
}

export const generateDisplayConfigUpdateNotification = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": getNotificationBody(newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid),
      "subject": getNotificationSubject(newConfigValue, configAction, user),
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

const getOverwriteColumnNotificationBody = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid, overwriteNotificationConfig) => {
  const rowInBody = objInBody(row);
  const {
    overwriteConfig,
    field,
    newValue
  } = overwriteNotificationConfig;
  const linkedMultiAppConfigName = overwriteConfig?.linkedMultiAppConfig?.multiAppConfigName ?? "no linked multi app config";
  return `<strong>${user}</strong> added { ${field}: ${newValue} } to ${configAction}<br/><br/>
      new display config ${configAction} optionValue: ${objInBody(JSON.parse(newConfigValue))}<br/><br/>
      display config uuid: ${displayConfigUuid}<br/><br/>
      modified linked multi app config: ${linkedMultiAppConfigName}<br/><br/>
      appUuid: ${devAppUuid}<br/><br/>
      row: ${rowInBody}<br/><br/>
      on ${window.location.origin}<br/><br/>`
}
const getOverwriteColumnNotificationSubject = (configAction, user, overwriteNotificationConfig) => {
  const {
    field,
    newValue
  } = overwriteNotificationConfig;
  return `${user} added { "${field}": "${newValue}" } to ${configAction} (${window.location.origin} - ${iso8601Timestamp()})`
}

export const generateDisplayConfigOverwriteColumnNotification = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid, overwriteNotificationConfig) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": getOverwriteColumnNotificationBody(newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid, overwriteNotificationConfig),
      "subject": getOverwriteColumnNotificationSubject(configAction, user, overwriteNotificationConfig),
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const isRowSelectable = (configs, row) => {
  const {
    statusTrackerConfig,
    createEntryConfig,
    sendEmailConfig,
    hide_row_toggle,
    disableActions
  } = configs
  
  // do not allow row selection if disableActions is true
  if (disableActions) {
    return false;
  }
  
  // do not allow row selection when viewing hidden rows (dashboardFilters.displayConfigsToggleState.hide_row)
  if (!hide_row_toggle) {
    return false;
  }
  
  const entry_fields = createEntryConfig_fields;
  const email_fields = sendEmailConfig_fields;
  const auto_perform_fields = autoPerformConfig_fields;
  
  const createEntryAutoPerform = createEntryConfig?.[entry_fields.autoPerformConfig]
  const sendEmailAutoPerform = sendEmailConfig?.[email_fields.autoPerformConfig]
  
  // do not allow row selection if multiple autoPerform configs - this is erroneous script logic and needs to be corrected in the app scripts
  if (createEntryAutoPerform && sendEmailAutoPerform) {
    return false;
  }
  
  if (createEntryAutoPerform) {
    const autoPerformFlag = row[createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
    const validStatus = createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
    
    if (autoPerformFlag && validStatus) {
      return true;
    }
  }
  /*
  createEntry:
  console.log("checking row for autoCreateEntry", row);
  const displayConfigTableRow = displayConfigTableRows
    ?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap)
    .every(([field, value]) => row[field] === value));
  
  const hidden = displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.hide_row]?.["optionValue"];
  const autoPerformFlag = row[createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
  const validStatus = createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
  */
  
  if (sendEmailAutoPerform) {
    const autoPerformFlag = row[sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
    const validStatus = sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
    
    if (autoPerformFlag && validStatus) {
      return true;
    }
  }
  /*
  sendEmail:
  console.log("checking row for autoSendEmail", row);
  const displayConfigTableRow = displayConfigTableRows
    ?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap)
    .every(([field, value]) => row[field] === value));
  
  const hidden = displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.hide_row]?.["optionValue"];
  const autoPerformFlag = row[sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
  const validStatus = sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
  */
  
  // default to false
  return false;
  
}

const getAutoPerformNotificationBody = (notificationConfig) => {
  const {
    dashboardJson,
    configAction,
    user,
    configValuesArr
  } = notificationConfig;
  const configValuesInBody = objInBody(configValuesArr);
  return `<strong>${user}</strong> auto-performed ${configAction} with the following values in the ${dashboardJson.title} app:</br>${configValuesInBody}<br/><br/>on ${window.location.origin}<br/><br/>`
}

const getAutoPerformNotificationSubject = (notificationConfig) => {
  const {
    selectionModel,
    dashboardJson,
    configAction,
    user
  } = notificationConfig;
  return `${user} auto-performed ${configAction} on ${selectionModel.length} rows in the ${dashboardJson.title} app (${window.location.origin} - ${iso8601Timestamp()})`
}

export const generateAutoPerformNotification = (notificationConfig) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": getAutoPerformNotificationBody(notificationConfig),
      "subject": getAutoPerformNotificationSubject(notificationConfig),
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}