import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { form_fields } from './createEntryForm';

const ReversalFlagInput = ({ formState, setFormState }) => {
  // Convert string 'true'/'false' to boolean for the checked state
  const isChecked = formState[form_fields.addReversalFlag] === 'true';

  return (
    <FormControlLabel
      control={
        <Switch
          sx={{ marginLeft: "4px" }}
          size="small"
          checked={isChecked}
          onChange={(event) => {
            setFormState(prev => ({
              ...prev,
              // Convert boolean back to string for storage
              [form_fields.addReversalFlag]: event.target.checked.toString()
            }));
          }}
        />
      }
      label={isChecked ? "Yes" : "No"}
    />
  );
};

export default ReversalFlagInput; 