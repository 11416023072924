import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import DirectRequest from "../../API/requests/DirectRequest";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as Constants from "../../Constants";
import {useSelector, useDispatch} from "react-redux";
import {setCompany, refreshSysAdminAlerts} from "../../store/alertsSysAdminSlice";
import ImpactBar from "../ImpactBar/ImpactBar";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import {/*ArchivedTooltipTitle, */IrrelevantTooltipTitle, MainTooltipTitle} from "../AlertFeed/FeedTableUtils";
import Button from "@mui/material/Button";
import {setSelectedTab, setReadyForCustomer} from "../../store/alertsTabsSysAdminSlice";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import SysAdminDatabricksRuns from "./SysAdminDatabricksRuns";
import SysAdminSubscribedApps from "./SysAdminSubscribedApps";
import SysAdminAccount from "./SysAdminAccount";
import SysAdminAlertFeed from "./SysAdminAlertFeed";
import SysAdminAppVisibility from "./SysAdminAppVisibility";
import DatabricksRunsWarmUpRequest from "../../API/requests/DatabricksRunsWarmUpRequest";
import SysAdminSummary from "./SysAdminSummary";
import SysAdminUserLogins from "./SysAdminUserLogins";
import SysAdminAlertConfigs from "./SysAdminAlertConfigs/SysAdminAlertConfigs";
import SysAdminAlertMessages from "./SysAdminAlertMessages";
import { messages_to_show, setMessagesToShow } from "../../store/alertMessagesSysAdminSlice";
import SysAdminClientEmails from "./SysAdminClientEmails/SysAdminClientEmails";
import SysAdminModules from "./SysAdminModules/SysAdminModules";
import SysAdminMultiAppConfigs from "./SysAdminMultiAppConfigs/SysAdminMultiAppConfigs";
import SysAdminDatabricksJobs from "./SysAdminDatabricksJobs/SysAdminDatabricksJobs";

const sysAdminTools = {
  NotificationFeed: "Notification Feed",
  AIMessages: "AI Messages",
  AlertConfig: "Alert Configuration",
  SubscribedApps: "Subscribed Apps",
  Account: "Account",
  JobRuns: "Job Runs",
  AppVisibility: "App Visibility",
  Summary: "Summary",
  UserLogins: "User Logins",
  ClientEmails: "Client Emails",
  Modules: "Modules",
  MultiAppConfigs: "MultiAppConfigs",
  JobsQueue: "Jobs queue"
}

const SysAdminControlsFilters = ({
  handleCompanySelection,
  selectedCompany,
  companies,
  selectedTool,
  handleToolSelection
}) => {
  const theme = useTheme()
  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.alertsTabsSysAdmin.selectedTab);
  const readyForCustomer = useSelector((state) => state.alertsTabsSysAdmin.readyForCustomer);
  const disabled = useSelector((state) => state.alertsTabsSysAdmin.disabled);
  const messagesToShow = useSelector(state => state.alertMessagesSysAdmin.messagesToShow);

  const handleTabSelection = (tabToSelect) => {
    console.log("handleTabSelection", tabToSelect)
    if (tabToSelect === selectedTab) return;
    dispatch(setSelectedTab(tabToSelect));
  }

  const handleReadyForCustomerToggle = () => {
    console.log("handleReadyForCustomer - readyForCustomer", readyForCustomer)
    dispatch(setReadyForCustomer(!readyForCustomer));
  }

  const handleMessagesToShowToggle = () => {
    console.log("handleMessagesToShowToggle", messagesToShow);
    if (messagesToShow === messages_to_show.ALERT) {
      dispatch(setMessagesToShow(messages_to_show.GENERAL))
    } else {
      dispatch(setMessagesToShow(messages_to_show.ALERT))
    }
  }

  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item>
        <FormControl size="small" sx={{width: "150px"}}>
          <Select
            displayEmpty
            value={selectedCompany ? selectedCompany.uuid : ''}
            onChange={handleCompanySelection}
            renderValue={(selectedValue) => selectedCompany && selectedCompany.name ? selectedCompany.name : <i>Company</i>}
          >
            {companies.map((company, index) => (
              <MenuItem key={index} value={company.uuid}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* TOOL SELECTION HERE */}
      <Grid item>
        <FormControl size="small" sx={{width: "150px"}}>
          <InputLabel id="sysadmin-tool-select-label">Tool</InputLabel>
          <Select
            labelId={"sysadmin-tool-select-label"}
            value={selectedTool}
            onChange={handleToolSelection}
            variant="outlined"
            label={"Tool"}
          >
            {Object.values(sysAdminTools).map((tool, index) => (
              <MenuItem key={index} value={tool}>
                {tool}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item style={{ alignSelf: "stretch" }}>
        <Divider orientation="vertical" sx={{ backgroundColor: theme.palette.border.light }} />
      </Grid>
      {/* CONDITIONAL RENDER ON TOOL SELECTION HERE */}
      {selectedTool === sysAdminTools.NotificationFeed && (
        <>
          <Grid item>
            <FormControlLabel control={<Switch checked={readyForCustomer} onChange={handleReadyForCustomerToggle} />} label={"Released"} labelPlacement="start"/>
          </Grid>
          <Grid item/>
          <Grid item>
            <Typography variant={"p"}>Tabs</Typography>
          </Grid>
          <Grid item sx={disabled ? { pointerEvents: "none"}:{}}>
            <ButtonGroup aria-label="outlined primary button group">
              <Tooltip title={<MainTooltipTitle/>} placement={"bottom"} arrow>
                <Button variant={selectedTab === "Main" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={handleTabSelection.bind(this, "Main")}>Inbox</Button>
              </Tooltip>
              <Tooltip title={""} placement={"bottom"} arrow>
                <Button variant={selectedTab === "Unread" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={handleTabSelection.bind(this, "Unread")}>Unread only</Button>
              </Tooltip>
              {/* NOTE: Oct 4 2023 - Archive functionality disabled for now - will determine reimplementation based on user feedback */}
              {/*<Tooltip title={<ArchivedTooltipTitle/>} placement={"bottom"} arrow>*/}
              {/*  <Button variant={selectedTab === "Archived" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={handleTabSelection.bind(this, "Archived")}>Archived</Button>*/}
              {/*</Tooltip>*/}
              <Tooltip title={"Resolved in system"} placement={"bottom"} arrow>
                <Button variant={selectedTab === "Resolved" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={handleTabSelection.bind(this, "Resolved")}>Previous</Button>
              </Tooltip>
              <Tooltip title={<IrrelevantTooltipTitle/>} placement={"bottom"} arrow>
                <Button variant={selectedTab === "Irrelevant" ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={handleTabSelection.bind(this, "Irrelevant")}>Removed</Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </>
      )}
      {selectedTool === sysAdminTools.AIMessages && (
        <>
          <Grid item>
            <ButtonGroup>
              <Button variant={messagesToShow === messages_to_show.ALERT ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={handleMessagesToShowToggle}>Alert</Button>
              <Button variant={messagesToShow === messages_to_show.GENERAL ? "contained" : "outlined"} sx={{ borderRadius: "0px !important" }} onClick={handleMessagesToShowToggle}>General</Button>
            </ButtonGroup>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default function SysAdminControls() {
  const dispatch = useDispatch();
  const [companiesArgs, setCompaniesArgs] = useState({ url: Constants.SERVER_SYSADMIN_GET_COMPANIES_URL });
  const [companies, setCompanies] = useState([]);

  const selectedCompany = useSelector((state) => state.alertsSysAdmin.company);

  const [selectedTool, setSelectedTool] = useState(sysAdminTools.NotificationFeed)

  const handleCompanySelection = (event) => {
    const selectedCompanyUuid = event.target.value;
    const selectedCompany = companies.find(company => company.uuid === selectedCompanyUuid);
    dispatch(setCompany(selectedCompany));
  };

  const handleCompanies = (res) => {
    const sortedCompanies = [...res].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    setCompanies(sortedCompanies.filter((company) => company.uuid !== "comp_DEMO"));
  }

  const handleToolSelection = (event) => {
    setSelectedTool(event.target.value);
  }

  return (
    <>
      <DatabricksRunsWarmUpRequest />
      <DirectRequest
        requestArgs={companiesArgs}
        afterProcess={handleCompanies}
        handleError={(err) => !Constants.USE_DUMMY_DATA && console.log("get companies error", err)}
        handleCatchError={(err) => !Constants.USE_DUMMY_DATA && console.log("get companies catch error", err)}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ImpactBar
                component={
                  <SysAdminControlsFilters
                    handleCompanySelection={handleCompanySelection}
                    selectedCompany={selectedCompany}
                    companies={companies}
                    selectedTool={selectedTool}
                    handleToolSelection={handleToolSelection}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              {/* selectedTool conditional render here */}
              {selectedTool === sysAdminTools.NotificationFeed && (
                <SysAdminAlertFeed selectedCompany={selectedCompany}/>
              )}
              {selectedTool === sysAdminTools.AIMessages && (
                // <SysAdminAiMessages selectedCompany={selectedCompany}/>
                <SysAdminAlertMessages/>
              )}
              {selectedTool === sysAdminTools.AlertConfig && (
                <SysAdminAlertConfigs/>
              )}
              {selectedTool === sysAdminTools.SubscribedApps && (
                <SysAdminSubscribedApps selectedCompany={selectedCompany}/>
              )}
              {selectedTool === sysAdminTools.Account && (
                <SysAdminAccount selectedCompany={selectedCompany}/>
              )}
              {selectedTool === sysAdminTools.JobRuns && (
                <SysAdminDatabricksRuns selectedCompany={selectedCompany}/>
              )}
              {selectedTool === sysAdminTools.AppVisibility && (
                <SysAdminAppVisibility companies={companies} />
              )}
              {selectedTool === sysAdminTools.Summary && (
                <SysAdminSummary selectedCompany={selectedCompany}/>
              )}
              {selectedTool === sysAdminTools.UserLogins && (
                <SysAdminUserLogins selectedCompany={selectedCompany}/>
              )}
              {selectedTool === sysAdminTools.ClientEmails && (
                <SysAdminClientEmails selectedCompany={selectedCompany}/>
              )}
              {selectedTool === sysAdminTools.Modules && (
                <SysAdminModules selectedCompany={selectedCompany} companies={companies}/>
              )}
              {/* MultiAppConfigs */}
              {selectedTool === sysAdminTools.MultiAppConfigs && (
                <SysAdminMultiAppConfigs selectedCompany={selectedCompany}/>
              )}
              {selectedTool === sysAdminTools.JobsQueue && (
                <SysAdminDatabricksJobs companies={companies}/>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}