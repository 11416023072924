import useDatabricksJobs from './hooks/useDatabricksJobs';
import DirectRequest from '../../../API/requests/DirectRequest';
import { useModules } from '../hooks/useModules';
import JobsList from './components/JobsList';

export const SysAdminDatabricksJobs = ({companies}) => {
    const { databricksJobs, databricksJobsArgs, handleDatabricksJobs, handleDatabricksJobsRefresh } = useDatabricksJobs();
    const { modules, modulesArgs, handleModules } = useModules();
    return (
        <>
            <DirectRequest
                requestArgs={databricksJobsArgs}
                afterProcess={handleDatabricksJobs}
                handleError={err => console.warn("server error fetching databricks jobs", err)}
                handleCatchError={err => console.warn("client error fetching databricks jobs", err)}
            />
            <DirectRequest
                requestArgs={modulesArgs}
                afterProcess={handleModules}
                handleError={err => console.warn("server error fetching modules", err)}
                handleCatchError={err => console.warn("client error fetching modules", err)}
            />
            <JobsList 
                databricksJobs={databricksJobs} 
                handleDatabricksJobsRefresh={handleDatabricksJobsRefresh} 
                modules={modules}
                companies={companies}
            />
        </>
    )
}

export default SysAdminDatabricksJobs;