import { useState, useEffect, useRef } from "react";
import * as Constants from "../../../../Constants"; 

const cancelJobsArgs = {
    url: Constants.SERVER_SYSADMIN_POST_CANCEL_DATABRICKS_JOB_RUNS_URL,
    method: "POST",
    // body: {otp: ""} -> need to pass body via function
}

const defaultCancelDatabricksJobsState = {
    isConfirmationActive: false,
    args: null,
    confirmationExpiration: null
}

export const useCancelDatabricksJobs = (handleDatabricksJobsRefresh) => {
    const [cancelDatabricksJobsState, setCancelDatabricksJobsState] = useState(defaultCancelDatabricksJobsState);
    const confirmationTimerRef = useRef(null);

    // Clean up timer on unmount
    useEffect(() => {
        return () => {
            if (confirmationTimerRef.current) {
                clearTimeout(confirmationTimerRef.current);
            }
        };
    }, []);

    // Reset state when confirmation expires
    useEffect(() => {
        if (cancelDatabricksJobsState.isConfirmationActive && cancelDatabricksJobsState.confirmationExpiration) {
            if (confirmationTimerRef.current) {
                clearTimeout(confirmationTimerRef.current);
            }
            
            const expirationTime = cancelDatabricksJobsState.confirmationExpiration - Date.now();
            confirmationTimerRef.current = setTimeout(() => {
                setCancelDatabricksJobsState(defaultCancelDatabricksJobsState);
            }, expirationTime);
        }
    }, [cancelDatabricksJobsState.isConfirmationActive, cancelDatabricksJobsState.confirmationExpiration]);

    const handleCancelJobs = () => {
        if (!cancelDatabricksJobsState.isConfirmationActive) {
            // First click - activate confirmation
            setCancelDatabricksJobsState({
                ...cancelDatabricksJobsState,
                isConfirmationActive: true,
                confirmationExpiration: Date.now() + 10000 // Confirmation expires in 10 seconds
            });
            return;
        }

        // Second click - send actual request
        const args = {...cancelJobsArgs};
        args.body = JSON.stringify({});
        
        setCancelDatabricksJobsState({
            ...cancelDatabricksJobsState,
            args: args
        });
    }

    const handleCancelJobsSuccess = (res) => {
        console.log("cancelled jobs", res);
        setCancelDatabricksJobsState(defaultCancelDatabricksJobsState);
        handleDatabricksJobsRefresh();
    }

    return {
        cancelDatabricksJobsArgs: cancelDatabricksJobsState.args,
        handleCancelJobs,
        handleCancelJobsSuccess,
        isConfirmationActive: cancelDatabricksJobsState.isConfirmationActive
    }
}

export default useCancelDatabricksJobs;