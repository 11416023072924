import { useSelector } from "react-redux";
import { Grid, Typography, Tooltip } from "@mui/material";
import { JobCardButtons } from "./JobButtons";
import { 
    databricksJobs_fields_inProgress as inProgress_fields, 
    databricksJobs_fields_queued as queued_fields
} from "../hooks/useDatabricksJobs";
import { styled } from "@mui/material/styles";

const truncateStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    cursor: "pointer"
};

// Create a custom styled tooltip component
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: '600px',  // You can adjust this value as needed
    width: 'auto',
    whiteSpace: 'pre-wrap',
  },
});

const generateTooltipContent = (propertiesArray) => {
    const output = {};
    propertiesArray.forEach((property) => {
        output[property[0]] = property[1];
    });
    return <pre>{JSON.stringify(output, null, 2)}</pre>;
}

const JobCardDetails = ({inProgress, config}) => {
    const {
        appUuid, 
        appName, 
        companyUuid, 
        companyName, 
        moduleUuid, 
        moduleName,
        dependencies,
        source
    } = config;
    return (
        <Grid item xs={8.5}>
            <div style={{width: "100%", height: "100%"}}>
                <Grid container spacing={1}>
                    <Grid item xs={3.5}>
                        <CustomWidthTooltip 
                            title={generateTooltipContent([["app uuid", appUuid], ["app fullName", appName], ["source", source]])} 
                            arrow 
                            followCursor
                        >
                            <Typography sx={truncateStyle}>{appName}</Typography>
                        </CustomWidthTooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <CustomWidthTooltip 
                            title={generateTooltipContent([["company uuid", companyUuid], ["company name", companyName]])} 
                            arrow 
                            followCursor
                        >
                            <Typography sx={truncateStyle}>{companyName}</Typography>
                        </CustomWidthTooltip>
                    </Grid>
                    <Grid item xs={3}>
                        {!inProgress && moduleUuid && <CustomWidthTooltip 
                            title={generateTooltipContent([["module uuid", moduleUuid], ["module fullName", moduleName]])} 
                            arrow 
                            followCursor
                        >
                            <Typography sx={truncateStyle}>{moduleName}</Typography>
                        </CustomWidthTooltip>}
                    </Grid>
                    <Grid item xs={2.5}>
                        {!inProgress && dependencies.length > 0 && <CustomWidthTooltip 
                            title={<pre>{JSON.stringify(dependencies, null, 2)}</pre>} 
                            arrow 
                            followCursor
                        >
                            <Typography sx={{ cursor: "pointer" }}>Dependencies</Typography>
                        </CustomWidthTooltip>}
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}


export const JobCard = ({job, handleDatabricksJobsRefresh, inProgress, modules, companies}) => {
    const allApps = useSelector((state) => state.appsSysAdmin.list);
    const appUuid = inProgress ? job[inProgress_fields.developer_app_id] : job[queued_fields.developerAppUuid];
    const appName = inProgress ? allApps.find((app) => app.uuid === appUuid)?.fullName ?? "app name not found" : job[queued_fields.developerAppName];
    
    const companyUuid = inProgress ? job[inProgress_fields.company_id] : job[queued_fields.companyUuid];
    const companyName = inProgress ? companies.find((company) => company.uuid === companyUuid)?.name ?? "company name not found" : job[queued_fields.companyName];
    
    const moduleUuid = inProgress ? "" : job[queued_fields.moduleId];
    const moduleName = modules.find(module => module.uuid === moduleUuid)?.fullName ?? "";

    const dependencies = inProgress ? [] : job[queued_fields.parentAppIds] ?? [];
    const source = inProgress ? job[inProgress_fields.source] : job[queued_fields.source];

    const config = {
        appUuid,
        appName,
        companyUuid,
        companyName,
        moduleUuid,
        moduleName,
        dependencies,
        source
    }
    return (
        <Grid item xs={12} container spacing={1} sx={{ border: "1px solid #e0e0e0", borderRadius: "5px", paddingTop: "0px !important", paddingBottom: "8px", paddingRight: "8px", marginTop: "-1px" }} alignItems="center">
            <JobCardDetails inProgress={inProgress} config={config} />
            <JobCardButtons job={job} handleDatabricksJobsRefresh={handleDatabricksJobsRefresh} inProgress={inProgress} config={config}/>
        </Grid>
    )
}

export default JobCard;