import React, {
  Fragment,
  useEffect,
} from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import NotFound from "./components/NotFound/NotFound";
import AlertFeed from "./components/AlertFeed/AlertFeed";
import "./App.scss";
import SSE from "./API/SSE/SSE";
import ErrorLogRequest from "./API/requests/ErrorLogRequest";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components/ErrorFallback/ErrorFallback";
import RoleRequest from "./API/requests/RoleRequest";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import new_logo from "./assets/Wiselayer.png";
import SyncedNonEmptyTablesRequest from "./API/requests/SyncedNonEmptyTablesRequest";
import SourcesRequest from "./API/requests/SourcesRequest";
import LoginNotificationRequest from "./API/requests/LoginNotificationRequest";
import mixpanel from "mixpanel-browser";
import {setMixpanelInitialized} from "./store/mixpanelSlice";
import MixpanelIdentify from "./components/Mixpanel/MixpanelIdentify";
import MixpanelSignIn from "./components/Mixpanel/MixpanelSignIn";
import MixpanelTrack from "./components/Mixpanel/MixpanelTrack";

import AppStore from "./components/AppStore/AppStore";
import IntegrationMetadataRequest from "./API/requests/IntegrationMetadataRequest";
import SysAdminControls from "./components/SysAdmin/SysAdminControls";

import Integrations from "./components/Connectors/Integrations";

import AppsRequest from "./API/requests/AppsRequest";
import FilteredAppsManager from "./components/ResourceManagement/FilteredAppsManager";
import AppsRequestSysAdmin from "./API/requests/AppsRequestSysAdmin";

import SummaryMetricsRequest from "./API/requests/SummaryMetricsRequest";

import AlertsRequest from "./API/requests/AlertsRequest";
import AlertsSysAdminRequest from "./API/requests/AlertsSysAdminRequest";

import Redirect from "./components/Redirect/Redirect";
import AppsCategoriesManager from "./components/ResourceManagement/AppsCategoriesManager";

import Account from "./components/Settings/Account";
import AppStateManager from "./components/ResourceManagement/AppStateManager";

import PreviewAppSandboxDestinationsRequest from "./API/requests/PreviewAppSandboxDestinationsRequest";
import PreviewAppRequest from "./API/requests/PreviewAppRequest";
import AppBuilder from "./components/AppBuilder/AppBuilder";
import * as Constants from "./Constants";
import DashboardEditor from "./components/AppBuilder/DashboardEditor/DashboardEditor";
import AlertsUpdateRequest from "./API/requests/AlertsUpdateRequest";
import AlertsNotificationRequest from "./API/requests/AlertsNotificationRequest";
import AlertConfigsSysAdminRequest from "./API/requests/AlertConfigsSysAdminRequest";
import AppsSubscriptionsRequest from "./API/requests/AppsSubscriptionsRequest";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { setCloseBanner } from "./store/roleSlice";
import CustomerSubsManager from "./components/ResourceManagement/CustomerSubsManager";
import PostSetupCompleteRequest from "./API/requests/PostSetupCompleteRequest";
import AlertMessagesRequest from "./API/requests/AlertMessagesRequest";
import AlertMessagesCategoryManager from "./components/ResourceManagement/AlertMessagesCategoryManager";
import AlertMessages from "./components/AlertMessages/AlertMessages";
import AlertMessageRedirect from "./components/Redirect/AlertMessageRedirect";
import AlertSyncsRequest from "./API/requests/AlertSyncsRequest";
import Dashboard from "./components/Dashboard/Dashboard";
import DashboardRequest from "./API/requests/DashboardRequest";
import LogoLoader from "./components/Loaders/LogoLoader";
import accruals_ai_logo from "./assets/logos/accruals_ai_logo.png";

import DashboardGetter from "./components/Dashboard/DashboardGetter";
import DashboardReportGetter from "./components/Dashboard/DashboardReportGetter";
import ReportRedirect from "./components/Redirect/ReportRedirect";

import ModulesRequest from "./API/requests/ModulesRequest";
import ModuleRedirect from "./components/Redirect/ModuleRedirect/ModuleRedirect";
import ModuleGetter from "./components/Dashboard/ModuleGetter";

import DownloadJeCsvRequest from "./API/requests/DownloadJeCsvRequest";

LicenseInfo.setLicenseKey(
  "a814959554cff5eb8587c9438f8d02bdT1JERVI6NDIwNzQsRVhQSVJZPTE2ODE5MjY2MzYwMDAsS0VZVkVSU0lPTj0x"
);

const mixpanelProjectToken = "c9cd6426a8fd56a2178087ec84647352";

export const theme = createTheme({
  palette: {
    primary: {
      //main: "#24BAE6",
      //light: "rgba(36, 186, 230, 0.1)",
      //middle: "rgba(36, 186, 230, 0.5)",
      main: "rgba(0, 18, 184, 1.0)",
      light: "rgba(0, 18, 184, 0.1)",
      middle: "rgba(0, 18, 184, 0.5)",
    },
    chip: {
      main: "rgba(243, 245, 252, 1.0)",
    },
    secondary: {
      //main: "#F2F2F2",
      //light: "rgba(36, 186, 230, 0.1)",
      main: "rgba(243, 245, 252, 1.0)",
      light: "rgba(243, 245, 252, 0.1)",
    },
    dark: {
      main: "#000000",
      light: "rgba(0, 0, 0, 0.1)",
    },
    link: {
      main: "#24BAE6",
      light: "rgba(36, 186, 230, 0.1)",
    },
    header: {
      main: "#24BAE6",
      light: "rgba(36, 186, 230, 0.1)",
    },
    border: {
      main: "rgba(0, 18, 184, 1.0)",
      light: "rgba(0, 18, 184, 0.1)",
      simpleBorder: "rgb(226,226,227)", //"rgba(0, 18, 184, 0.15)",
      middle: "rgba(0, 18, 184, 0.5)",
    },
    good: {
      main: "rgb(30, 70, 32)",
      light: "rgb(237, 247, 237)",
    },
    bad: {
      main: "rgb(95, 33, 32)",
      light: "rgb(253, 237, 237)",
    },
    white: {
      main: "#FFFFFF",
    },
    xarrow: {
      linked: "rgb(226,226,227)", // <- a light grey
      viable: "rgb(204,208,241)", // theme.palette.primary.main -> 70% lighter
      active: "rgba(0, 18, 184, 1.0)", // theme.palette.primary.main
    },
    missingData: {
      alert: { main: "#ffd000" },
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Arial", "Open Sans"].join(","),
    color: "rgba(0, 5, 39, 1.0)",
    h4: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "18px",
    },
    p: {
      fontSize: "12px",
      lineHeight: "18px",
    },
    body1: {
      fontSize: "12px",
      lineHeight: "18px",
    },
    footer: {
      fontSize: "12px",
      //color: "#000000",
      //color: "#000527",
      color: "rgba(0, 5, 39, 1.0)",
      lineHeight: "18px",
    },
    primary: {
      main: "rgba(255, 0, 0, 1.0)",
      light: "rgba(255, 0, 0, 1.0)",
      dark: "rgba(255, 0, 0, 0.1)",
    },
    link: {
      //color: "#24BAE6",
      //color: "#000000",
      color: "#000527",
      textDecoration: "underline",
      fontWeight: 400,
    },
    cleanlink: {
      color: "#000527",
      fontWeight: 400,
    },
    person: {
      color: "#000000",
      fontSize: "18px",
    },
    missingData: {
      color: "#6D6E71",
    }
  },
  components: {
    /* MuiDataGrid styles here are for getting rid of hover backgroundcolor and preserving status tracker row colors on hover */
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // Override default hover color to make it transparent
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
          },
          "& .MuiDataGrid-row.Mui-hovered": {
            backgroundColor: "inherit",
          },
          // override "status" style via Mui-hovered and :hover to cover all scenarios
          "& .MuiDataGrid-row.Mui-hovered.status-in-progress, & .MuiDataGrid-row.status-in-progress:hover": {
            backgroundColor: "rgba(243, 245, 252, 1.0)",
          },
          "& .MuiDataGrid-row.Mui-hovered.status-done, & .MuiDataGrid-row.status-done:hover": {
            backgroundColor: "rgba(228, 236, 255, 1.0)",
          },
          // supporting legacy alert--active backgroundColor style on hover
          "& .MuiDataGrid-row.Mui-hovered.alert--active, & .MuiDataGrid-row.alert--active:hover": {
            backgroundColor: "rgba(243, 245, 252, 1.0)",
          },
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
        li {
          font-size: 12px;
        }
        button {
          border-radius: 120px !important;
          padding-right: 1em;
          padding-left: 1em;
        }
        button.dark {
          // background-color: #000000 !important;
          background-color:  rgba(0, 18, 184, 1.0) !important;
          color: #ffffff !important;
        }
        button.dark:disabled {
          //background-color: rgba(0, 0, 0, 0.2) !important;
          background-color:  rgba(0, 18, 184, 0.2) !important;
        }
        .reactour__helper span[data-tour-elem='badge'] {
          //background: #000000 !important;
          background: rgba(0, 18, 184, 1.0) !important;
        }
        .reactour__helper .reactour__dot--is-active {
          //background: #000000 !important;
          background-color: rgba(0, 18, 184, 1.0) !important;
        }
        .reactour__helper {
          padding: 36px !important;
        }
        .MuiAlert-icon {
          color: inherit !important;
        }
        .MuiDataGrid-cell.bordered-left {
          border-left: 1px solid #e6e6e6;
        }
        .MuiDataGrid-cell.bordered-right {
          border-right: 1px solid #e6e6e6;
        }
        .MuiDataGrid-cell.bordered-right-dense {
          border-right: 3px solid #e6e6e6;
        }
        .MuiDataGrid-cell.highlighted {
          background-color: rgba(0, 0, 0, 0.1);
        }
        .MuiDataGrid-cell.bold {
          font-weight: 500;
        }
        .MuiDataGrid-cell.italic {
          font-style: italic;
        }
        .MuiDataGrid-cell.bad-highlighted-cell {
          color: white;
          background-color: rgb(95, 33, 32);
          font-weight: 500;
        }
        .MuiDataGrid-cell.bad-highlighted-text {
          color: rgb(95, 33, 32);
        }
        /* start hidden rows styling */
        .MuiDataGrid-row.dashboard-hidden-row {
          background-color: #d6d6d6;
          font-style: italic;
          font-weight: 500;
          color: #999999;
        }
        /* start status tracker styling */
        .MuiDataGrid-row.status-in-progress {
          background-color: rgba(243, 245, 252, 1.0);
        }
        .MuiDataGrid-row.status-done {
          background-color: rgba(228, 236, 255, 1.0);
        }
        /* selectionModel styling */
        .MuiDataGrid-row.Mui-selected:not(.dashboard-hidden-row) {
            background-color: /*transparent*/inherit !important;
        }
        .MuiDataGrid-row.status-in-progress.Mui-selected {
            background-color: rgba(243, 245, 252, 1.0) !important;
        }
        .MuiDataGrid-row.status-done.Mui-selected {
            background-color: rgba(228, 236, 255, 1.0) !important;
        }
        /* end selectionModel styling */
        /* tagged row icons are grey */
        .MuiDataGrid-row.dashboard-hidden-row .MuiSvgIcon-root {
          color: inherit;  /* Make icons inherit the color from the parent */
        }
        /* Prevents hover background for the entire row and pinned columns */
        .MuiDataGrid-row.dashboard-hidden-row.Mui-hovered,
        .MuiDataGrid-row.dashboard-hidden-row.Mui-hovered .MuiDataGrid-pinnedColumn {
          background-color: #d6d6d6 !important;
        }
        /* Prevents selection background for the entire row and pinned columns */
        .MuiDataGrid-row.dashboard-hidden-row.Mui-selected,
        .MuiDataGrid-row.dashboard-hidden-row.Mui-selected .MuiDataGrid-pinnedColumn {
          background-color: #d6d6d6 !important;
        }
        /* Prevents hover effect on selected rows for the entire row and pinned columns */
        .MuiDataGrid-row.dashboard-hidden-row.Mui-selected.Mui-hovered,
        .MuiDataGrid-row.dashboard-hidden-row.Mui-selected.Mui-hovered .MuiDataGrid-pinnedColumn {
          background-color: #d6d6d6 !important;
        }
        /* end hidden rows styling */
        .MuiDataGrid-row.bold {
          font-weight: 500;
        }
        .MuiDataGrid-row.italics {
          font-style: italic;
        }
        .MuiDataGrid-row.bordered-top {
          border-top: 3px solid #e6e6e6;
        }
        .MuiDataGrid-row.spacer-row {
          margin-top: 1em;
        }
        .MuiDataGrid-row.italics .MuiDataGrid-cell[data-colindex='0'] .MuiTypography-p {
          position: relative;
          left: 0.5em;
        }
        .MuiDataGrid-pinnedColumnHeaders--right {
           padding-right: 0 !important;
        }
        /* wrap text for wrapColumn columns */
        .MuiDataGrid-cell.wrap-cell,
        .MuiDataGrid-columnHeader.wrap-header {
          white-space: normal !important;
          word-break: break-word;
          line-height: 1.4;
        }
        .MuiDataGrid-columnHeader.wrap-header {
          white-space: normal !important;
          word-break: break-word;
          line-height: 1.4;
          text-align: left;
        }
        /* This makes sure the header content itself doesn’t fight layout */
        .MuiDataGrid-columnHeader.wrap-header .MuiDataGrid-columnHeaderTitle {
          white-space: normal !important;
          word-break: break-word;
          line-height: 1.4;
          display: block;
          width: 100%;
        }
        .MuiDataGrid-columnHeader.wrap-header
          .MuiDataGrid-columnHeaderTitleContainer
          .MuiDataGrid-columnHeaderTitleContainerContent
          [aria-label] {
          white-space: normal !important;
          word-break: break-word;
          overflow: visible !important;
          text-overflow: unset !important;
          display: block;
          width: 100%;
        }
        /* end wrap text for wrapColumn columns */
        `,
    },
  },
});

export const AppLoader = (props) => {
  const dispatch = useDispatch();
  const roleError = useSelector((state) => state.role.isErrored);
  const handleLogout = () => {
    const logoutUrl = Constants.SERVER_LOGOUT_URL;
    dispatch({ type: "RESET" });
    window.open(logoutUrl, "_self");
  }
  const verbs = [
    "Collecting",
    "Amassing",
    "Accumulating",
    "Assembling",
    "Convening",
    "Congregating",
    "Conglomerating",
    "Generating",
    "Concentrating",
    "Pooling",
    "Compiling",
    "Congregating",
    "Gathering",
  ]
  const nouns = [
    "wisdom",
    "insights",
    "intelligence",
    "perceptions",
    "truths",
    "knowledge",
  ]
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container spacing={1} sx={props.inRouter ? {height:  "100vh", minWidth: "100vw", marginTop: "-18px" } : {height:  "100vh", minWidth: "100vw"}} justifyContent={"center"} alignItems={"center"} /*direction={"column"}*/>
        <Grid item xs={12} container alignItems={"center"} justifyContent={"center"}>
          <LogoLoader/>
          {!roleError && <Typography sx={{ marginTop: "2rem" }}>{verbs[Math.floor(Math.random() * 13)]} {nouns[Math.floor(Math.random() * 6)]}<span className="bouncing-dots"><span
            className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span></span></Typography>}
          {roleError && <Typography sx={{ marginTop: "2rem" }}>There was an error retrieving your profile, please click <span style={{ color: theme.palette.primary.main, cursor: "pointer" }} onClick={handleLogout}><strong>here</strong></span> to try again.</Typography>}
        </Grid>
        {/* <Grid item xs={12} style={{textAlign: "center", alignItems: "center"}}>
          {/* <img
            src={new_logo}
            alt="Wiselayer"
            style={{
              height: 70,
            }}
          /> */}
          {/* <LogoLoader/> */}
          {/*{!roleError && <Typography sx={{ marginTop: "2rem" }}>{verbs[Math.floor(Math.random() * 13)]} {nouns[Math.floor(Math.random() * 6)]}<span className="bouncing-dots"><span
            className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span><span className="dot" style={{ marginLeft: "2px"}}>.</span></span></Typography>}
          {roleError && <Typography sx={{ marginTop: "2rem" }}>There was an error retrieving your profile, please click <span style={{ color: theme.palette.primary.main, cursor: "pointer" }} onClick={handleLogout}><strong>here</strong></span> to try again.</Typography>}
        </Grid> */}
      </Grid>
    </ThemeProvider>
  )
};

const Banner = ({ hide }) => {
  const dispatch = useDispatch();
  const closeBanner = useSelector(state => state.role.closeBanner);

  const handleCloseBanner = () => {
    dispatch(setCloseBanner());
  }

  return !closeBanner && !hide ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        container
        sx={{ height: "2rem", width: "100%", backgroundColor: theme.palette.primary.main, zIndex: theme.zIndex.drawer + 1, /*marginTop: "-18px !important",*/ position: "sticky"}}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <Typography sx={{ color: "white !important"}}>
            To improve the user experience, WiseLayer shows your <strong>HIGHEST VALUE ONLY</strong> alerts by default. To access all alerts click the <strong>ALL</strong> toggle.
          </Typography>
        </Grid>
        <Grid item sx={{position: "absolute", right: "10px"}}>
          <IconButton
            onClick={handleCloseBanner}
            size={"small"}
            variant={"contained"}
          >
            <CloseIcon sx={{ color: "white !important", fontSize: "14px"}}/>
          </IconButton>
        </Grid>
      </Grid>
    </ThemeProvider>
  ) : null;
}

export function WrappedComponent(props) {
  const navigate = useNavigate();
  const storedRedirectUrl = localStorage.getItem('redirectUrl');

  useEffect(() => {
    // look for localStorage redirect url
    // const storedRedirectUrl = localStorage.getItem('redirectUrl');
    // Check for a stored redirect URL in local storage and apply it to the window URL
    if (storedRedirectUrl) {
      console.log("WrappedComponent - storedRedirectUrl found:", storedRedirectUrl)
      const reactRoute = storedRedirectUrl.slice(window.location.href.length - 1);
      navigate(reactRoute);
      localStorage.removeItem("redirectUrl");
    }
    return () => {
      localStorage.removeItem("redirectUrl");
    }
  }, []);
  return storedRedirectUrl ? <AppLoader inRouter /> : (
    <>
    <div style={{ height: "18px" }} /> {/* Spacer on top of div */}
    <Box sx={{ display: "flex" }}>
      <Header hide={props.hideSidebar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "calc(100vh - 18px)",
          maxWidth: "calc(100vw - 18px) !important",
        }}
      >
        <Box
          sx={{
            pl: props.hideSidebar !== true ? 3 : (props.dashboardView && props.dashboardView === true ? "3rem" : "7rem") /*"231px"*/,
            pr: props.hideSidebar !== true ? 3 : (props.dashboardView && props.dashboardView === true ? "3rem" : "7rem") /*"231px"*/,
          }}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {props.inner}
          </ErrorBoundary>
        </Box>
        {!props.hideFooter && <Footer/>}
      </Box>
    </Box>
    </>
  );
}

const FDRouter = () => {
  const appReady = useSelector((state) => state.appState.isReady);
  const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
  const isAdmin = useSelector((state) => state.role.isAdmin);
  const isRestricted = useSelector((state) => state.role.isRestricted);
  const isAccrualsUser = useSelector(state => state.role.isAccrualsUser);
  const isModulesUser = useSelector(state => state.role.isModulesUser);
  return !appReady ? (
    <AppLoader/>
  ) : isModulesUser ? (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Routes>
            {/* new ModuleGetter route */}
            <Route
              index
              path=""
              element={
                <WrappedComponent 
                  hideSidebar={true}
                  inner={<ModuleGetter moduleTag={Constants.module_dashboard_tags.module__accruals} />} 
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            <Route
              path="dashboard/:dashboardName"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<Dashboard/>}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            {/* new DashboardReportGetter route - TODO: currently only works with module_tag app - will need to handle different modules */}
            <Route
              path="get_report"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<DashboardReportGetter moduleTag={Constants.module_dashboard_tags.module__accruals} />}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            <Route
              path="*"
              element={
                <WrappedComponent 
                  hideSidebar={true}
                  inner={<NotFound />} 
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
          </Routes>
        </div>
      </ThemeProvider>
    </Router>
  ) : isAccrualsUser ? (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Routes>
            {/* new DashboardGetter route */}
            <Route
              index
              path=""
              element={
                <WrappedComponent 
                  hideSidebar={true}
                  inner={<DashboardGetter moduleTag={Constants.module_dashboard_tags.module__accruals} />} 
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            <Route
              path="dashboard/:dashboardName"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<Dashboard/>}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            {/* new DashboardReportGetter route */}
            <Route
              path="get_report"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<DashboardReportGetter moduleTag={Constants.module_dashboard_tags.module__accruals} />}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            <Route
              path="*"
              element={
                <WrappedComponent 
                  hideSidebar={true}
                  inner={<NotFound />} 
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
          </Routes>
        </div>
      </ThemeProvider>
    </Router>
  ) : (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Routes>
            <Route
              index
              path=""
              element={<WrappedComponent inner={<AlertFeed />} />}
            />
            {!isRestricted && (
              <>
                <Route
                  path={"account"}
                  element={<WrappedComponent inner={<Account />} />}
                />
                <Route
                  path={"notifications"}
                  element={<WrappedComponent inner={<AppStore />} />}
                />
              </>
            )}
            <Route
              path="dashboard/:dashboardID"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<Dashboard/>}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            <Route
              path="redirect/:dashboardID/:alertID"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<Redirect />}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            <Route
              path="redirect/:dashboardID"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<Redirect />}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            <Route
              path="module/redirect/:moduleUuid"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<ModuleRedirect />}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            <Route
              path="get_report"
              element={
                <WrappedComponent
                  hideSidebar={true}
                  inner={<ReportRedirect />}
                  hideFooter={true}
                  dashboardView={true}
                />
              }
            />
            {isAdmin && (
              <>
                <Route
                  path={"integrations"}
                  element={<WrappedComponent inner={<Integrations />} />}
                />
                <Route
                  path={"messages"}
                  element={<WrappedComponent inner={<AlertMessages />} />}
                />
                <Route
                  path="redirect/alert_message/:dashboardID/:alertID"
                  element={
                    <WrappedComponent
                      hideSidebar={true}
                      inner={<AlertMessageRedirect />}
                      hideFooter={true}
                      dashboardView={true}
                    />
                  }
                />
              </>
            )}
            {isSysAdmin && (
              <>
                <Route
                  path="module/redirect/:moduleUuid/:companyUuid"
                  element={
                    <WrappedComponent
                      hideSidebar={true}
                      inner={<ModuleRedirect />}
                      hideFooter={true}
                      dashboardView={true}
                    />
                  }
                />
                <Route
                  path="redirect/:dashboardID/:alertID/:companyID"//"redirect/:dashboardID"
                  element={
                    <WrappedComponent
                      hideSidebar={true}
                      inner={<Redirect />}
                      hideFooter={true}
                      dashboardView={true}
                    />
                  }
                />
                <Route
                  path="redirect/alert_message/:dashboardID/:alertID/:companyID"
                  element={
                    <WrappedComponent
                      hideSidebar={true}
                      inner={<AlertMessageRedirect />}
                      hideFooter={true}
                      dashboardView={true}
                    />
                  }
                />
                <Route
                  path="sysadmin_controls"
                  element={<WrappedComponent inner={<SysAdminControls />} />}
                />
                <Route
                  path="build_dashboard"
                  element={<WrappedComponent inner={<AppBuilder />} />}
                />
                <Route
                  path="edit_dashboard"
                  element={<WrappedComponent inner={<DashboardEditor/>}/>}
                />
                <Route
                  path="preview_dashboard"
                  element={
                    <WrappedComponent
                      dashboardView={true}
                      hideSidebar={true}
                      inner={<Dashboard/>}
                    />
                  }
                />
              </>
            )}
            <Route
              path="*"
              element={<WrappedComponent inner={<NotFound />} />}
            />
          </Routes>
        </div>
      </ThemeProvider>
    </Router>
  )
}

const ResourceWrapper = () => {
  const isLoggedIn = useSelector(state => state.role.isCaptured);
  return isLoggedIn ? (
    <>
      <SSE />
      <IntegrationMetadataRequest/>
      <AppsRequest/>
      <FilteredAppsManager/>
      <AppsCategoriesManager/>
      <AppsRequestSysAdmin/>
      <AlertsRequest/>
      <AlertsSysAdminRequest/>
      <SummaryMetricsRequest/>
      <ErrorLogRequest />
      <SourcesRequest />
      <LoginNotificationRequest />
      <MixpanelIdentify />
      <MixpanelSignIn />
      <MixpanelTrack />
      <AppStateManager />
      <PreviewAppSandboxDestinationsRequest />
      <PreviewAppRequest/>
      <AlertsUpdateRequest/>
      <AlertsNotificationRequest />
      <AlertConfigsSysAdminRequest />
      <AppsSubscriptionsRequest />
      <CustomerSubsManager />
      <PostSetupCompleteRequest />
      <AlertMessagesRequest />
      <AlertMessagesCategoryManager />
      <AlertSyncsRequest/>
      <DashboardRequest/>
      <ModulesRequest/>
      <DownloadJeCsvRequest/>
    </>
  ) : null;
}

function App(props) {
  if (process.env.NODE_ENV !== "development") {
    // console.log = () => {}; // silences all console logs in prod
  }
  const dispatch = useDispatch();
  // const appReady = useSelector((state) => state.appState.isReady);

  useEffect(() => {
    // ----------------- Mixpanel ------------------
    mixpanel.init(mixpanelProjectToken, {debug: true});
    dispatch(setMixpanelInitialized(true));

    return () => {
      console.log("App.js useEffect cleanup");
    };
  }, []);

  return (
    <Fragment>
      <RoleRequest />
      <ResourceWrapper/>
      <FDRouter/>
    </Fragment>
  );
}

export default App;
