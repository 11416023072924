import { setUpFilters, setFilters, setMultipleFilters, setFiltersInputs } from '../dashboardFiltersSlice';

// Single timeout ID for search filter debouncing
let searchDebounceTimeout = null;
const DEBOUNCE_TIME = 300; // milliseconds - adjust as needed

export const dashboardFiltersMiddleware = store => next => action => {
	// Check if the action is setUpFilters
	if (action.type === setUpFilters.type) {
		// Get the current state
		const state = store.getState();
		// Extract companyUuid and uuid from dashboard slice
		const { companyUuid, uuid } = state.dashboard;
		
		// Enrich the action payload with companyUuid and uuid
		const enrichedAction = {
			...action,
			payload: {
				...action.payload,
				companyUuid,
				uuid
			}
		};
		
		// Replace the original action with the enriched one
		return next(enrichedAction);
		
	} else if (action.type === setFilters.type) {
		const state = store.getState();
		const { uuid } = state.dashboard;
		const enrichedAction = {
			...action,
			payload: {
				...action.payload,
				uuid
			}
		};
		
		// Check if this is a search filter that needs debouncing
		if (action.payload.filterType === "search" && !action.payload._debounced) {
			// Immediately dispatch setFiltersInputs with objectName and target
			store.dispatch({
				type: setFiltersInputs.type,
				payload: {
					objectName: action.payload.objectName,
					target: action.payload.target,
					uuid
				}
			});
			
			// Clear any existing timeout
			if (searchDebounceTimeout) {
				clearTimeout(searchDebounceTimeout);
			}
			
			// Set a new timeout for the actual filter
			searchDebounceTimeout = setTimeout(() => {
				// Add a _debounced flag to avoid re-processing this action
				store.dispatch({
					...enrichedAction,
					payload: {
						...enrichedAction.payload,
						_debounced: true
					}
				});
				// Clear the timeout reference
				searchDebounceTimeout = null;
			}, DEBOUNCE_TIME);
			
			// Don't pass the original action through yet
			return;
		}
		
		// For non-search filters or already debounced actions, proceed normally
		return next(enrichedAction);

	} else if (action.type === setMultipleFilters.type) {
		const state = store.getState();
		const { uuid } = state.dashboard;
		const enrichedAction = {
			...action,
			payload: {
				filters: action.payload,
				uuid
			}
		};
		return next(enrichedAction);
	}
	
	// Let the action proceed
	return next(action);
};

export default dashboardFiltersMiddleware;
// import { setUpFilters, setFilters, setMultipleFilters, setFiltersInputs } from '../dashboardFiltersSlice';

// // Single timeout ID for search filter debouncing
// let searchDebounceTimeout = null;
// const DEBOUNCE_TIME = 300; // milliseconds - adjust as needed

// export const dashboardFiltersMiddleware = store => next => action => {
// 	// Check if the action is setUpFilters
// 	if (action.type === setUpFilters.type) {
// 		// Get the current state
// 		const state = store.getState();
// 		// Extract companyUuid and uuid from dashboard slice
// 		const { companyUuid, uuid } = state.dashboard;
		
// 		// Enrich the action payload with companyUuid and uuid
// 		const enrichedAction = {
// 			...action,
// 			payload: {
// 				...action.payload,
// 				companyUuid,
// 				uuid
// 			}
// 		};
		
// 		// Replace the original action with the enriched one
// 		return next(enrichedAction);
		
// 	} else if (action.type === setFilters.type) {
// 		console.log("setFilters middleware", action.payload);
// 		const state = store.getState();
// 		const { uuid } = state.dashboard;
// 		const enrichedAction = {
// 			...action,
// 			payload: {
// 				...action.payload,
// 				uuid
// 			}
// 		};
		
// 		// Check if this is a search filter that needs debouncing
// 		if (action.payload.filterType === "search") {
// 			console.log("setFilters middleware - search filter", action.payload);
// 			// Immediately dispatch setFiltersInputs with objectName and target
// 			console.log("setFilters middleware - search filter - dispatching setFiltersInputs", action.payload.objectName, action.payload.target, uuid);
// 			store.dispatch({
// 				type: setFiltersInputs.type,
// 				payload: {
// 					objectName: action.payload.objectName,
// 					target: action.payload.target,
// 					uuid
// 				}
// 			});
			
// 			// Clear any existing timeout
// 			if (searchDebounceTimeout) {
// 				clearTimeout(searchDebounceTimeout);
// 			}
			
// 			// Set a new timeout for the actual filter
// 			searchDebounceTimeout = setTimeout(() => {
// 				console.log("setFilters middleware - search filter - dispatching setFilters", enrichedAction);
// 				// Dispatch the actual enriched setFilters action after debounce time
// 				store.dispatch(enrichedAction);
// 				// Clear the timeout reference
// 				searchDebounceTimeout = null;
// 			}, DEBOUNCE_TIME);
			
// 			// Don't pass the original action through yet
// 			return;
// 		}
		
// 		// For non-search filters, proceed normally
// 		return next(enrichedAction);

// 	} else if (action.type === setMultipleFilters.type) {
// 		const state = store.getState();
// 		const { uuid } = state.dashboard;
// 		const enrichedAction = {
// 			...action,
// 			payload: {
// 				filters: action.payload,
// 				uuid
// 			}
// 		};
// 		return next(enrichedAction);
// 	}
	
// 	// Let the action proceed
// 	return next(action);
// };

// export default dashboardFiltersMiddleware;
// // import { setUpFilters, setFilters, setMultipleFilters } from '../dashboardFiltersSlice';

// // export const dashboardFiltersMiddleware = store => next => action => {
// // 	// Check if the action is either setGetDataArgs or setGetReportDataArgs
// // 	if (action.type === setUpFilters.type) {
// // 		// Get the current state
// // 		const state = store.getState();
// // 		// Extract companyUuid and uuid from dashboard slice
// // 		const { companyUuid, uuid } = state.dashboard;
		
// // 		// Enrich the action payload with companyUuid and uuid
// // 		const enrichedAction = {
// // 			...action,
// // 			payload: {
// // 				...action.payload,
// // 				companyUuid,
// // 				uuid
// // 			}
// // 		};
		
// // 		// Replace the original action with the enriched one
// // 		return next(enrichedAction);
		
// // 	} else if (action.type === setFilters.type) {
// // 		const state = store.getState();
// // 		const { uuid } = state.dashboard;
// // 		const enrichedAction = {
// // 			...action,
// // 			payload: {
// // 				...action.payload,
// // 				uuid
// // 			}
// // 		};
// // 		return next(enrichedAction);

// // 	} else if (action.type === setMultipleFilters.type) {
// // 		const state = store.getState();
// // 		const { uuid } = state.dashboard;
// // 		const enrichedAction = {
// // 			...action,
// // 			payload: {
// // 				filters: action.payload,
// // 				uuid
// // 			}
// // 		};
// // 		return next(enrichedAction);

// // 	}
	
	
// // 	// Let the action proceed
// // 	return next(action);
// // };
// // export default dashboardFiltersMiddleware;