import { useState, useEffect } from "react";
import isValidJson from "../../../../helpers/isValidJson";
import * as Constants from "../../../../Constants";
import { view_options } from "./useToolbar";

export const isValidList = (jsonValue) => {
	// TODO
	// when i put it in formState it will already be valStr (presumably string - confirm this)
	/*
	isValid:
	
	*/
	return true;
}

export const isValidKeyValue = (JsonValue) => {
	// TODO
	// when i put it in formState it will already be valStr (presumably string - confirm this)
	/*
	isValid:
	
	*/
	return true;
}

export const isValidDate = (value) => {
	// Matches: MM/DD/YYYY where:
	// MM is 01-12
	// DD is 01-31
	// YYYY is 4 digits
	// const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
	// return regex.test(value);
	const date = new Date(value);
	return !isNaN(date);
}

export const isValidFloat = (value) => {
	// Matches: optional minus sign + one or more digits + required decimal point + one or more digits
	const regex = /^-?\d+\.\d+$/;
	return regex.test(value);
}

export const isValidInteger = (value) => {
	// Matches: optional minus sign + one or more digits
	const regex = /^-?\d+$/;
	return regex.test(value);
}

export const config_types = {
	TEXT: "TEXT",
	DROPDOWN: "DROPDOWN",
	INTEGER: "INTEGER",
	FLOAT: "FLOAT",
	BOOLEAN: "BOOLEAN",
	KEY_VALUE: "KEY_VALUE",
	LIST: "LIST",
	DATE: "DATE"
}

export const defaultDropdownValueList = [
	"Option 1",
	"Option 2"
]

export const config_defaults = {
	// NO VALUE FOR DROPDOWN - dynamically generated based on user input
	[config_types.TEXT]: "",
	[config_types.INTEGER]: "0",
	[config_types.FLOAT]: "0.0",
	[config_types.BOOLEAN]: "false",
	[config_types.KEY_VALUE]: JSON.stringify({}),
	[config_types.LIST]: JSON.stringify([]),
	[config_types.DATE]: "00/00/0000",
}

const json_types = [
	config_types.KEY_VALUE,
	config_types.LIST
]

const string_types = [
	config_types.TEXT,
	config_types.DROPDOWN,
	config_types.INTEGER,
	config_types.FLOAT,
	config_types.BOOLEAN,
	config_types.DATE
]
/*
multiAppConfig:
{ 
	uuid: "string", 
	companyUuid: "string", 
	name: "string", 
	createdBy: "string", 
	configName: "string", // Unique 
	displayName: "string", 
	configSource: {}, // This would be the Sources enum/object 
	defaultValStr: "string", // Multi-type serialized as 
	string multiAppConfigType: "string", // This would be the enum value 
	dropdownValueList: ["string"], 
	sysAdminOnly: true, 
	objectType: "MULTI_APP_CONFIG" 
}

multiAppConfigInstance:
{ 
	uuid: "string", 
	companyUuid: "string", 
	name: "string", 
	createdBy: "string", 
	multiAppConfigUuid: "string", 
	configSource: {}, // Sources enum/object 
	valStr: "string", 
	multiAppConfigType: "string", // Enum value 
	displayNote: "string", 
	objectType: "MULTI_APP_CONFIG_INSTANCE" 
}
*/
// contains all multiAppConfig, multiAppConfigInstance, and config form fields (ex: "multiAppConfigType" vs "curMultiAppConfigType")
export const config_fields = {
	uuid: "uuid",
	multiAppConfigUuid: "multiAppConfigUuid",
	val: "val",
	displayNote: "displayNote",
	curVal: "curVal",
	curDisplayNote: "curDisplayNote",
	multiAppConfigType: "multiAppConfigType",
	curMultiAppConfigType: "curMultiAppConfigType",
	dropdownValueList: "dropdownValueList",
	curDropdownValueList: "curDropdownValueList",
	displayName: "displayName",
	curDisplayName: "curDisplayName",
	defaultValStr: "defaultValStr",
	defVal: "defVal",
	valStr: "valStr",
	defaultVal: "defaultVal",
	sysAdminOnly: "sysAdminOnly",
	curSysAdminOnly: "curSysAdminOnly",
	configName: "configName",
	curConfigName: "curConfigName",
	configSource: "configSource",
	curConfigSource: "curConfigSource",
}

export const generateConfig = (multiAppConfig) => {
	console.log("generateConfig - multiAppConfig", multiAppConfig)
	const displayName = multiAppConfig[config_fields.displayName];
	const configName = multiAppConfig[config_fields.configName];
	const dropdownValueList = multiAppConfig[config_fields.dropdownValueList];
	const defaultValStr = multiAppConfig[config_fields.defaultValStr];
	const uuid = multiAppConfig[config_fields.uuid];
	const type = multiAppConfig[config_fields.multiAppConfigType];
	const source = multiAppConfig[config_fields.configSource];
	const sysAdminOnly = multiAppConfig[config_fields.sysAdminOnly];
	return {
		[config_fields.uuid]: uuid,
		[config_fields.val]: defaultValStr,
		[config_fields.curVal]: defaultValStr,
		[config_fields.multiAppConfigType]: type,
		[config_fields.curMultiAppConfigType]: type,
		[config_fields.dropdownValueList]: dropdownValueList ?? null,
		[config_fields.curDropdownValueList]: dropdownValueList ?? null,
		[config_fields.displayName]: displayName,
		[config_fields.curDisplayName]: displayName,
		[config_fields.defVal]: defaultValStr,
		[config_fields.configSource]: source,
		[config_fields.curConfigSource]: source,
		[config_fields.configName]: configName,
		[config_fields.curConfigName]: configName,
		[config_fields.sysAdminOnly]: sysAdminOnly,
		[config_fields.curSysAdminOnly]: sysAdminOnly
	}
}

const generateConfigInstance = (instance, multiAppConfig) => {
	const displayName = multiAppConfig[config_fields.displayName];
	const configName = multiAppConfig[config_fields.configName];
	const dropdownValueList = multiAppConfig[config_fields.dropdownValueList];
	const defaultValStr = multiAppConfig[config_fields.defaultValStr];
	const uuid = multiAppConfig[config_fields.uuid];
	const type = multiAppConfig[config_fields.multiAppConfigType];
	const source = multiAppConfig[config_fields.configSource];
	return {
		[config_fields.uuid]: uuid,
		[config_fields.val]: instance ? instance[config_fields.valStr] : defaultValStr,
		[config_fields.displayNote]: instance ? instance[config_fields.displayNote] ?? "" : "",
		[config_fields.curVal]: instance ? instance[config_fields.valStr] : defaultValStr,
		[config_fields.curDisplayNote]: instance ? instance[config_fields.displayNote] ?? "" : "",
		[config_fields.multiAppConfigType]: type,
		[config_fields.dropdownValueList]: dropdownValueList ?? null,
		[config_fields.displayName]: displayName,
		[config_fields.defVal]: defaultValStr,
		[config_fields.configName]: configName
	}
}

const generateConfigs = (multiAppConfigs, multiAppConfigsInstances, viewOption) => {
	/*
		each form config needs these values:
		for update args:
		- uuid
		- val (form parameter value - new value to set config to)
		- displayNote (optional args field)
		for form control:
		- uuid (multiAppConfig.uuid)
		- curVal (valString - current parameter value - value config is currently set to)
		- curDisplayNote
		- multiAppConfigType
		- dropdownValueList (array || null) (multiAppConfigType === DROPDOWN || !multiAppConfigType === DROPDOWN)
	*/
	// return a config for each multiAppConfig - map its values into the config
	const configs = [];
	multiAppConfigs.forEach(multiAppConfig => {
		let config;
		if (viewOption === view_options.all) {
			config = generateConfig(multiAppConfig)
		} else {
			const uuid = multiAppConfig[config_fields.uuid];
			const instance = multiAppConfigsInstances.find(instance => instance[config_fields.multiAppConfigUuid] === uuid);
			config = generateConfigInstance(instance, multiAppConfig);
		}
		configs.push(config);
	})
	const sortedConfigs = [...configs].sort((a, b) => {
		return a[config_fields.displayName].localeCompare(b[config_fields.displayName]);
	})
	return sortedConfigs;
}

const validationMap = {
	/*
	TEXT: "TEXT",
	DROPDOWN: "DROPDOWN",
	INTEGER: "INTEGER",
	FLOAT: "FLOAT",
	BOOLEAN: "BOOLEAN",
	KEY_VALUE: "KEY_VALUE",
	LIST: "LIST",
	DATE: "DATE"
	*/
	[config_types.TEXT]: () => true,
	[config_types.DROPDOWN]: () => true,
	[config_types.INTEGER]: isValidInteger,
	[config_types.FLOAT]: isValidFloat,
	[config_types.BOOLEAN]: () => true,
	[config_types.KEY_VALUE]: isValidKeyValue,
	[config_types.LIST]: isValidList,
	[config_types.DATE]: isValidDate,
}

export const validateField = (val, type) => {
	// TODO - check type against config_types and use type validators from above
	try {
		const result = validationMap[type](val);
		return result;
	} catch (err) {
		console.warn(`error validating "${val}" with type "${type}"`)
		return false;
	}
	// return true;
}

export const needsUpdateArgs = (config, viewOption) => {
	console.log("needsUpdateArgs check - config", config, "viewOption", viewOption)
	// if val is different from curVal
	// if displayNote is different from curDisplayNote
	// needs update - note - might need special attention to make sure json fields are identical
	let result = false 
	if (config[config_fields.val] !== config[config_fields.curVal]) {
		console.log("config[config_fields.val] !== config[config_fields.curVal]")
		result = true;
	}
	if (viewOption !== view_options.all && config[config_fields.displayNote] !== config[config_fields.curDisplayNote]) {
		console.log("config[config_fields.displayNote] !== config[config_fields.curDisplayNote]")
		result = true;
	}
	if (viewOption === view_options.all) {
		// handling value change above - need to handle changes to:
		// - displayName
		// - multiAppConfigType
		// - dropdownValueList
		// - sysadmin -- should always be true for now
		if (config[config_fields.displayName] !== config[config_fields.curDisplayName]) {
			console.log("config[config_fields.displayName] !== config[config.curDisplayName]")
			result = true;
		}
		if (config[config_fields.multiAppConfigType] !== config[config_fields.curMultiAppConfigType]) {
			console.log("config[config_fields.multiAppConfigType] !== config[config_fields.curMultiAppConfigType]")
			result = true;
		}
		if (config[config_fields.multiAppConfigType] === config_types.DROPDOWN) {
			console.log("config[config_fields.multiAppConfigType] === config_types.DROPDOWN")
			// if its dropdown - compare current list to previous list
			const newList = config[config_fields.dropdownValueList].join("");
			const oldList = config[config_fields.curDropdownValueList]?.join("") ?? "";
			if (newList !== oldList) {
				console.log("newList !== oldList")
				result = true;
			}
		}
		if (config[config_fields.sysAdminOnly] !== config[config_fields.curSysAdminOnly]) {
			console.log("config[config_fields.sysAdminOnly] !== config[config_fields.curSysAdminOnly]")
			result = true;
		}
	}
	if (result === true) {
		console.log(`config ${config[config_fields.uuid]} needs update `, config)
	}
	return result;
}

export const needsResetArgs = (config) => {
	let result = false;
	if (config[config_fields.curVal] !== config[config_fields.defVal]) {
		result = true;
	}
	if (config[config_fields.curDisplayNote] !== "") {
		result = true;
	}
	return result;
}

const validateForm = (configs, viewOption) => {
	const needsUpdate = configs.some(config => needsUpdateArgs(config, viewOption));
	const validValues = !configs.some(config => !validateField(config[config_fields.val], config[config_fields.multiAppConfigType]));
	const noEmptyFields = viewOption !== view_options.all ? true : !configs.some(config => !config[config_fields.displayName]);
	return needsUpdate && validValues && noEmptyFields
}

export const useMultiAppConfigsForm = (
	multiAppConfigs, 
	multiAppConfigsInstances,
	refreshMultiAppConfigs,
	refreshMultiAppConfigsInstances,
	selectedCompany,
	viewOption
) => {
	const [configs, setConfigs] = useState([]);
	const [updateArgs, setUpdateArgs] = useState(null);
	
	const handleChange = (newValue, uuid, config_field, additionalValues) => {
		const updatedConfigs = configs.map(config => {
			if (config[config_fields.uuid] === uuid) {
				if (additionalValues) {
					return {
						...config,
						[config_field]: newValue,
						...additionalValues
					}
				}
				return {
					...config,
					[config_field]: newValue
				}
			} else {
				return config
			}
		})
		setConfigs(updatedConfigs)
	}
	
	const handleFormReset = () => {
		const newConfigs = generateConfigs(multiAppConfigs, multiAppConfigsInstances, viewOption)
		setConfigs(newConfigs)
	}
	
	const handleUpdate = () => {
		const configsToUpdate = configs.filter(config => needsUpdateArgs(config, viewOption));
		const argsArr = [];
		configsToUpdate.forEach(config => {
			if (viewOption === view_options.all) {
				// updating config definitions
				/*
				update multiAppConfig args:
				multiAppConfigUuid String
				
				displayName String
				
				defaultVal Object
				
				multiAppConfigType String (From MultiAppConfigTypeEnum: TEXT, DROPDOWN, etc.)
				
				sysAdminOnly Boolean
				
				dropdownValueList List<String> (Optional)
				*/
				const tempBody = {
					[config_fields.multiAppConfigUuid]: config[config_fields.uuid],
					[config_fields.displayName]: config[config_fields.displayName],
					[config_fields.defaultVal]: config[config_fields.val],
					[config_fields.multiAppConfigType]: config[config_fields.multiAppConfigType],
					[config_fields.sysAdminOnly]: config[config_fields.sysAdminOnly],
					[config_fields.dropdownValueList]: null
				}
				if (config[config_fields.multiAppConfigType] === config_types.DROPDOWN) {
					tempBody[config_fields.dropdownValueList] = config[config_fields.dropdownValueList]
				}
				const tempArgs = {
					url: Constants.SERVER_SYSADMIN_UPDATE_MULTI_APP_CONFIG_URL,
					method: "POST",
					body: JSON.stringify(tempBody)
				}
				argsArr.push(tempArgs);
			} else {
				// updating instances by app or module
				const tempBody = {
					[config_fields.multiAppConfigUuid]: config[config_fields.uuid],
					[config_fields.val]: config[config_fields.val]
				}
				if (config[config_fields.displayNote] !== config[config_fields.curDisplayNote]) {
					tempBody[config_fields.displayNote] = config[config_fields.displayNote]
				}
				const tempArgs = {
					url: Constants.SERVER_SYSADMIN_UPDATE_MULTI_APP_CONFIG_INSTANCE_URL + selectedCompany.uuid,
					method: "POST",
					body: JSON.stringify(tempBody)
				}
				argsArr.push(tempArgs)	
			}
		})
		if (argsArr.length === 0) {
			console.log("no updates to make")
		} else {
			console.log("updateArgs", argsArr)
			setUpdateArgs(argsArr);
		}
	}
	
	const handleDelete = (uuid) => {
		const tempBody = {
			[config_fields.multiAppConfigUuid]: uuid
		}
		const tempArgs = {
			url: Constants.SERVER_SYSADMIN_DELETE_MULTI_APP_CONFIG_URL,
			method: "POST",
			body: JSON.stringify(tempBody)
		}
		setUpdateArgs([tempArgs])
	}
	
	const handleReset = () => {
		const configsToReset = configs.filter(config => needsResetArgs(config));
		const argsArr = [];
		configsToReset.forEach(config => {
			const tempBody = {
				[config_fields.multiAppConfigUuid]: config[config_fields.uuid],
			}
			const tempArgs = {
				url: Constants.SERVER_SYSADMIN_RESET_MULTI_APP_CONFIG_INSTANCE_URL + selectedCompany.uuid,
				method: "POST",
				body: JSON.stringify(tempBody)
			}
			argsArr.push(tempArgs)
		})
		if (argsArr.length === 0) {
			console.log("no multiAppConfigsInstance updates to make")
		} else {
			console.log("resetting multiAppConfigsInstances", argsArr)
			setUpdateArgs(argsArr);
		}
	}
	
	const handleIndividualReset = (config) => {
		const argsArr = [];
		const tempBody = {
			[config_fields.multiAppConfigUuid]: config[config_fields.uuid],
		}
		const tempArgs = {
			url: Constants.SERVER_SYSADMIN_RESET_MULTI_APP_CONFIG_INSTANCE_URL + selectedCompany.uuid,
			method: "POST",
			body: JSON.stringify(tempBody)
		}
		argsArr.push(tempArgs)
		setUpdateArgs(argsArr)
	}
	
	const isValid = validateForm(configs, viewOption);
	
	const isValidReset = !configs.some(config => needsUpdateArgs(config, viewOption)) 
		&& configs.some(config => needsResetArgs(config));
	console.log("isValidRest", isValidReset);
	const handleUpdateSuccess = () => {
		// need to set updateArgs to null when all args fired
		// if viewOption === view_options.all - refresh multiAppConfigs
		// else refresh multiAppConfigsInstances
		if (updateArgs.length === 1) {
			setUpdateArgs(null);
			if (viewOption === view_options.all) {
				refreshMultiAppConfigs();
			} else {
				refreshMultiAppConfigsInstances();	
			}
		} else {
			setUpdateArgs(prevArgs => prevArgs.slice(1))
		}
		
	}
	
	useEffect(() => {
		console.log("useMultiAppConfigsForm - multiAppConfigs", multiAppConfigs)
		const newConfigs = generateConfigs(multiAppConfigs, multiAppConfigsInstances, viewOption)
		console.log("useMultiAppConfigsForm - newConfigs", newConfigs)
		setConfigs(newConfigs)
	}, [multiAppConfigs, multiAppConfigsInstances, viewOption])
	
	return {
		configs,
		handleChange,
		handleFormReset,
		updateArgs,
		handleUpdate,
		handleDelete,
		handleReset,
		handleIndividualReset,
		isValid,
		isValidReset,
		handleUpdateSuccess
	}
}