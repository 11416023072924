import { createSlice } from '@reduxjs/toolkit';
import * as Constants from "../Constants";

const initialDashboardState = {
  companyUuid: "",// new 9/4
  getDataArgs: null,
  getReportDataArgs: null,
  getDataError: {
    isErrored: false,
    message: null,
  },
  configsReady: false,
  dashboardJson: null,
  tables: null,
  rows: null,// new 9/4
  uuid: "",
  entryPeriod: "",
  entryPeriodName: "",
  reportName: "",
  isReport: false,
  isLocked: false
}

export const getDataArgs = (appUuid, customerSubscriptionUuid, companyUuid) => {
  const tempArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_CUS_SUBBED_APP_URL + companyUuid : Constants.SERVER_CUS_SUBBED_APP_URL,
    method: "POST",
    body: JSON.stringify({
      customerSubscriptionUuid: customerSubscriptionUuid,
    }),
  };
  return tempArgs;
};

export const getReportDataArgs = (erpReportUuid, companyUuid) => {
  const tempReportArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_GET_ERP_REPORT_URL + companyUuid : Constants.SERVER_GET_ERP_REPORT_URL,
    method: "POST",
    body: JSON.stringify({
      erpReportUuid: erpReportUuid
    })
  }
  return tempReportArgs;
}

export const getDisplayConfigsArgs = (dashboardJson, dashboardUuid, companyUuid, entryPeriod) => {
  console.log("::getDisplayConfigsArgs::")
  console.log("dashboardJson", dashboardJson)
  console.log("dashboardUuid", dashboardUuid)
  console.log("companyUuid", companyUuid)
  console.log("entryPeriod", entryPeriod)
  const tempDisplayConfigsArgs = [];
  Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
    if (component.displayConfigEnabled) {
      // create args -> push to tempDisplayConfigArgs
      // developerAppUuid: str
      // sourceTableName: str
      // objectName: str
      // primaryKeyFieldList: List<str>
      // linkedTableForeignFieldMap: Map<str, List<str>>
      const tempBody = {
        developerAppUuid: dashboardUuid,
        sourceTableName: component.sourceTable,
        objectName: objectName,
        primaryKeyFieldList: component.primaryKeyFieldList,
        entryPeriod: entryPeriod
      }
      if (component.linkedTableForeignFieldMap) {
        tempBody["linkedTableForeignFieldMap"] = component.linkedTableForeignFieldMap
      }
      const tempDisplayConfigArgs = {
        url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_GET_OR_CREATE_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_GET_OR_CREATE_URL,
        method: "POST",
        body: JSON.stringify(tempBody)
      }
      tempDisplayConfigsArgs.push(tempDisplayConfigArgs);
    }
  })
  console.log("tempDisplayConfigsArgs", tempDisplayConfigsArgs)
  return tempDisplayConfigsArgs;
}

export const getDataArgsPayload = (getDataArgs, companyUuid, uuid) => {
  return {
    getDataArgs: getDataArgs,
    companyUuid: companyUuid ?? "",
    uuid: uuid,
  }
}

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    // setUuid: (state, action) => {
    //   console.log("setUuid", action.payload);
    //   state.uuid = action.payload;
    // },
    setGetDataArgs: (state, action) => {
      console.log("setGetDataArgs", action.payload);
      const { getDataArgs, companyUuid, uuid } = action.payload;
      state.getDataArgs = getDataArgs;
      state.companyUuid = companyUuid;
      state.uuid = uuid;
      state.isLocked = !!(companyUuid);
    },
    setGetReportDataArgs: (state, action) => {
      console.log("setGetReportDataArgs", action.payload);
      // what do I need to get report data args?
      const { getDataArgs, companyUuid, uuid } = action.payload;
      state.getReportDataArgs = getDataArgs;
      state.companyUuid = companyUuid;
      state.uuid = uuid;
      state.isLocked = !!(companyUuid);
    },
    setGetDataError: (state, action) => {
      // console.log("setGetDataError", action.payload);
      state.getDataError = {
        isErrored: true,
        message: action.payload
      };
    },
    setDashboardData: (state, action) => {
      console.log("setDashboardData", action.payload);
      console.warn("dashboardJson", action.payload.dashboardJson);
      console.warn("tables", action.payload.tables);
      // set entryPeriod and entryPeriodName from first available createEntryConfig
      let entryPeriod;
      let entryPeriodName;
      let reportName;
      Object.entries(action.payload.dashboardJson.components).forEach(([objectName, component]) => {
        if (entryPeriod) return;
        if (component.createEntryConfig && component.createEntryConfig.entryPeriod) {
          entryPeriod = component.createEntryConfig.entryPeriod;
          entryPeriodName = component.createEntryConfig.entryPeriodName;
          reportName = component.createEntryConfig.reportName;
        } else if (component.overwriteColumnConfig && component.overwriteColumnConfig.displayConfigEntryPeriod) {
          entryPeriod = component.overwriteColumnConfig.displayConfigEntryPeriod;
        }
      })
      state.isReport = action.payload.isReport ?? false;
      state.entryPeriod = entryPeriod ?? "";
      state.entryPeriodName = entryPeriodName ?? "";
      state.reportName = reportName ?? "";
      state.dashboardJson = action.payload.dashboardJson;
      state.tables = action.payload.tables;
      state.getDataArgs = null;
    },
    setConfigsReady: (state) => {
      console.log("setConfigsReady");
      state.configsReady = true;
    },
    setDashboardPreviewData: (state, action) => {
      console.log("setDashboardPreviewData", action.payload);
      console.warn("preview dashboardJson", action.payload.dashboardJson);
      console.warn("preview tables", action.payload.tables);
      state.dashboardJson = action.payload.dashboardJson;
      state.tables = action.payload.tables;
      state.configsReady = true;
    },
    setRows: (state, action) => {
      console.log("setRows", action.payload);
      state.rows = action.payload;
    },
    setIsReport: (state, action) => {
      console.log("setIsReport", action.payload);
      state.isReport = action.payload;
    },
    setIsLocked: (state, action) => {
      console.log("setIsLocked", action.payload);
      state.isLocked = action.payload;
    },
    resetDashboardSlice: (state) => {
      console.log("resetDashboardSlice");
      return initialDashboardState;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setGetDataArgs,
  setGetReportDataArgs,
  setGetDataError,
  setDashboardData,
  setConfigsReady,
  setDashboardPreviewData,
  setRows,
  setIsReport,
  setIsLocked,
  resetDashboardSlice
} = dashboardSlice.actions;

export default dashboardSlice.reducer;