import { useSelector, useDispatch } from "react-redux";
import { useRef, useState, useMemo } from "react";
import { config_options, dashboardConfigsSelectors, updateConfig } from "../../../../store/dashboardConfigsSlice";
import {setAlertNotificationArgs} from "../../../../store/alertsNotificationSlice";
import { Popover, Button, TextField, Tooltip } from "@mui/material";
import { generateDisplayConfigUpdateArgs, generateDisplayConfigUpdateNotification } from "./DisplayConfigUpdateUtils";

const DisplayConfigNotesInput = ({ handleUpdate, notes, disableActions }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const textFieldRef = useRef(null);// new

    const [message, setMessage] = useState("");

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        setMessage(notes);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = () => {
        handleUpdate(message);
        setOpen(false);
        setTimeout(() => {
            setMessage("");
        }, 300);
    };

    return (
        <>
            {/* Render the notes initially, which can be clicked */}
            <Tooltip title={`${!notes ? "Notes" : notes} - click to edit`} followCursor>
                <div
                    ref={anchorRef}
                    onClick={disableActions ? null : handleToggle}
                    style={{
                        cursor: 'pointer',
                        width: '100%',      // Ensure the div occupies the full cell width
                        height: '100%',     // Ensure the div occupies the full cell height
                        display: 'flex',    // This allows for easy centering
                        alignItems: 'center', // Vertically center the text
                        padding: '0 8px'    // Optional padding to prevent text touching the edges
                    }}
                >
                    <span style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%'
                    }}>
                        {notes}
                    </span>
                </div>
            </Tooltip>
            {/* Popover that contains the TextField */}
            <Popover
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                // props for child auto-focus/select
                TransitionProps={{
                    onEntered: () => {
                        if (textFieldRef.current) {
                            const input = textFieldRef.current.querySelector('textarea');
                            if (input) {
                                input.focus();
                                if (input.value) {
                                    // If there's existing text, move cursor to end
                                    input.setSelectionRange(input.value.length, input.value.length);
                                }
                            }
                        }
                    }
                }}
            >
                <div style={{ padding: 10, width: "350px", borderRadius: "10px" }}>
                    <TextField
                        ref={textFieldRef}// auto-focus/select ref
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={6}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        variant="outlined"
                        autoFocus// required prop for auto-focus/select
                    />
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 8, width: "100%" }}
                    >
                        Save
                    </Button>
                </div>
            </Popover>
        </>
    )
}

export const DisplayConfigNotes = ({ row, displayConfigUuid, disableActions }) => {
    const dispatch = useDispatch();
    const isLocked = useSelector(state => state.dashboard.isLocked);
    const dashboardUuid = useSelector(state => state.dashboard.uuid);
    const user = useSelector((state) => state.role.name);
    const isInternal = useSelector((state) => state.role.isInternal);
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    const entryPeriod = useSelector(state => state.dashboard.entryPeriod);
    
    const primaryKeyFieldList = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.primaryKeyFieldList);
    const linkedTableForeignFieldMap = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.linkedTableForeignFieldMap);
    const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.displayConfigTableRows);
    const displayConfigTableRow = displayConfigTableRows?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap).every(([field, value]) => row[field] === value));
    const displayConfigOptions = displayConfigTableRow?.displayConfigOptions;
    const notes = (displayConfigOptions && displayConfigOptions[config_options.notes]) ? displayConfigOptions[config_options.notes].optionValue : "";
    
    const handleUpdate = (notes) => {
        const configUpdateArgs = generateDisplayConfigUpdateArgs(
            companyUuid, 
            notes, 
            config_options.notes, 
            row, 
            displayConfigUuid, 
            entryPeriod, 
            primaryKeyFieldList, 
            linkedTableForeignFieldMap,
            null
        );
        // place isLocked check here so SysAdmin can see console logs from above function calls / errors that might occur generating args
        if (isLocked) {
            console.log(`isLocked: ${isLocked} => notes update skipped`);
            return;
        }
        if (!isInternal && !companyUuid) {// companyUuid means sysAdmin viewing dashboard
        const configUpdateNotification = generateDisplayConfigUpdateNotification(notes, config_options.notes, row, user, displayConfigUuid, dashboardUuid);
        console.log("configUpdateNotification", configUpdateNotification);
        dispatch(setAlertNotificationArgs(configUpdateNotification));
        }
        dispatch(updateConfig(displayConfigUuid, configUpdateArgs));
    }

    return (<DisplayConfigNotesInput handleUpdate={handleUpdate} notes={notes} disableActions={disableActions}/>);
}

export default DisplayConfigNotes;