import { useDispatch, useSelector } from 'react-redux';
import { setResetColumnsStateArgs, generateNewColumnsState, setInitialColumnsState } from '../../../../../../store/dashboardTableVizSlice';
import * as Constants from '../../../../../../Constants';

/**
 * @returns {handleReset: function}
 * 
 * @description
 * This hook is used to reset the columns state for a table.
 * It will work for both sysadmin and user
 */
export const useResetColumnsState = () => {
    const dispatch = useDispatch();
    const companyUuid = useSelector((state) => state.dashboard.companyUuid);
    const developerAppUuid = useSelector((state) => state.dashboard.uuid);
    const dashboardJson = useSelector((state) => state.dashboard.dashboardJson);
    const layoutUser = useSelector((state) => state.dashboardTableViz.sysAdmin.user);
    const savedColumnsState = useSelector((state) => state.dashboardTableViz.sysAdmin.savedColumnsState);

    const handleReset = () => {
        if (companyUuid) {
            if (layoutUser && savedColumnsState) {
                const resetArgs = {
                    url: Constants.SERVER_SYSADMIN_POST_DELETE_USER_DASHBOARD_LAYOUT_URL + companyUuid,
                    method: "POST",
                    body: JSON.stringify({
                        developerAppUuid: developerAppUuid,
                        layoutUser: layoutUser
                    })
                }
                dispatch(setResetColumnsStateArgs(resetArgs));    
            } else {
                dispatch(setInitialColumnsState(generateNewColumnsState(dashboardJson)));
            }
        } else {
            const resetArgs = {
                url: Constants.SERVER_POST_DELETE_USER_DASHBOARD_LAYOUT_URL,
                method: "POST",
                body: JSON.stringify({
                    developerAppUuid: developerAppUuid
                })
            }
            dispatch(setResetColumnsStateArgs(resetArgs));
        }
        
    }

    return { handleReset };
}