import { Grid, Typography } from "@mui/material";
import { JobsButtons } from "./JobButtons";
import JobCard from "./JobCard";
export const JobsList = ({
    databricksJobs,
    handleDatabricksJobsRefresh,
    modules,
    companies
}) => {

    const { inProgress, queued, notReady } = databricksJobs;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <JobsButtons handleDatabricksJobsRefresh={handleDatabricksJobsRefresh} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ opacity: 0.8 }}><strong>inProgress</strong></Typography>
            </Grid>
            {inProgress.map((job, index) => (
                <JobCard 
                    key={index + "_inProgress"} 
                    job={job} 
                    inProgress={true} 
                    handleDatabricksJobsRefresh={handleDatabricksJobsRefresh} 
                    modules={modules} 
                    companies={companies}
                />
            ))}
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ opacity: 0.8 }}><strong>queued</strong></Typography>
            </Grid>
            {queued.map((job, index) => (
                <JobCard 
                    key={index + "_queued"} 
                    job={job} 
                    inProgress={false} 
                    handleDatabricksJobsRefresh={handleDatabricksJobsRefresh} 
                    modules={modules} 
                    companies={companies}
                />
            ))}
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ opacity: 0.8 }}><strong>notReady</strong></Typography>
            </Grid>
            {notReady.map((job, index) => (
                <JobCard 
                    key={index + "_notReady"} 
                    job={job} 
                    inProgress={false} 
                    handleDatabricksJobsRefresh={handleDatabricksJobsRefresh} 
                    modules={modules} 
                    companies={companies}
                />
            ))}
        </Grid>
    )
}

export default JobsList;