import React, { useState, useEffect } from 'react';
// Material-UI imports
import { 
  Box, 
  Typography, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

// Sample data structure - replace with actual API calls
const sampleData = {
  tables: [
    {
      id: 'vendors-table',
      name: 'Vendors',
      overwriteColumnConfig: {
        overwriteColumnList: [
          {
            fieldName: 'email',
            isEntryPeriodSpecific: false,
            linkedMultiAppConfig: {
              multiAppConfigUuid: 'email-config-uuid',
              name: 'Vendor Email',
              type: 'map_text'
            }
          },
          {
            fieldName: 'category',
            isEntryPeriodSpecific: true,
            linkedMultiAppConfig: {
              multiAppConfigUuid: 'category-config-uuid',
              name: 'Vendor Category',
              type: 'map_dropdown',
              mapDropdownValues: ['Preferred', 'Standard', 'Limited']
            }
          },
          {
            fieldName: 'active',
            isEntryPeriodSpecific: false,
            linkedMultiAppConfig: {
              multiAppConfigUuid: 'active-config-uuid',
              name: 'Vendor Active Status',
              type: 'map_boolean'
            }
          }
        ]
      },
      primaryKeyFieldList: ['id'],
      rows: [
        { id: 'v1', name: 'Acme Corp', email: 'contact@acme.com', category: 'Standard', active: true },
        { id: 'v2', name: 'Widget Inc', email: 'info@widget.com', category: 'Preferred', active: true }
      ]
    },
    {
      id: 'products-table',
      name: 'Products',
      overwriteColumnConfig: {
        overwriteColumnList: [
          {
            fieldName: 'price',
            isEntryPeriodSpecific: true,
            linkedMultiAppConfig: {
              multiAppConfigUuid: 'price-config-uuid',
              name: 'Product Price',
              type: 'map_text'
            }
          },
          {
            fieldName: 'status',
            isEntryPeriodSpecific: false,
            linkedMultiAppConfig: {
              multiAppConfigUuid: 'status-config-uuid',
              name: 'Product Status',
              type: 'map_dropdown',
              mapDropdownValues: ['In Stock', 'Low Stock', 'Out of Stock', 'Discontinued']
            }
          },
          {
            fieldName: 'featured',
            isEntryPeriodSpecific: false,
            linkedMultiAppConfig: {
              multiAppConfigUuid: 'featured-config-uuid',
              name: 'Featured Product',
              type: 'map_boolean'
            }
          }
        ]
      },
      primaryKeyFieldList: ['productId'],
      rows: [
        { productId: 'p1', name: 'Widget Pro', price: '$99.99', status: 'In Stock', featured: false },
        { productId: 'p2', name: 'Super Gadget', price: '$149.99', status: 'Low Stock', featured: true },
        { productId: 'p3', name: 'Basic Tool', price: '$29.99', status: 'Out of Stock', featured: false }
      ]
    }
  ],
  userInputs: [
    {
      name: 'default_currency',
      Title: 'Default Currency',
      userSetMultiAppConfig: {
        multiAppConfigUuid: 'currency-config-uuid',
        name: 'Default Currency',
        type: 'dropdown',
        mapDropdownValues: ['USD', 'EUR', 'GBP', 'JPY', 'CAD', 'AUD', 'CHF']
      }
    },
    {
      name: 'notification_email',
      Title: 'Notification Email',
      userSetMultiAppConfig: {
        multiAppConfigUuid: 'email-config-uuid',
        name: 'Notification Email',
        type: 'text'
      }
    },
    {
      name: 'auto_refresh',
      Title: 'Auto-Refresh Dashboard',
      userSetMultiAppConfig: {
        multiAppConfigUuid: 'refresh-config-uuid',
        name: 'Auto-Refresh Dashboard',
        type: 'boolean'
      }
    },
    {
      name: 'refresh_interval',
      Title: 'Refresh Interval (minutes)',
      userSetMultiAppConfig: {
        multiAppConfigUuid: 'refresh-interval-config-uuid',
        name: 'Refresh Interval',
        type: 'dropdown',
        mapDropdownValues: ['1', '5', '10', '15', '30', '60']
      }
    },
    {
      name: 'default_view',
      Title: 'Default Dashboard View',
      userSetMultiAppConfig: {
        multiAppConfigUuid: 'default-view-config-uuid',
        name: 'Default View',
        type: 'dropdown',
        mapDropdownValues: ['Summary', 'Detailed', 'Analytics', 'Reports']
      }
    },
    {
      name: 'dark_mode',
      Title: 'Dark Mode',
      userSetMultiAppConfig: {
        multiAppConfigUuid: 'dark-mode-config-uuid',
        name: 'Dark Mode',
        type: 'boolean'
      }
    }
  ]
};

// Custom Tab Panel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const FDUserSettings = () => {
  // State
  const [tabValue, setTabValue] = useState(0);
  const [tableOverrides, setTableOverrides] = useState({});
  const [userInputValues, setUserInputValues] = useState({});
  const [multiAppConfigs, setMultiAppConfigs] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(null);
  const [tempEditValue, setTempEditValue] = useState('');

  // Initialize with empty values
  useEffect(() => {
    const initialTableOverrides = {};
    const initialUserInputs = {};
    const initialMultiAppConfigs = {};
    
    // Initialize table overrides with proper nested structure
    sampleData.tables.forEach(table => {
      initialTableOverrides[table.id] = {};
      
      // Initialize row-level objects using the correct primary key
      const primaryKey = table.primaryKeyFieldList[0];
      table.rows.forEach(row => {
        const rowId = row[primaryKey];
        initialTableOverrides[table.id][rowId] = {};
      });
      
      // Initialize multiAppConfigs for each linked config in the table
      table.overwriteColumnConfig.overwriteColumnList.forEach(column => {
        const configUuid = column.linkedMultiAppConfig.multiAppConfigUuid;
        if (!initialMultiAppConfigs[configUuid]) {
          initialMultiAppConfigs[configUuid] = {
            uuid: configUuid,
            name: column.linkedMultiAppConfig.name,
            type: column.linkedMultiAppConfig.type,
            values: {}
          };
          
          // For map types, initialize with empty key-value store
          if (column.linkedMultiAppConfig.type.startsWith('map_')) {
            initialMultiAppConfigs[configUuid].values = {};
          } else {
            initialMultiAppConfigs[configUuid].value = null;
          }
          
          // Add dropdown values if applicable
          if (column.linkedMultiAppConfig.type === 'map_dropdown' && 
              column.linkedMultiAppConfig.mapDropdownValues) {
            initialMultiAppConfigs[configUuid].dropdownValues = 
              column.linkedMultiAppConfig.mapDropdownValues;
          }
        }
      });
    });
    
    // Initialize user inputs
    sampleData.userInputs.forEach(input => {
      const configUuid = input.userSetMultiAppConfig.multiAppConfigUuid;
      
      // Set default value based on type
      initialUserInputs[input.name] = input.userSetMultiAppConfig.type === 'dropdown' 
        ? '' 
        : input.userSetMultiAppConfig.type === 'boolean'
          ? false
          : '';
      
      // Initialize multiAppConfig for this input
      if (!initialMultiAppConfigs[configUuid]) {
        initialMultiAppConfigs[configUuid] = {
          uuid: configUuid,
          name: input.userSetMultiAppConfig.name,
          type: input.userSetMultiAppConfig.type,
          value: input.userSetMultiAppConfig.type === 'dropdown' 
            ? '' 
            : input.userSetMultiAppConfig.type === 'boolean'
              ? false
              : ''
        };
        
        // Add dropdown values if applicable
        if (input.userSetMultiAppConfig.type === 'dropdown' && 
            input.userSetMultiAppConfig.mapDropdownValues) {
          initialMultiAppConfigs[configUuid].dropdownValues = 
            input.userSetMultiAppConfig.mapDropdownValues;
        }
      }
    });
    
    setTableOverrides(initialTableOverrides);
    setUserInputValues(initialUserInputs);
    setMultiAppConfigs(initialMultiAppConfigs);
  }, []);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handle user input change
  const handleUserInputChange = (name, value, configUuid) => {
    // Update user input value
    setUserInputValues(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Update corresponding multiAppConfig
    setMultiAppConfigs(prev => ({
      ...prev,
      [configUuid]: {
        ...prev[configUuid],
        value: value
      }
    }));
  };

  // Handle table cell override
  const handleTableCellOverride = (tableId, rowId, fieldName, value, configUuid) => {
    // Get primary key for this row
    const table = sampleData.tables.find(t => t.id === tableId);
    
    // Find the row using the correct primary key field
    const primaryKey = table.primaryKeyFieldList[0];
    const row = table.rows.find(r => r[primaryKey] === rowId);
    
    if (!row) {
      console.error(`Row with ID ${rowId} not found in table ${tableId}`);
      return;
    }
    
    // Update table overrides
    setTableOverrides(prev => {
      // Make sure the nested structure exists
      const newOverrides = { ...prev };
      if (!newOverrides[tableId]) {
        newOverrides[tableId] = {};
      }
      if (!newOverrides[tableId][rowId]) {
        newOverrides[tableId][rowId] = {};
      }
      
      // Set the override value
      newOverrides[tableId][rowId][fieldName] = value;
      return newOverrides;
    });
    
    // Update multiAppConfig for map types
    setMultiAppConfigs(prev => {
      const updatedConfig = { ...prev[configUuid] };
      updatedConfig.values = { ...updatedConfig.values, [rowId]: value };
      return {
        ...prev,
        [configUuid]: updatedConfig
      };
    });
    
    // Close edit modal if open
    if (editModalOpen) {
      setEditModalOpen(false);
      setCurrentEdit(null);
    }
  };

  // Open edit modal for a cell
  const openEditModal = (tableId, rowId, fieldName, currentValue, configUuid, configType) => {
    // Get any existing override value or use the current value
    const existingOverride = getOverriddenValue(tableId, rowId, fieldName);
    const initialEditValue = existingOverride !== undefined ? existingOverride : 
      (configType === 'map_boolean' ? Boolean(currentValue) : String(currentValue));
    
    setTempEditValue(initialEditValue);
    setCurrentEdit({
      tableId,
      rowId,
      fieldName,
      currentValue,
      configUuid,
      configType
    });
    setEditModalOpen(true);
  };

  // Save changes
  const saveChanges = () => {
    console.log('Saving changes...');
    console.log('User inputs:', userInputValues);
    console.log('Table overrides:', tableOverrides);
    console.log('MultiAppConfigs to be updated:', multiAppConfigs);
    
    // Here you would make API calls to save the changes
    // For each multiAppConfig, update the server
    Object.values(multiAppConfigs).forEach(config => {
      console.log(`Updating multiAppConfig ${config.uuid} (${config.name})`);
      if (config.type.startsWith('map_')) {
        console.log('Map values:', config.values);
      } else {
        console.log('Value:', config.value);
      }
    });
    
    // Show success message or handle errors
  };

  // Reset changes
  const resetChanges = () => {
    console.log('Resetting changes...');
    // Reset to initial values
    const initialTableOverrides = {};
    const initialUserInputs = {};
    
    sampleData.tables.forEach(table => {
      initialTableOverrides[table.id] = {};
    });
    
    sampleData.userInputs.forEach(input => {
      initialUserInputs[input.name] = input.userSetMultiAppConfig.type === 'dropdown' 
        ? '' 
        : input.userSetMultiAppConfig.type === 'boolean'
          ? false
          : '';
    });
    
    setTableOverrides(initialTableOverrides);
    setUserInputValues(initialUserInputs);
    
    // Reset multiAppConfigs as well
    const resetMultiAppConfigs = { ...multiAppConfigs };
    Object.keys(resetMultiAppConfigs).forEach(key => {
      if (resetMultiAppConfigs[key].type.startsWith('map_')) {
        resetMultiAppConfigs[key].values = {};
      } else {
        resetMultiAppConfigs[key].value = null;
      }
    });
    
    setMultiAppConfigs(resetMultiAppConfigs);
  };

  // Check if a cell has been overridden
  const isCellOverridden = (tableId, rowId, fieldName) => {
    return tableOverrides[tableId]?.[rowId]?.[fieldName] !== undefined;
  };

  // Get the overridden value for a cell
  const getOverriddenValue = (tableId, rowId, fieldName) => {
    return tableOverrides[tableId]?.[rowId]?.[fieldName];
  };

  // Render edit modal
  const renderEditModal = () => {
    if (!currentEdit) return null;
    
    const { tableId, rowId, fieldName, currentValue, configUuid, configType } = currentEdit;
    
    // Check if this cell has an override
    const isOverridden = isCellOverridden(tableId, rowId, fieldName);
    
    return (
      <Dialog 
        open={editModalOpen} 
        onClose={() => setEditModalOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          Override {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" gutterBottom>
            Original Value:
          </Typography>
          <Typography variant="body2" gutterBottom>
            {currentValue !== undefined ? 
              (typeof currentValue === 'boolean' ? (currentValue ? 'Yes' : 'No') : String(currentValue)) 
              : 'N/A'}
          </Typography>
          
          {configType === 'map_text' && (
            <TextField
              autoFocus
              margin="dense"
              label="New Value"
              fullWidth
              value={tempEditValue}
              onChange={(e) => setTempEditValue(e.target.value)}
              style={{ marginTop: '16px' }}
            />
          )}
          
          {configType === 'map_dropdown' && (
            <FormControl fullWidth margin="dense" style={{ marginTop: '16px' }}>
              <InputLabel>New Value</InputLabel>
              <Select
                value={tempEditValue}
                onChange={(e) => setTempEditValue(e.target.value)}
                label="New Value"
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {multiAppConfigs[configUuid]?.dropdownValues?.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          
          {configType === 'map_boolean' && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(tempEditValue)}
                  onChange={(e) => setTempEditValue(e.target.checked)}
                />
              }
              label="Enabled"
              style={{ marginTop: '16px' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)}>
            Cancel
          </Button>
          <Button 
            onClick={() => {
              // Apply the override with the temp value
              handleTableCellOverride(
                tableId, 
                rowId, 
                fieldName, 
                tempEditValue, 
                configUuid
              );
            }}
            color="primary"
          >
            Save
          </Button>
          {isOverridden && (
            <Button 
              onClick={() => {
                // Remove override
                setTableOverrides(prev => {
                  const newOverrides = { ...prev };
                  if (newOverrides[tableId]?.[rowId]) {
                    const { [fieldName]: _, ...rest } = newOverrides[tableId][rowId];
                    newOverrides[tableId][rowId] = rest;
                  }
                  return newOverrides;
                });
                
                // Remove from multiAppConfig
                setMultiAppConfigs(prev => {
                  const updatedConfig = { ...prev[configUuid] };
                  
                  if (updatedConfig.values) {
                    const { [rowId]: _, ...rest } = updatedConfig.values;
                    updatedConfig.values = rest;
                  }
                  
                  return {
                    ...prev,
                    [configUuid]: updatedConfig
                  };
                });
                
                setEditModalOpen(false);
              }}
              color="error"
            >
              Remove Override
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800 }}>
      <Typography variant="body1" fontWeight="medium" sx={{ mb: 0.5 }}>
        User Preferences
      </Typography>
      
      {/* Material-UI Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          aria-label="user preferences tabs"
          variant="fullWidth"
          sx={{ minHeight: 32 }}
        >
          <Tab label="Global Settings" sx={{ fontSize: '0.8rem', py: 0.5, minHeight: 32 }} />
          <Tab label="Table Customizations" sx={{ fontSize: '0.8rem', py: 0.5, minHeight: 32 }} />
        </Tabs>
      </Box>
      
      {/* Global Settings Tab */}
      <TabPanel value={tabValue} index={0}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {sampleData.userInputs.map((input) => (
            <Box key={input.name} sx={{ mb: 0.5 }}>
              {input.userSetMultiAppConfig.type === 'dropdown' && (
                <FormControl fullWidth size="small" margin="dense">
                  <Select
                    value={userInputValues[input.name] || ''}
                    onChange={(e) => handleUserInputChange(
                      input.name, 
                      e.target.value, 
                      input.userSetMultiAppConfig.multiAppConfigUuid
                    )}
                    displayEmpty
                    sx={{ 
                      '& .MuiSelect-select': { 
                        py: 0.5, 
                        fontSize: '0.8rem',
                        display: 'flex',
                        alignItems: 'center',
                        height: '18px',
                        color: userInputValues[input.name] ? 'inherit' : 'rgba(0, 0, 0, 0.6)'
                      } 
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span>{input.Title}</span>;
                      }
                      return selected;
                    }}
                  >
                    <MenuItem value="" sx={{ fontSize: '0.8rem' }}><em>Select...</em></MenuItem>
                    {input.userSetMultiAppConfig.mapDropdownValues.map((option) => (
                      <MenuItem key={option} value={option} sx={{ fontSize: '0.8rem' }}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              
              {input.userSetMultiAppConfig.type === 'text' && (
                <TextField
                  label={input.Title}
                  value={userInputValues[input.name] || ''}
                  onChange={(e) => handleUserInputChange(
                    input.name, 
                    e.target.value, 
                    input.userSetMultiAppConfig.multiAppConfigUuid
                  )}
                  fullWidth
                  size="small"
                  margin="dense"
                  InputLabelProps={{ 
                    style: { 
                      fontSize: '0.8rem' 
                    },
                    shrink: userInputValues[input.name] ? true : undefined
                  }}
                  inputProps={{ 
                    style: { 
                      fontSize: '0.8rem', 
                      padding: '8px 10px',
                      height: '18px'
                    } 
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      display: 'flex',
                      alignItems: 'center'
                    }
                  }}
                />
              )}
              
              {input.userSetMultiAppConfig.type === 'boolean' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(userInputValues[input.name])}
                      onChange={(e) => handleUserInputChange(
                        input.name, 
                        e.target.checked, 
                        input.userSetMultiAppConfig.multiAppConfigUuid
                      )}
                      size="small"
                      sx={{ p: 0.25 }}
                    />
                  }
                  label={<Typography sx={{ fontSize: '0.8rem' }}>{input.Title}</Typography>}
                />
              )}
            </Box>
          ))}
        </Box>
      </TabPanel>
      
      {/* Table Customizations Tab */}
      <TabPanel value={tabValue} index={1}>
        {sampleData.tables.map((table) => (
          <Box key={table.id} sx={{ mb: 1 }}>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 500, mb: 0.2 }}>{table.name}</Typography>
            <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 300 }}>
              <Table size="small" stickyHeader sx={{ '& .MuiTableCell-root': { py: 0.5, px: 1 } }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: '0.7rem' }}>Name</TableCell>
                    {table.overwriteColumnConfig.overwriteColumnList.map((column) => (
                      <TableCell key={column.fieldName} sx={{ fontSize: '0.7rem' }}>
                        {column.fieldName.charAt(0).toUpperCase() + column.fieldName.slice(1)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table.rows.map((row) => {
                    // Get the correct row ID based on the table's primaryKeyFieldList
                    const rowId = row[table.primaryKeyFieldList[0]];
                    
                    return (
                      <TableRow key={rowId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell sx={{ fontSize: '0.7rem' }}>{row.name}</TableCell>
                        {table.overwriteColumnConfig.overwriteColumnList.map((column) => {
                          // Use the correct rowId for checking overrides
                          const isOverridden = isCellOverridden(table.id, rowId, column.fieldName);
                          const overriddenValue = getOverriddenValue(table.id, rowId, column.fieldName);
                          
                          return (
                            <TableCell 
                              key={column.fieldName}
                              sx={{ 
                                position: 'relative',
                                backgroundColor: isOverridden ? 'rgba(25, 118, 210, 0.08)' : 'inherit'
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography 
                                  sx={{ 
                                    fontSize: '0.7rem', 
                                    color: isOverridden ? 'text.primary' : 'text.secondary',
                                    textDecoration: isOverridden ? 'line-through' : 'none',
                                    display: 'inline-block'
                                  }}
                                >
                                  {column.fieldName === 'active' || column.fieldName === 'featured'
                                    ? (row[column.fieldName] ? 'Yes' : 'No')
                                    : row[column.fieldName]}
                                </Typography>
                                
                                {isOverridden && (
                                  <Typography 
                                    sx={{ 
                                      fontSize: '0.7rem', 
                                      fontWeight: 'bold',
                                      color: 'primary.main',
                                      ml: 1,
                                      display: 'inline-block'
                                    }}
                                  >
                                    {column.linkedMultiAppConfig.type === 'map_boolean'
                                      ? (overriddenValue ? 'Yes' : 'No')
                                      : overriddenValue}
                                  </Typography>
                                )}
                                
                                <Tooltip title="Override Value">
                                  <IconButton 
                                    size="small" 
                                    sx={{ 
                                      p: 0.2,
                                      ml: 0.5,
                                      color: isOverridden ? 'primary.main' : 'action.disabled'
                                    }}
                                    onClick={() => openEditModal(
                                      table.id,
                                      rowId, // Use the correct rowId here
                                      column.fieldName,
                                      row[column.fieldName],
                                      column.linkedMultiAppConfig.multiAppConfigUuid,
                                      column.linkedMultiAppConfig.type
                                    )}
                                  >
                                    <EditIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </TabPanel>
      
      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 0.5, mt: 0.5 }}>
        <Button 
          onClick={resetChanges} 
          variant="outlined" 
          size="small"
          sx={{ 
            py: 0, 
            px: 1, 
            minWidth: 'auto', 
            fontSize: '0.7rem',
            height: '24px',
            lineHeight: 1
          }}
        >
          Reset
        </Button>
        <Button 
          onClick={saveChanges} 
          variant="contained" 
          size="small"
          sx={{ 
            py: 0, 
            px: 1, 
            minWidth: 'auto', 
            fontSize: '0.7rem',
            height: '24px',
            lineHeight: 1
          }}
        >
          Save Changes
        </Button>
      </Box>
      
      {/* Edit Modal */}
      {renderEditModal()}
    </Box>
  );
};

export default FDUserSettings;
