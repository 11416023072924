import React from 'react';
import { styled } from '@mui/material/styles';
import { useColumnSorting } from './hooks/useColumnSorting';
import { useAutoColumnWidth } from './hooks/useAutoColumnWidth';
import { useDoubleClickHandler } from './hooks/useDoubleClickHandler';
import HeaderSortButton from './components/HeaderSortButton';
import HeaderTooltip from './components/HeaderTooltip';

/**
 * Styled component to prevent text selection in header
 */
const NoSelectDiv = styled('div')({
  '&::selection': {
    backgroundColor: 'transparent',
    color: 'inherit'
  },
  '& *::selection': {
    backgroundColor: 'transparent',
    color: 'inherit'
  }
});

/**
 * Header component for table columns with auto-fit and sort capabilities
 * Presentation component that uses custom hooks for business logic
 * @param {Object} params - Column parameters from MUI DataGrid
 * @param {Array} rows - Data rows for the table
 * @param {Function} handleColumnResize - Callback function for column resize
 */
export const AutoFitHeader = ({params, rows, handleColumnResize}) => {
    const { field, colDef } = params;
    const { width, headerName, sortable, disableAutoFit, tooltip } = colDef;
    
    // Custom hook for handling sort logic
    const { 
      showSortControls, 
      setShowSortControls,
      currentSortDirection,
      handleSort
    } = useColumnSorting(field);
    
    // Custom hook for column auto-width functionality
    const { calculateOptimalWidth } = useAutoColumnWidth(rows);
    
    // Custom hook for handling double-click detection
    const { handleClick } = useDoubleClickHandler(() => {
      const optimalWidth = calculateOptimalWidth(field, headerName);
      
      if (handleColumnResize && !disableAutoFit) {
        const resizeParams = {
          colDef: { field },
          width: optimalWidth
        };
        handleColumnResize(resizeParams);
      }
    });
  
    return (
        
            <NoSelectDiv 
                style={{ 
                    width: `${width}px`, 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-between'
                }}
                onMouseEnter={() => setShowSortControls(true)}
                onMouseLeave={() => setShowSortControls(false)}
                onClick={handleClick}
            >
                <HeaderTooltip title={tooltip ?? ""}>
                    <div style={{ 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap',
                        maxWidth: sortable ? `calc(${width}px - 30px)` : `${width}px`,
                        fontWeight: 500
                    }}>
                        {headerName}
                    </div>
                </HeaderTooltip>
                {/* Only render the sort button if column is sortable */}
                {sortable && (
                    <div style={{
                        position: 'absolute',
                        right: '6px',
                        display: 'flex'
                    }}>
                        <HeaderSortButton 
                            field={field}
                            showOnHover={showSortControls}
                            currentSortDirection={currentSortDirection}
                            onSort={handleSort}
                        />
                    </div>
                )}
            </NoSelectDiv>
        
    );
}

export default AutoFitHeader;