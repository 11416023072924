import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import { 
    updateColumnsStateColumnOrder, 
    updateColumnsStateColumnSettings,
    updateSysAdminState,
    sysAdminUpdatePayload,
    sysAdmin_update_types,
    generateNewColumnsState
} from '../../../../../store/dashboardTableVizSlice';
import * as Constants from '../../../../../Constants';
import TableSettingsHeader from './components/TableSettingsHeader';
const DraggableColumn = ({ column, objectName, columnSettings }) => {
    const dispatch = useDispatch();
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: column.field });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    const isVisible = columnSettings[column.field]?.visible ?? true;

    const handleVisibilityToggle = () => {
        dispatch(updateColumnsStateColumnSettings({
            objectName,
            field: column.field,
            option: 'visible',
            value: !isVisible
        }));
    };

    return (
        <Box
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            sx={{
                p: 1,
                mb: 1,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                cursor: 'move',
                '&:hover': {
                    bgcolor: 'action.hover',
                },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Typography>{column.headerName}</Typography>
            <IconButton 
                onClick={handleVisibilityToggle}
                size="small"
                sx={{ ml: 1 }}
                onPointerDown={(e) => e.stopPropagation()}
            >
                {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
        </Box>
    );
};

const SysAdminAlertChip = () => {
    const noSavedColumnsState = useSelector(state => state.dashboardTableViz.sysAdmin.noSavedColumnsState);
    const savedColumnsState = useSelector(state => state.dashboardTableViz.sysAdmin.savedColumnsState);
    const user = useSelector(state => state.dashboardTableViz.sysAdmin.user);
    

    /*
    <Chip
			label=<span><strong>ERROR:</strong> autoPerformConfig found in sendEmailConfig and createEntryConfig</span>
			color="error"
			sx={{
				height: "24px", // Small button default height
				fontSize: "10px",
				marginRight: "14px",
				padding: "0px 12px",
				"& .MuiChip-label": {
					padding: "0",
					whiteSpace: "nowrap"
				}
			}}
		/>
    */
    if (noSavedColumnsState) {
        return (
            <Chip
                label={`No settings for ${user}`}
                color="error"
                sx={{
                    height: "18px",
                    fontSize: "10px",
                    padding: "0px 10px",
                    "& .MuiChip-label": {
                        padding: "0",
                        whiteSpace: "nowrap"
                    }
                }}
            />
        )
    } else if (savedColumnsState) {
        return (
            <Chip
                label={`Settings for ${user}`}
                color="success"
                sx={{
                    height: "18px",
                    fontSize: "10px",
                    padding: "0px 10px",
                    "& .MuiChip-label": {
                        padding: "0",
                        whiteSpace: "nowrap"
                    }
                }}
            />
        )
    } else {
        return null;
    }
}

const SysAdminUserSettingsInput = () => {
    const dispatch = useDispatch();
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    const isSysAdmin = useSelector(state => state.role.isSysAdmin);
    const uuid = useSelector(state => state.dashboard.uuid);
    const user = useSelector(state => state.dashboardTableViz.sysAdmin.user);
    const sysAdminUserSettingsArgs = useSelector(state => state.dashboardTableViz.sysAdmin.args);
    const dashboardJson = useSelector(state => state.dashboard.dashboardJson);

    const getSysAdminUserSettings = () => {
        const tempArgs = {
            url: Constants.SERVER_SYSADMIN_POST_GET_DASHBOARD_LAYOUT_URL + companyUuid,
            method: "POST",
            body: JSON.stringify({
                developerAppUuid: uuid,
                layoutUser: user
            })
        }
        dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.args, tempArgs)));
    }

    const handleCancel = () => {
        const initialColumnsState = generateNewColumnsState(dashboardJson);
        dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.reset, initialColumnsState)));
    }

    return isSysAdmin && companyUuid ? (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                pb: 1,
                gap: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <TextField
                    sx={{ flexGrow: 1 }}
                    size="small"
                    label="User"
                    value={user}
                    onChange={(e) => {
                        dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.user, e.target.value)));
                    }}
                    disabled={!!sysAdminUserSettingsArgs}
                />
                {!sysAdminUserSettingsArgs &&
                    <IconButton
                        onClick={getSysAdminUserSettings}
                        sx={{ padding: "4px" }}
                        disabled={!user}
                    >
                        <CheckIcon />
                    </IconButton>
                }
                {sysAdminUserSettingsArgs &&
                    <IconButton
                        onClick={handleCancel}
                        sx={{ padding: "4px" }}
                    >
                        <CancelIcon />
                    </IconButton>
                }
            </Box>
            <SysAdminAlertChip />
        </Box>
    ) : null;
}

const TableSettings = ({ columns, objectName, columnsState }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const reorderableColumns = useMemo(() => 
        columns.filter(col => 
            !col.hidden && 
            !(columnsState?.pinnedColumns?.left?.includes(col.field)) &&
            !(columnsState?.pinnedColumns?.right?.includes(col.field))
        ),
        [columns, columnsState]
    );

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        
        if (active.id !== over.id) {
            const oldIndex = reorderableColumns.findIndex(item => item.field === active.id);
            const newIndex = reorderableColumns.findIndex(item => item.field === over.id);
            
            const newOrder = arrayMove(reorderableColumns, oldIndex, newIndex);
            
            dispatch(updateColumnsStateColumnOrder({
                objectName,
                value: newOrder.map(col => col.field)
            }));
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return Constants.USE_COLUMNS_STATE ? (
        <>
            <IconButton onClick={handleClick} sx={{ padding: "4px"}}>
                <SettingsIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    vertical: 'top',
                }}
            >
                <Paper sx={{ p: 2, width: 300 }}>
                    <SysAdminUserSettingsInput />
                    <TableSettingsHeader />
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[restrictToParentElement]}
                    >
                        <SortableContext
                            items={reorderableColumns.map(col => col.field)}
                            strategy={verticalListSortingStrategy}
                        >
                            {reorderableColumns.map((column) => (
                                <DraggableColumn
                                    key={column.field}
                                    column={column}
                                    objectName={objectName}
                                    columnSettings={columnsState?.columnSettings || {}}
                                />
                            ))}
                        </SortableContext>
                    </DndContext>
                </Paper>
            </Popover>
        </>
    ) : null;
};

export default TableSettings; 