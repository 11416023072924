import { createSlice } from "@reduxjs/toolkit";
import {
	createEntryConfig_fields,
	sendEmailConfig_fields
} from "./dashboardConfigsSlice";

export const column_types = {
	delta: "delta",
	icon: "icon",
	//bar: "bar",// killing column_type: bar/useState extremes - Vik approved
	flagged: "flagged",
	actions: "actions",
	quick_filter_button: "quick_filter_button",
	status_tracker: "status_tracker",
	notes: "notes",
	date: "date",
	number: "number",
}

export const pin_right_columns = [column_types.actions, column_types.status_tracker, column_types.notes, column_types.quick_filter_button];

const defaultColumnsState = () => {
	return {
		columnOrder: [],
		pinnedColumns: { left: [], right: [] },
		columnSettings: {}
	}
};

export const columnsState_fields = {
	columnOrder: "columnOrder",
	pinnedColumns: "pinnedColumns",
	columnSettings: "columnSettings"
}

export const pinnedColumns_fields = {
	left: "left",
	right: "right"
}

export const columnSettings_fields = {
	visible: "visible",
	width: "width"
}

export const generateNewColumnsState = (dashboardJson) => {
	const parsedJson = typeof dashboardJson === 'string' ? JSON.parse(dashboardJson) : dashboardJson;
    const columnsState = {};

    // Iterate through components to find tables
    Object.entries(parsedJson.components).forEach(([objectName, component]) => {
        if (component.type === 'table') {
            // Initialize table state structure
            columnsState[objectName] = defaultColumnsState()
            
            // Process each column
            component.columns.forEach((column) => {
                // Skip hidden columns
                if (column.hidden) {
                    return;
                }

                // Add to appropriate order array and create settings
                if (column.pinned) {
					let column_type = column.type ? column.type : "string";
					if (column.pinned && column.pinned === true && !pin_right_columns.includes(column_type)) {
						columnsState[objectName][columnsState_fields.pinnedColumns][pinnedColumns_fields.left].push(column.field);
					}
					if (column.pinned && column.pinned === true && pin_right_columns.includes(column_type)) {
						columnsState[objectName][columnsState_fields.pinnedColumns][pinnedColumns_fields.right].push(column.field);
					}
                    //columnsState[objectName].pinnedRightColumns.push(column.field);
                } else {
                    columnsState[objectName].columnOrder.push(column.field);
                }

                // Create column settings
                columnsState[objectName].columnSettings[column.field] = {
                    width: column.width ?? 150,
                    visible: true
                };
            });
        }
    });

    return columnsState;
}

export const generateSavedColumnsState = (dashboardJson, savedColumnsState) => {
    const parsedJson = typeof dashboardJson === 'string' ? JSON.parse(dashboardJson) : dashboardJson;
    const newColumnsState = {};

    // Iterate through components to find tables
    Object.entries(parsedJson.components).forEach(([objectName, component]) => {
        if (component.type === 'table') {
            // Initialize state structure for this table
            newColumnsState[objectName] = defaultColumnsState()

            const savedTableState = savedColumnsState[objectName] || {};
            const existingColumns = new Set(); // Track which columns still exist
            
            // First pass: handle pinned columns and build settings
            component.columns.forEach((column) => {
                if (column.hidden) {
                    // Ensure hidden columns are removed from settings
                    delete savedTableState[columnsState_fields.columnSettings]?.[column.field];
                    return;
                }

                existingColumns.add(column.field);

                // Preserve or create column settings
				const savedWidth = savedTableState[columnsState_fields.columnSettings]?.[column.field]?.[columnSettings_fields.width] ?? column.width ?? 150
				const savedVisible = savedTableState[columnsState_fields.columnSettings]?.[column.field]?.[columnSettings_fields.visible] ?? true
                newColumnsState[objectName][columnsState_fields.columnSettings][column.field] = {
                    width: savedWidth,
                    visible: savedVisible
                };

                // Handle pinned columns
                if (column.pinned) {
					let column_type = column.type ? column.type : "string";
					if (column.pinned && column.pinned === true && !pin_right_columns.includes(column_type)) {
						newColumnsState[objectName][columnsState_fields.pinnedColumns][pinnedColumns_fields.left].push(column.field);
					}
					if (column.pinned && column.pinned === true && pin_right_columns.includes(column_type)) {
						newColumnsState[objectName][columnsState_fields.pinnedColumns][pinnedColumns_fields.right].push(column.field);
					}
                    //newColumnsState[objectName].pinnedRightColumns.push(column.field);
                }
            });

            // Second pass: handle column ordering
            // Start with existing saved order, filtering out defunct or hidden columns
            const savedOrder = savedTableState[columnsState_fields.columnOrder] ?? [];
            const validSavedColumns = savedOrder.filter(field => 
                existingColumns.has(field) && 
                !component.columns.find(col => col.field === field && col.pinned)
            );

            // Find new unpinned columns that weren't in the saved state
            const newColumns = component.columns
                .filter(column => 
                    !column.hidden &&
                    !column.pinned &&
                    !savedOrder.includes(column.field)
                )
                .map(column => column.field);

            // Combine valid saved columns with new columns
            newColumnsState[objectName].columnOrder = [
                ...validSavedColumns,
                ...newColumns
            ];
        }
    });

    return newColumnsState;
};

const initialState = {
	selectionModel: {
		// key === objectName - 
	},
	renderedRows: {
		// key === objectName -
	},
	// collected from server:
	columnsState: {
		// key === objectName - 
	},
	columnsStateReady: false,
	columnsStateModified: false,
	resetColumnsStateArgs: null,
	sysAdmin: {
		user: "",
		args: null,
		noSavedColumnsState: false,
		savedColumnsState: false,
	}
}

export const sysAdmin_fields = {
	user: "user",
	args: "args",
	noSavedColumnsState: "noSavedColumnsState",
	savedColumnsState: "savedColumnsState",
}

export const sysAdmin_update_types = {
	user: "user",
	columnsState: "columnsState",
	args: "args",
	noSavedColumnsState: "noSavedColumnsState",
	reset: "reset",
	resetLayoutUserSavedColumnsState: "resetLayoutUserSavedColumnsState",
}

export const sysAdminUpdatePayload = (type, payload) => {
	return {
		type,
		payload
	}
}

export const dashboardTableVizSlice = createSlice({
	name: "dashboardTableViz",
	initialState: initialState,
	reducers: {
		setUpSelectionModel: (state, action) => {
			console.log("dashboardTableVizSlice - setUpRowSelection: ", action.payload);
			const { dashboardJson } = action.payload;
			const newSelectionModel = {};
			Object.entries(dashboardJson).forEach(([objectName, component]) => {
				if (component.createEntryConfig || component.sendEmailConfig) {
					const autoPerformEntries = component.createEntryConfig?.[createEntryConfig_fields.autoPerformConfig];
					const autoPerformEmails = component.sendEmailConfig?.[sendEmailConfig_fields.autoPerformConfig];
					const statusTrackerConfig = component.statusTrackerConfig;
					
					if ((autoPerformEntries || autoPerformEmails) && statusTrackerConfig) {
						newSelectionModel[objectName] = [];
					}
				}
			})
			
			state.selectionModel = newSelectionModel;
		},
		updateSelectionModel: (state, action) => {
			console.log("dashboardTableVizSlice - updateRowSelection: ", action.payload);
			const { objectName, selectionModel } = action.payload;
			state.selectionModel[objectName] = selectionModel;
		},
		setRenderedRows: (state, action) => {
			console.log("dashboardTableVizSlice - setRenderedRows: ", action.payload)
			const { objectName, rows } = action.payload;
			state.renderedRows[objectName] = rows;
		},
		// columnsState reducers
		setInitialColumnsState: (state, action) => {
			console.log("dashboardTableVizSlice - setInitialColumnsState: ", action.payload)
			state.columnsState = action.payload;
			state.columnsStateReady = true;
		},
		updateColumnsStateColumnSettings: (state, action) => {
			console.log("dashboardTableVizSlice - updateColumnsState: ", action.payload)
			const { objectName, field, option, value } = action.payload;
			state.columnsState[objectName]["columnSettings"][field][option] = value;
			state.columnsStateModified = true;
		},
		updateColumnsStateColumnOrder: (state, action) => {
			console.log("dashboardTableVizSlice - updateColumnsStateColumnOrder: ", action.payload)
			const { objectName, value } = action.payload
			state.columnsState[objectName]["columnOrder"] = value;
			state.columnsStateModified = true;
		},
		setResetColumnsStateArgs: (state, action) => {
			console.log("dashboardTableVizSlice - setResetColumnsStateArgs: ", action.payload)
			state.resetColumnsStateArgs = action.payload;
		},
		resetColumnsState: (state, action) => {
			console.log("dashboardTableVizSlice - resetColumnsState: ", action.payload)
			state.columnsState = action.payload;
			state.resetColumnsStateArgs = null;
			state.columnsStateModified = false;
		},
		// end columnsState reducers
		// start supporting sysadmin user columns state
		updateSysAdminState: (state, action) => {
			console.log("dashboardTableVizSlice - updateSysAdminState: ", action.payload)
			const { type, payload } = action.payload;
			if (type === sysAdmin_update_types.user) {
				state.sysAdmin[sysAdmin_fields.user] = payload;
			} else if (type === sysAdmin_update_types.args) {
				state.sysAdmin[sysAdmin_fields.args] = payload;
			} else if (type === sysAdmin_update_types.noSavedColumnsState) {
				state.sysAdmin[sysAdmin_fields.noSavedColumnsState] = payload;
			} else if (type === sysAdmin_update_types.columnsState) {
				state.columnsState = payload;
				state.sysAdmin[sysAdmin_fields.savedColumnsState] = true;
			} else if (type === sysAdmin_update_types.reset) {
				state.sysAdmin = initialState.sysAdmin;
				state.columnsState = payload;
			} else if (type === sysAdmin_update_types.resetLayoutUserSavedColumnsState) {
				state.sysAdmin[sysAdmin_fields.savedColumnsState] = false;
				state.sysAdmin[sysAdmin_fields.noSavedColumnsState] = true;
				state.columnsState = payload;
			}
		},
		// end supporting sysadmin user columns state
		resetDashboardTableViz: (state, action) => {
			console.log("dashboardTableVizSlice - resetTableViz");
			return initialState
		},
	}
});

export const { 
	setUpSelectionModel, 
	updateSelectionModel,
	setRenderedRows,
	setInitialColumnsState,
	updateColumnsStateColumnSettings,
	updateColumnsStateColumnOrder,
	setResetColumnsStateArgs,
	resetColumnsState,
	updateSysAdminState,
	resetDashboardTableViz, 
} = dashboardTableVizSlice.actions;

export default dashboardTableVizSlice.reducer;