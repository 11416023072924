import { useState, useEffect } from "react";
import { config_types, config_fields, generateConfig, validateField } from "./useMultiAppConfigsForm";
import * as Constants from "../../../../Constants";
//POST /api/multi_app_config/create/
/*
args:
configName String

displayName String

defaultVal Object

multiAppConfigType String (From MultiAppConfigTypeEnum: TEXT, DROPDOWN, etc.) 

configSource String (From Sources Enum: quickbooks, netsuite_analytics, etc.)

sysAdminOnly Boolean

dropdownValueList List<String> (Optional)
*/
export const create_config_fields = {
	[config_fields.configName]: config_fields.configName,
	[config_fields.displayName]: config_fields.displayName,
	[config_fields.defaultVal]: config_fields.defaultVal,
	[config_fields.multiAppConfigType]: config_fields.multiAppConfigType,
	[config_fields.configSource]: config_fields.configSource,
	[config_fields.sysAdminOnly]: config_fields.sysAdminOnly,
	[config_fields.dropdownValueList]: config_fields.dropdownValueList,
}

const generateCreateArgsBody = (config) => {
	const tempBody = {
		[create_config_fields.configName]: config[create_config_fields.configName],
		[create_config_fields.displayName]: config[create_config_fields.displayName],
		[create_config_fields.defaultVal]: config[config_fields.val],
		[create_config_fields.multiAppConfigType]: config[create_config_fields.multiAppConfigType],
		[create_config_fields.configSource]: config[create_config_fields.configSource],
		[create_config_fields.sysAdminOnly]: config[create_config_fields.sysAdminOnly],
	}
	if (config[create_config_fields.multiAppConfigType] === config_types.DROPDOWN) {
		tempBody[create_config_fields.dropdownValueList] = config[create_config_fields.dropdownValueList]
	}
	return tempBody;
}

const newMultiAppConfig = {
	[config_fields.uuid]: "",
	[config_fields.configName]: "",
	[config_fields.displayName]: "",
	[config_fields.multiAppConfigType]: config_types.TEXT,
	[config_fields.defaultValStr]: "",
	[config_fields.dropdownValueList]: null,
	[config_fields.configSource]: "",
	[config_fields.sysAdminOnly]: true,
}

const generateNewConfig = () => {
	const newConfig = generateConfig(newMultiAppConfig);
	newConfig[create_config_fields.configName] = "";
	return newConfig;
}

const validateForm = (config) => {
	/*
	args:
	configName String
	
	displayName String
	
	defaultVal Object
	
	multiAppConfigType String (From MultiAppConfigTypeEnum: TEXT, DROPDOWN, etc.) 
	
	configSource String (From Sources Enum: quickbooks, netsuite_analytics, etc.)
	
	sysAdminOnly Boolean
	
	dropdownValueList List<String> (Optional)
	
	!validateField(config[config_fields.val], config[config_fields.multiAppConfigType])
	*/
	const isValidValue = validateField(config[config_fields.val], config[config_fields.multiAppConfigType])
	const noEmptyFields = config[create_config_fields.configName] && config[create_config_fields.displayName] && config[create_config_fields.configSource]
	return isValidValue && noEmptyFields
}

export const useCreateMultiAppConfig = (refreshMultiAppConfigs) => {
	const [createConfigArgs, setCreateConfigArgs] = useState(null);
	const [open, setOpen] = useState(false);
	const [config, setConfig] = useState(generateNewConfig());
	
	const toggleOpen = () => {
		console.log("toggleOpen")
		setOpen(prevOpen => !prevOpen);
		setConfig(generateNewConfig());
	}
	
	const handleChange = (newValue, uuid, config_field, additionalValues) => {
		// update config
		setConfig(prevConfig => {
			if (additionalValues) {
				return {
					...prevConfig,
					[config_field]: newValue,
					...additionalValues
				}
			}
			return {
				...prevConfig,
				[config_field]: newValue
			}
		})
	}
	
	const isValidCreate = validateForm(config)
	
	const createConfig = () => {
		console.log("createConfig - config", config);
		const tempArgs = {
			url: Constants.SERVER_SYSADMIN_CREATE_MULTI_APP_CONFIG_URL,
			method: "POST",
			body: JSON.stringify(generateCreateArgsBody(config))
		}
		setCreateConfigArgs(tempArgs)
	}
	
	const handleSuccess = () => {
		console.log("useCreateMultiAppConfig - handleSuccess")
		setCreateConfigArgs(null)
		toggleOpen()
		refreshMultiAppConfigs()
	}
	
	return {
		open,
		toggleOpen,
		config,
		handleChange,
		isValidCreate,
		createConfigArgs,
		createConfig,
		handleSuccess
	}
}