// will have refesh and cancel, along with individual card buttons
import { useState } from "react";
import { Grid, Button, Popper, Paper, Stack, ClickAwayListener } from "@mui/material";
import { databricksJobs_fields_queued } from "../hooks/useDatabricksJobs";
import useCancelDatabricksJobs from "../hooks/useCancelDatabricksJobs";
import useModifyDatabricksJobs from "../hooks/useModifyDatabricksJobs";
import DirectRequest from "../../../../API/requests/DirectRequest";
import React from "react";

const CancelJobsButton = ({handleDatabricksJobsRefresh}) => {
    const {
        cancelDatabricksJobsArgs,
        handleCancelJobs,
        handleCancelJobsSuccess,
        isConfirmationActive
    } = useCancelDatabricksJobs(handleDatabricksJobsRefresh);

    return (
        <>
            <DirectRequest
                requestArgs={cancelDatabricksJobsArgs}
                afterProcess={handleCancelJobsSuccess}
                handleError={err => console.warn("server error cancelling jobs", err)}
                handleCatchError={err => console.warn("client error cancelling jobs", err)}
            />
            <Button 
                onClick={handleCancelJobs} 
                variant={isConfirmationActive ? "contained" : "outlined"} 
                color="error"
                size="small"
                sx={{
                    marginLeft: "10px"
                }}
            >
                {isConfirmationActive ? "Confirm Cancel Jobs" : "Cancel Jobs"}
            </Button>
        </>
    )
}

export const JobsButtons = ({handleDatabricksJobsRefresh}) => {
    return (
        <Grid item xs={12}>
            <Button onClick={handleDatabricksJobsRefresh} variant="contained" color="primary" size="small">Refresh Jobs</Button>
            <CancelJobsButton handleDatabricksJobsRefresh={handleDatabricksJobsRefresh} />
        </Grid>
    )
}

const FastTrackModuleJobsButton = ({inProgress, handleReorderModuleJobs, config}) => {
    const {moduleUuid, moduleName, companyUuid, companyName} = config;
    const [anchorEl, setAnchorEl] = useState(null);
    
    const disabled = inProgress || !moduleUuid
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleModuleOnly = () => {
        handleReorderModuleJobs(moduleUuid);
        handleClose();
    };

    const handleModuleWithCompany = () => {
        handleReorderModuleJobs(moduleUuid, companyUuid);
        handleClose();
    };

    return (
        <>
            <Button 
                variant={open ? "contained" : "outlined"} 
                color="primary" 
                size="small" 
                disabled={disabled} 
                onClick={handleClick}
            >
                Fast Track Module
            </Button>
            <Popper open={open} anchorEl={anchorEl} placement="top">
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper sx={{ p: 1 }}>
                        <Stack spacing={1}>
                            <Button 
                                variant="outlined" 
                                size="small" 
                                onClick={handleModuleWithCompany}
                                sx={{ textTransform: "none" }}
                            >
                                Fast track {moduleName} for {companyName}
                            </Button>
                            <Button 
                                variant="outlined" 
                                size="small" 
                                onClick={handleModuleOnly}
                                sx={{ textTransform: "none" }}
                            >
                                Fast track {moduleName} for all companies
                            </Button>
                        </Stack>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

const FastTrackJobButton = ({job, inProgress, handleReorderJobs}) => {
    const disabled = inProgress
    const jobUuid = inProgress ? null : job[databricksJobs_fields_queued.jobUuid]// same as databricksJobs_fields_inProgress.jobUuid
    return (
        <Button variant="contained" color="primary" size="small" disabled={disabled} sx={{marginLeft: "10px"}} onClick={() => handleReorderJobs(jobUuid)}>Move to front</Button>
    )
}

export const JobCardButtons = ({job, handleDatabricksJobsRefresh, inProgress, config}) => {
    const {
        modifyDatabricksJobsArgs,
        handleReorderJobs,
        handleReorderModuleJobs,
        handleSuccess
    } = useModifyDatabricksJobs(handleDatabricksJobsRefresh);
    return (
        <>
            <DirectRequest
                requestArgs={modifyDatabricksJobsArgs}
                afterProcess={handleSuccess}
                handleError={err => console.warn("server error reordering jobs", err)}
                handleCatchError={err => console.warn("client error reordering jobs", err)}
            />
            <Grid item xs={3.5} sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end"}}>
                <FastTrackModuleJobsButton inProgress={inProgress} handleReorderModuleJobs={handleReorderModuleJobs} config={config} />
                <FastTrackJobButton job={job} inProgress={inProgress} handleReorderJobs={handleReorderJobs} />
            </Grid>
        </>
    )
}