
import { Button } from "@mui/material";
import { useState } from "react";
import { useResetColumnsState } from "../hooks/useResetColumnsState";

export const ResetColumnsStateButton = () => {
    const { handleReset } = useResetColumnsState();
    const [hovering, setHovering] = useState(false);

    return (
        <Button 
            onClick={handleReset}
            variant={hovering ? "contained" : "outlined"}
            size="small"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            sx={{
                padding: "4px",
                fontSize: "12px",
                borderRadius: "4px",
                maxHeight: "24px",
            }}
        >
            Reset
        </Button>
    )
}

export default ResetColumnsStateButton;