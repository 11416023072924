import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { 
    generateNewColumnsState, 
    generateSavedColumnsState, 
    setInitialColumnsState, 
    updateSysAdminState, 
    sysAdminUpdatePayload,
    sysAdmin_update_types,
    resetColumnsState
} from "../../../store/dashboardTableVizSlice"
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";
import iso8601Timestamp from "../../../helpers/iso8601Timestamp";
import { resetColumnsStateNotificationArgs } from "./FDRecipeTableComponents/TableSettings/utils/notifications";
import { setAlertNotificationArgs } from "../../../store/alertsNotificationSlice";
/*
if companyUuid - sysadmin viewing - generate newColumnsState
if isReport - viewing an old report dashboard - generateNewColumnsState (tables may have changed dramatically since report)
if !isReport - get columnsState - if present, generateSavedColumnsState, otherwise generateNewColumnsState
*/
const GetInitialColumnsState = () => {
    const dispatch = useDispatch();
    const uuid = useSelector(state => state.dashboard.uuid)
    const isReport = useSelector(state => state.dashboard.isReport)
    const user = useSelector(state => state.role.name)
    const companyUuid = useSelector(state => state.dashboard.companyUuid)
    const dashboardJson = useSelector(state => state.dashboard.dashboardJson)

    const [columnsStateArgs, setColumnsStateArgs] = useState(null)

    useEffect(() => {
        if (dashboardJson) {
            console.log("GetInitialColumnsState - useEffect -- dashboardJson found")
            if (companyUuid) {
                console.log("GetInitialColumnsState - useEffect -- companyUuid found - generating new columnsState")
                // generateNewColumnsState
                const initialColumnsState = generateNewColumnsState(dashboardJson)
                dispatch(setInitialColumnsState(initialColumnsState))
            } else if (isReport) {
                console.log("GetInitialColumnsState - useEffect -- isReport - generating new columnsState")
                const initialColumnsState = generateNewColumnsState(dashboardJson)
                dispatch(setInitialColumnsState(initialColumnsState))
            } else {
                console.log("GetInitialColumnsState - useEffect -- !isReport - getting saved columnsState")
                const tempArgs = {
                    url: Constants.SERVER_POST_GET_USER_DASHBOARD_LAYOUT_URL,
                    method: "POST",
                    body: JSON.stringify({
                        developerAppUuid: uuid
                    })
                }
                setColumnsStateArgs(tempArgs)
            }
        }
    }, [uuid, isReport, user, companyUuid, dashboardJson])

    const handleColumnsState = (res) => {
        console.log("GetInitialColumnsState - handleColumnsState -- res", res)
        // res.dashboardLayout.tableSettings is the object we want
        const columnsState = res?.dashboardLayout?.tableSettings
        if (columnsState) { 
            // there are settings
            const initialColumnsState = generateSavedColumnsState(dashboardJson, columnsState)
            dispatch(setInitialColumnsState(initialColumnsState))
        } else {
            const initialColumnsState = generateNewColumnsState(dashboardJson)
            dispatch(setInitialColumnsState(initialColumnsState))
        }
    }

    return (
        <DirectRequest
            requestArgs={columnsStateArgs}
            afterProcess={handleColumnsState}
            handleError={err => {
                console.warn("server error getting columnsState", err)
                handleColumnsState({})
            }}
            handleCatchError={err => {
                console.warn("client error getting columnsState", err)
                handleColumnsState({})
            }}
        />
    )
}


const SaveColumnsState = () => {
    const uuid = useSelector(state => state.dashboard.uuid)
    const isReport = useSelector(state => state.dashboard.isReport)
    const user = useSelector(state => state.role.name)
    const companyUuid = useSelector(state => state.dashboard.companyUuid)
    const columnsState = useSelector(state => state.dashboardTableViz.columnsState)
    const columnsStateModified = useSelector(state => state.dashboardTableViz.columnsStateModified)

    const [saveColumnsStateArgs, setSaveColumnsStateArgs] = useState(null)

    useEffect(() => {
        // Don't save if it's a report or company view
        if (isReport || companyUuid || !columnsStateModified) return;
        
        const timeoutId = setTimeout(() => {
            const args = {
                url: Constants.SERVER_POST_SAVE_USER_DASHBOARD_LAYOUT_URL,
                method: "POST",
                body: JSON.stringify({
                    developerAppUuid: uuid,
                    columnsState: columnsState
                })
            };
            setSaveColumnsStateArgs(args);
        }, 1500);

        return () => {
            clearTimeout(timeoutId);
            setSaveColumnsStateArgs(null);
        };
    }, [columnsState, uuid, user, isReport, companyUuid, columnsStateModified]);

    const handleColumnsStateSave = () => {
        console.log("handleColumnsStateSave");
        setSaveColumnsStateArgs(null);
    }

    const handleError = (err) => {
        console.warn("error saving columnsState", err);
        setSaveColumnsStateArgs(null);
    }

    return saveColumnsStateArgs ? (
        <DirectRequest
            requestArgs={saveColumnsStateArgs}
            afterProcess={handleColumnsStateSave}
            handleError={handleError}
            handleCatchError={handleError}
        />
    ) : null;
}


const ColumnsStateChangeNotification = () => {
    const columnsStateModified = useSelector(state => state.dashboardTableViz.columnsStateModified)
    const companyUuid = useSelector(state => state.dashboard.companyUuid)
    const user = useSelector(state => state.role.name)
    const isInternal = useSelector(state => state.role.isInternal)
    const dashboardJson = useSelector(state => state.dashboard.dashboardJson)
    const [notifyOfColumnsStateChangeArgs, setNotifyOfColumnsStateChangeArgs] = useState(null)

    useEffect(() => {
        if (columnsStateModified && columnsStateModified === true && !companyUuid && !isInternal) {
            console.log("NotifyOfColumnsStateChange - useEffect -- columnsStateModified:", columnsStateModified)
            const args = {
                url: Constants.SERVER_SEND_EMAIL,
                method: "POST",
                body: JSON.stringify({
                    "body": `${user} has modified the table settings for ${dashboardJson?.title}`,
                    "subject": `${user} has modified the table settings for ${dashboardJson?.title} (${window.location.origin} - ${iso8601Timestamp()})`,
                    "from": "noreply@wiselayer.com",
                    "toEmail": Constants.notificationList.join(","),
                    "sendHTML": true
                })
            }
            setNotifyOfColumnsStateChangeArgs(args)
        }
    }, [columnsStateModified, companyUuid, user, isInternal, dashboardJson])

    const handleNotifyOfColumnsStateChange = () => {
        // console.log("NotifyOfColumnsStateChange - handleNotifyOfColumnsStateChange")
        setNotifyOfColumnsStateChangeArgs(null)
    }
    
    return notifyOfColumnsStateChangeArgs ? (
        <DirectRequest
            requestArgs={notifyOfColumnsStateChangeArgs}
            afterProcess={handleNotifyOfColumnsStateChange}
            handleError={(err) => console.log("server error sending columnsState change email", err)}
            handleCatchError={(err) => console.log("client error sending columnsState change email", err)}
        />
    ) : null;
}

export const SysAdminUserSettingsRequest = () => {
    const dispatch = useDispatch();
    const dashboardJson = useSelector(state => state.dashboard.dashboardJson);
    const sysAdminRequestArgs = useSelector(state => state.dashboardTableViz.sysAdmin.args);

    const handleSysAdminUserSettings = (res) => {
        const columnsState = res?.dashboardLayout?.tableSettings;
        if (columnsState) {
            const newColumnsState = generateSavedColumnsState(dashboardJson, columnsState);
            dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.columnsState, newColumnsState)));
        } else {
            dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.noSavedColumnsState, true)));
        }
    }

    const handleError = (err) => {
        console.error("Error getting sys admin user settings", err);
    }

    return sysAdminRequestArgs ? (
        <DirectRequest
            requestArgs={sysAdminRequestArgs}
            afterProcess={handleSysAdminUserSettings}
            handleError={handleError}
            handleCatchError={handleError}
        />
    ) : null;
}

export const ResetColumnsState = () => {
    const dispatch = useDispatch();
    const resetColumnsStateArgs = useSelector(state => state.dashboardTableViz.resetColumnsStateArgs);
    const dashboardJson = useSelector(state => state.dashboard.dashboardJson);
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    const user = useSelector(state => state.role.name);
    const isInternal = useSelector(state => state.role.isInternal);

    const onReset = () => {
        if (companyUuid) {
            // sysadmin reset - only gets here if sysadmin.user and sysadmin.savedColumnsState are true (see useResetColumnsState.js)
            const newColumnsState = generateNewColumnsState(dashboardJson);
            dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.resetLayoutUserSavedColumnsState, newColumnsState)));
        } else {
            // user reset
            if (!isInternal) {
                // setAlertNotification
                dispatch(setAlertNotificationArgs(resetColumnsStateNotificationArgs(user, dashboardJson)));
            }
            dispatch(resetColumnsState(generateNewColumnsState(dashboardJson)));
        }
    }

    const handleError = (err) => {
        console.error("Error resetting columns state", err);
    }

    return resetColumnsStateArgs ? (
        <DirectRequest
            requestArgs={resetColumnsStateArgs}
            afterProcess={onReset}
            handleError={handleError}
            handleCatchError={handleError}
        />
    ) : null;
}

export const DashboardTableVizManager = () => {
    const columnsStateReady = useSelector(state => state.dashboardTableViz.columnsStateReady)
    return Constants.USE_COLUMNS_STATE ? (
        <>
            {!columnsStateReady && <GetInitialColumnsState/>}
            {columnsStateReady && <SaveColumnsState/>}
            {columnsStateReady && <ColumnsStateChangeNotification/>}
            {columnsStateReady && <SysAdminUserSettingsRequest/>}
            {columnsStateReady && <ResetColumnsState/>}
        </>
    ) : null;
}

export default DashboardTableVizManager;