import { createSlice } from "@reduxjs/toolkit";
import { module_fields } from "./modulesSlice";
import { getDataArgs } from "./dashboardSlice";

export const stages = {
    IDLE: "IDLE",
    INITIALIZE: "INITIALIZE",
    FETCH: "FETCH",
    PROCESS: "PROCESS",
    DOWNLOAD: "DOWNLOAD",
}
/*
stages:
    IDLE: initial state
    INITIALIZE: get app subscriptions
    FETCH: get 1 dashboard from dashboardUuidsToProcess, get its displayConfigs
    PROCESS: process dashboard and its displayConfigs to add its entriesToExclude to tempDownloadArgs 
             -> if last dashboard, set stage to DOWNLOAD on completion, otherwise set stage to FETCH for next dashboard
    DOWNLOAD: download csv
*/

const initialState = {
    stage: stages.IDLE,
    downloadArgs: null,
    tempDownloadArgs: {
        entriesToExclude: [],
        destination: "",
        entryType: "",
        entryPeriod: "",
        developerAppUuid: "",// doesn't really matter, can be first or last or any inbetween because we're passing moduleUuid
        moduleUuid: "",
    },
    module: null,
    appsSubscriptions: [],// either copy from user's appsSubscriptions or get from companyUuid
    dashboardUuidsToProcess: [],
    getDataArgs: null,
    dashboardData: null,
    displayConfigsArgs: [],
    displayConfigs: [],
    // we need a modal state to hold a reference of which dashboards porduced entriesToExclude
    hiddenEntriesToExcludeModal: {
        show: false,
        content: {}// will be map of dashboardUuid to hiddenEntriesToExclude
    }
};

export const dashboardDownloadJeCsvSlice = createSlice({
    name: "dashboardDownloadJeCsv",
    initialState: initialState,
    reducers: {
        initializeProcess: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> initializeProcess -> action.payload`, action.payload);
            const { module } = action.payload;
            state.module = module;
            state.tempDownloadArgs.moduleUuid = module[module_fields.uuid];
            state.tempDownloadArgs.developerAppUuid = module[module_fields.displayApps][0];// can be any of the app uuids since we're including moduleUuid in the args
            state.dashboardUuidsToProcess = module[module_fields.displayApps];
            state.stage = stages.INITIALIZE;
        },
        setAppsSubscriptions: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> setAppsSubscriptions -> action.payload (appsSubscriptions)`, action.payload)
            state.appsSubscriptions = action.payload;
            state.stage = stages.FETCH;
        },
        setGetDataArgs: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> setGetDataArgs -> action.payload (getDataArgs)`, action.payload)
            state.getDataArgs = action.payload;
        },
        setDashboardData: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> setDashboardData -> action.payload (dashboardData)`, action.payload)
            state.getDataArgs = null;
            state.dashboardData = action.payload;
        },
        setDisplayConfigsArgs: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> setDisplayConfigsArgs -> action.payload (displayConfigsArgs)`, action.payload)
            // this is where we will check for .length > 0
            // if > 0, we need to get displayConifgs and process them against rows
            const displayConfigsArgs = action.payload;
            if (displayConfigsArgs.length > 0) {
                console.log(`displayConfigsArgs.length > 0`)
                console.log(`setting displayConfigsArgs`)
                state.displayConfigsArgs = displayConfigsArgs;
                // subscribed component will fetch the displayConfigs
            } else if (state.dashboardUuidsToProcess.length === 1) {
                console.log(`displayConfigsArgs.length === 0 and dashboardUuidsToProcess.length === 1`)
                // last dashboard and no displayConfigs -> show modal if there are hidden entries to exclude, otherwise set stage to DOWNLOAD
                if (Object.values(state.hiddenEntriesToExcludeModal.content).flat().length > 0) {
                    console.log(`hidden entries to exclude present -> showing modal`)
                    state.hiddenEntriesToExcludeModal.show = true;
                } else {
                    console.log(`no hidden entries to exclude -> setting stage to DOWNLOAD`)
                    state.stage = stages.DOWNLOAD;
                }
            } else {
                console.log(`displayConfigsArgs.length === 0 and dashboardUuidsToProcess.length > 1`)
                console.log(`wiping dashboardData, incrementing dashboardUuidsToProcess`)
                // need to fetch the next dashboard 
                // -> wipe dashboardData
                state.dashboardData = null;
                // and slice dashboardUuidsToProcess to trigger next getDataArgs
                state.dashboardUuidsToProcess.shift();
            }
        },
        setDisplayConfig: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> setDisplayConfig -> action.payload (displayConfig)`, action.payload)
            state.displayConfigs = state.displayConfigs.concat(action.payload);
            // also slice displayConfigsArgs
            state.displayConfigsArgs.shift();
        },
        processDashboard: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> processDashboard -> action.payload (final displayConfig)`, action.payload)
            // action.pyalod is the final displayConfig
            state.displayConfigs = state.displayConfigs.concat(action.payload);
            state.displayConfigsArgs = [];
            state.stage = stages.PROCESS
        },
        // when we're done with 1 dashboard, we will increment dashboardUuidsToProcess and set stage to FETCH again
        setProcessedDashboard: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> setProcessedDashboard -> action.payload (entriesToExclude, destination, entryType, entryPeriod, hiddenEntriesToExclude)`, action.payload)
            const {
                entriesToExclude,
                destination,
                entryType,
                entryPeriod,
                hiddenEntriesToExclude
            } = action.payload;
            
            if (hiddenEntriesToExclude.length > 0) {
                const currentDashboardUuid = state.dashboardUuidsToProcess[0];
                state.hiddenEntriesToExcludeModal.content[currentDashboardUuid] = hiddenEntriesToExclude;
            }

            state.tempDownloadArgs.entriesToExclude = state.tempDownloadArgs.entriesToExclude.concat(entriesToExclude);
            if (entryPeriod) {
                state.tempDownloadArgs.entryPeriod = entryPeriod;
            }
            if (destination) {
                state.tempDownloadArgs.destination = destination;
            }
            if (entryType) {
                state.tempDownloadArgs.entryType = entryType;
            }
            // now wipe dashboardData, displayConfigs, getDataArgs, and displayConfigsArgs and switch to FETCH stage with incremented dashboardUuidsToProcess
            // unless we're at the last dashboard
            // if at last dashboard, set stage to DOWNLOAD
            if (state.dashboardUuidsToProcess.length > 1) {
                console.log(`dashboardUuidsToProcess.length > 1`)
                console.log(`wiping dashboardData, displayConfigs, getDataArgs, and displayConfigsArgs. Shifting dashboardUuidsToProcess, and setting stage to FETCH`)
                state.dashboardData = null;
                state.displayConfigs = [];
                state.getDataArgs = null;
                state.displayConfigsArgs = [];
                state.dashboardUuidsToProcess.shift();
                state.stage = stages.FETCH;
            } else {
                console.log(`dashboardUuidsToProcess.length === 1`)
                // if at last dashboard and tempDownloadArgs.entriesToExclude + entriesToExclude length > 0, show modal - otherwise set stage to DOWNLOAD
                if (Object.values(state.hiddenEntriesToExcludeModal.content).flat().concat(hiddenEntriesToExclude).length > 0) {
                    console.log(`hidden entries to exclude present -> showing modal`)
                    state.hiddenEntriesToExcludeModal.show = true;
                } else {
                    console.log(`no entries to exclude -> setting stage to DOWNLOAD`)
                    state.stage = stages.DOWNLOAD;
                }
            }
        },
        acceptExcludedEntries: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> acceptExcludedEntries`)
            state.hiddenEntriesToExcludeModal.show = false;
            state.stage = stages.DOWNLOAD;
        },
        resetDownloadJeCsvSlice: (state, action) => {
            console.log(`dashboardDownloadJeCsvSlice -> resetDownloadJeCsvSlice`)
            return initialState;
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    initializeProcess,
    setAppsSubscriptions,
    setGetDataArgs,
    setDashboardData,
    setDisplayConfigsArgs,
    setDisplayConfig,
    processDashboard,
    setProcessedDashboard,
    acceptExcludedEntries,
    resetDownloadJeCsvSlice,
} = dashboardDownloadJeCsvSlice.actions;

export default dashboardDownloadJeCsvSlice.reducer;