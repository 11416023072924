import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Constants from "../../../../../../Constants";
import { generateDisplayConfigUpdateArgs, generateDisplayConfigUpdateNotification, generateDisplayConfigOverwriteColumnNotification } from "../../DisplayConfigUpdateUtils";
import { setAlertNotificationArgs } from "../../../../../../store/alertsNotificationSlice";
import { updateConfig, dashboardConfigsSelectors, config_options, overwriteColumn_fields, linkedMultiAppConfig_fields } from "../../../../../../store/dashboardConfigsSlice";

const getAllMultiAppConfigsArgs = () => {
    return {
        url: Constants.SERVER_GET_NON_SYSADMIN_MULTI_APP_CONFIGS_URL
    }
}

const getLinkedMultiAppConfigInstanceArgs = (configName, companyUuid) => {
    return {
        url: companyUuid ? Constants.SERVER_SYSADMIN_LINKED_MULTI_APP_CONFIG_URL + companyUuid : Constants.SERVER_LINKED_MULTI_APP_CONFIG_URL,
        method: "POST",
        body: JSON.stringify({ configName })
    }
}

const getUpdateLinkedMultiAppConfigInstanceArgs = (multiAppConfigUuid, updatedValStr, companyUuid) => {
    const body = {
        multiAppConfigUuid: multiAppConfigUuid,
        val: updatedValStr
    }
    return {
        url: companyUuid ? Constants.SERVER_SYSADMIN_UPDATE_MULTI_APP_CONFIG_INSTANCE_URL + companyUuid : Constants.SERVER_UPDATE_MULTI_APP_CONFIG_INSTANCE_URL,
        method: "POST",
        body: JSON.stringify(body)
    }
}

const getPrimaryKey = (row, primaryKeyFieldList, overridePrimaryKeyColumn) => {
    if (overridePrimaryKeyColumn) {
        const output = row[overridePrimaryKeyColumn];
        if (output) {
            return output;
        } else {
            console.error("overridePrimaryKeyColumn is set to true, but the overridePrimaryKeyColumn value is not found in the row");
        }
    }
    return primaryKeyFieldList.map((field) => row[field]).join("");
    // this should be a single field value for linkedMultiAppConfigInstance - won't work with composite keys
}

const defaultSaveOverwriteColumnState = {
    nonSysAdminMultiAppConfigsArgs: null,
    linkedMultiAppConfig: null,
    linkedMultiAppConfigInstanceArgs: null,
    updateLinkedMultiAppConfigInstanceArgs: null,
    newValue: "",
    // loading state
    isLoading: false,
    isError: false,
    errorMessage: ""
}

export const useSaveOverwriteColumn = (
    field,
    configName,
    displayConfigUuid,
    row,
    overwriteConfig,
    displayConfigOptions,
    closeModal
) => {
    const dispatch = useDispatch();
    const isLocked = useSelector(state => state.dashboard.isLocked);
    const dashboardUuid = useSelector(state => state.dashboard.uuid);
    const user = useSelector((state) => state.role.name);
    const isInternal = useSelector((state) => state.role.isInternal);
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    const entryPeriod = useSelector(state => state.dashboard.entryPeriod);

    const primaryKeyFieldList = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.primaryKeyFieldList);
    const linkedTableForeignFieldMap = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.linkedTableForeignFieldMap);

    const [saveOverwriteColumnState, setSaveOverwriteColumnState] = useState({...defaultSaveOverwriteColumnState});
    
    const saveOverwriteColumn = (newValue) => {
        console.log("saveOverwriteColumn invoked with newValue", newValue)
        console.log("overwriteConfig", overwriteConfig);
        console.log("displayConfigOptions", displayConfigOptions);
        console.log("configName", configName);
        //(newValue, configName, displayConfigUuid, entryPeriod, primaryKeyFieldList, linkedTableForeignFieldMap
        const linkedMultiAppConfig = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig] ?? "";
        if (linkedMultiAppConfig) {
            console.log("linkedMultiAppConfig found => get all nonSysAdminMultiAppConfigs");
            // get all nonSysAdminMultiAppConfigs
            const nonSysAdminMultiAppConfigsArgs = getAllMultiAppConfigsArgs();
            setSaveOverwriteColumnState({
                ...saveOverwriteColumnState,
                nonSysAdminMultiAppConfigsArgs,
                newValue,
                isLoading: true
            });
        } else {
            console.log("no linkedMultiAppConfig => update displayConfig");
            updateDisplayConfig(newValue);
        }
    }
    
    const onNonSysAdminMultiAppConfigsReturn = (res) => {
        console.log("onNonSysAdminMultiAppConfigsReturn invoked -- res", res);
        const { multiAppConfigs } = res;
        const linkedMultiAppConfig = multiAppConfigs.find(
            (config) => config.configName === configName
        );
        console.log("linkedMultiAppConfig", linkedMultiAppConfig);
        // then the handler for that function should get the linkedMultiAppConfig instance
        console.log(`getting linkedMultiAppConfig instance for configName ${configName}`);
        const linkedMultiAppConfigInstanceArgs = getLinkedMultiAppConfigInstanceArgs(configName, companyUuid);

        setSaveOverwriteColumnState({
            ...saveOverwriteColumnState,
            nonSysAdminMultiAppConfigsArgs: null,
            linkedMultiAppConfig,
            linkedMultiAppConfigInstanceArgs
        });
    }

    const updateLinkedMultiAppConfigInstance = (res) => {
        // I need to update the linkedMultiAppConfig - that means taking the current instance body if it exists and updating the field with the new value
        /*
        linkedMultiAppConfig return:
        // assuming data = { multiAppConfigInstance: {...} }
        "multiAppConfigInstance": {
            "uuid": "maci_CWSD5NLALQQBBTJQUD7IKNIA3",
            "companyUuid": "comp_86FOF91CZHPW88SRGR9TYISRB",
            "name": "comp_86FOF91CZHPW88SRGR9TYISRB_if_manual__map_text__overwrite",
            "createdBy": "jack@awsdev_testsandbox.com",
            "multiAppConfigUuid": "macf_9NTFVQ28EX9R6SGXB276760B0",
            "configSource": "generic",
            "valStr": "{\n    \"test_field\": \"test_value\"\n}",
            "multiAppConfigType": "KEY_VALUE",
            "displayNote": null
        }
        payload for update:
        {
            "multiAppConfigUuid": "macf_9NTFVQ28EX9R6SGXB276760B0",
            "val": "{\n    \"test_field\": \"test_value\"\n}"
        }

        */
        console.log("updateLinkedMultiAppConfig invoked")
        const { multiAppConfigInstance } = res;
        const { newValue, linkedMultiAppConfig } = saveOverwriteColumnState;
        const { uuid } = linkedMultiAppConfig;
        let cleanedNewValue = newValue;
        // if overwriteConfig.linkedMultiAppConfig.type is map_dropdown, we need to use newValue's corresponding dropdownOptions value
        if (overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.type] === "map_dropdown") {
            // get the dropdownOptions from the linkedMultiAppConfig
            const dropdownOptions = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.mapDropdownValues] ?? [];
            const dropdownDisplayValues = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.mapDropdownDisplayValues] ?? [];
            // find the index of the newValue in the dropdownOptions
            const newValueIndex = dropdownDisplayValues.findIndex((option) => option === newValue);
            // if newValueIndex is not -1, then we need to update the newValue with the dropdownDisplayValues
            if (newValueIndex !== -1) {
                cleanedNewValue = dropdownOptions[newValueIndex];
            }
        }
        // need to check overwriteConfig?.linkedMultiAppConfig?.overridePrimaryKeyColumn
        const overridePrimaryKeyColumn = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.overridePrimaryKeyColumn] ?? null;
        const primaryKey = getPrimaryKey(row, primaryKeyFieldList, overridePrimaryKeyColumn);
        if (multiAppConfigInstance) {
            // update existing multiAppConfigInstance
            const { valStr } = multiAppConfigInstance;
            const valStrObj = JSON.parse(valStr);
            valStrObj[primaryKey] = cleanedNewValue;
            console.log("updated valStrObj", valStrObj);
            const updatedValStr = JSON.stringify(valStrObj);
            const updateLinkedMultiAppConfigInstanceArgs = getUpdateLinkedMultiAppConfigInstanceArgs(uuid, updatedValStr, companyUuid);
            if (isLocked) {
                console.log(`isLocked: ${isLocked} => overwrite column update skipped`);
                console.log("updateLinkedMultiAppConfigInstanceArgs", updateLinkedMultiAppConfigInstanceArgs);
                setSaveOverwriteColumnState({...defaultSaveOverwriteColumnState});
                return;
            }
            setSaveOverwriteColumnState({
                ...saveOverwriteColumnState,
                linkedMultiAppConfigInstanceArgs: null,
                updateLinkedMultiAppConfigInstanceArgs: updateLinkedMultiAppConfigInstanceArgs
            });
        } else {
            // no multiAppConfigInstance -> create new one
            // still need multiAppConfigUuid
            const updatedValStr = JSON.stringify({ [primaryKey]: cleanedNewValue });
            const updateLinkedMultiAppConfigInstanceArgs = getUpdateLinkedMultiAppConfigInstanceArgs(uuid, updatedValStr, companyUuid);
            if (isLocked) {
                console.log(`isLocked: ${isLocked} => overwrite column update skipped`);
                console.log("updateLinkedMultiAppConfigInstanceArgs", updateLinkedMultiAppConfigInstanceArgs);
                setSaveOverwriteColumnState({...defaultSaveOverwriteColumnState});
                return;
            }
            setSaveOverwriteColumnState({
                ...saveOverwriteColumnState,
                linkedMultiAppConfigInstanceArgs: null,
                updateLinkedMultiAppConfigInstanceArgs: updateLinkedMultiAppConfigInstanceArgs
            });
        }
    }

    const onLinkedMultiAppConfigInstanceUpdate = () => {
        const { newValue } = saveOverwriteColumnState;
        updateDisplayConfig(newValue);
    }

    const updateDisplayConfig = (newValue) => {
        const isEntryPeriodSpecific = overwriteConfig[overwriteColumn_fields.isEntryPeriodSpecific];

        let newConfigValue;//oldConfigValue + field: newValue overwrite

        let cleanedNewValue = newValue;
        // if overwriteConfig.linkedMultiAppConfig.type is map_dropdown, we need to use newValue's corresponding dropdownOptions value
        if (overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.type] === "map_dropdown") {
            // get the dropdownOptions from the linkedMultiAppConfig
            const dropdownOptions = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.mapDropdownValues] ?? [];
            const dropdownDisplayValues = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.mapDropdownDisplayValues] ?? [];
            // find the index of the newValue in the dropdownOptions
            const newValueIndex = dropdownDisplayValues.findIndex((option) => option === newValue);
            // if newValueIndex is not -1, then we need to update the newValue with the dropdownDisplayValues
            if (newValueIndex !== -1) {
                cleanedNewValue = dropdownOptions[newValueIndex];
            }
        }

        if (displayConfigOptions?.[config_options.overwrite_column]?.optionValue) {
            newConfigValue = JSON.stringify({
                ...JSON.parse(displayConfigOptions[config_options.overwrite_column].optionValue),
                [field]: cleanedNewValue
            });
        } else {
            newConfigValue = JSON.stringify({
                [field]: cleanedNewValue
            });
        }
        
        const configUpdateArgs = generateDisplayConfigUpdateArgs(
            companyUuid,
            newConfigValue,
            config_options.overwrite_column,
            row,
            displayConfigUuid,
            isEntryPeriodSpecific ? entryPeriod : null,
            primaryKeyFieldList,
            linkedTableForeignFieldMap,
            null
        )
        if (isLocked) {
            console.log(`isLocked: ${isLocked} => overwrite column update skipped`);
            setSaveOverwriteColumnState({...defaultSaveOverwriteColumnState});
            return;
        }
        if (!isInternal && !companyUuid) {
            const overwriteNotificationConfig = {
                overwriteConfig,
                field,
                newValue
            }
            const configUpdateNotification = generateDisplayConfigOverwriteColumnNotification(newConfigValue, config_options.overwrite_column, row, user, displayConfigUuid, dashboardUuid, overwriteNotificationConfig);
            console.log("configUpdateNotification", configUpdateNotification);
            dispatch(setAlertNotificationArgs(configUpdateNotification));
        }
        dispatch(updateConfig(displayConfigUuid, configUpdateArgs));
        setSaveOverwriteColumnState({...defaultSaveOverwriteColumnState});
        closeModal();
    }

    const handleError = (error) => {
        console.error("linkedMultiAppConfig error", error);
        setSaveOverwriteColumnState({
            ...saveOverwriteColumnState,
            isLoading: false,
            isError: true,
            errorMessage: "There was an error updating the column. The team has been notified and a solution is in progress."
        });
    }

    const handleOnClose = () => {
        if (saveOverwriteColumnState.isLoading) {
            return;
        }
        setSaveOverwriteColumnState({...defaultSaveOverwriteColumnState});
        closeModal();
    }

    const { nonSysAdminMultiAppConfigsArgs, linkedMultiAppConfigInstanceArgs, updateLinkedMultiAppConfigInstanceArgs, isLoading, isError, errorMessage } = saveOverwriteColumnState;

    return {
        saveOverwriteColumn,
        nonSysAdminMultiAppConfigsArgs,// get all multiAppConfigs args
        onNonSysAdminMultiAppConfigsReturn,// get linkedMultiAppConfigInstance
        linkedMultiAppConfigInstanceArgs,// get linkedMultiAppConfigInstance args
        updateLinkedMultiAppConfigInstance,// update linkedMultiAppConfigInstance
        updateLinkedMultiAppConfigInstanceArgs,// update linkedMultiAppConfigInstance args
        onLinkedMultiAppConfigInstanceUpdate,// onLinkedMultiAppConfigInstanceUpdate
        handleError,
        handleOnClose,
        isLoading,
        isError,
        errorMessage
    }
}