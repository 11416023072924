import { useRef } from 'react';

/**
 * Custom hook to handle double-click detection
 * 
 * @param {Function} onDoubleClick - Callback function to execute on double click
 * @returns {Object} Click handler
 */
export const useDoubleClickHandler = (onDoubleClick) => {
  const clickTimeoutRef = useRef(null);
  const clickCountRef = useRef(0);

  /**
   * Handle click events and detect double clicks
   * @param {Event} event - Click event
   */
  const handleClick = (event) => {
    // Stop propagation to prevent DataGrid's default sorting
    event.stopPropagation();
    
    clickCountRef.current += 1;
    
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }
    
    clickTimeoutRef.current = setTimeout(() => {
      if (clickCountRef.current === 2) {
        onDoubleClick(event);
      }
      
      clickCountRef.current = 0;
      clickTimeoutRef.current = null;
    }, 500);
  };

  return {
    handleClick
  };
};