import React, { useState, useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

/**
 * Pure presentational component for column sorting button
 * 
 * @param {string} field - Column field name
 * @param {boolean} showOnHover - Whether hover state is active
 * @param {string|null} currentSortDirection - Current sort direction
 * @param {Function} onSort - Sort handler function
 * @returns {React.Component} Sort button component
 */
const HeaderSortButton = ({ field, showOnHover, currentSortDirection, onSort }) => {
  const [visible, setVisible] = useState(false);
  const timerRef = useRef(null);
  
  // Effect to handle delayed appearance of sort button
  useEffect(() => {
    if (showOnHover) {
      timerRef.current = setTimeout(() => {
        setVisible(true);
      }, 750);
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      setVisible(false);
    }
    
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [showOnHover]);
  
  // Show button if visible flag is true OR if column is already sorted
  if (!visible && !currentSortDirection) {
    return null;
  }
  
  // Determine icon to show based on current sort direction
  const showAscendingIcon = currentSortDirection === 'asc' || !currentSortDirection;

  return (
    <IconButton 
      size="small" 
      onClick={onSort}
      color={currentSortDirection ? 'primary' : 'default'}
      sx={{ padding: '0px', zIndex: 10, flexShrink: 0 }}
    >
      {showAscendingIcon ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )}
    </IconButton>
  );
};

export default HeaderSortButton;