import { useSelector, useDispatch } from "react-redux";
import { IconButton, Tooltip, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { setIsLocked } from "../../../../../store/dashboardSlice";

export const SysAdminLockButton = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    const isLocked = useSelector(state => state.dashboard.isLocked);

    if (!companyUuid) return null;

    return (
        <Grid item>
            <Tooltip title={isLocked ? "Unlock Dashboard" : "Lock Dashboard"}>
                <IconButton
                    onClick={() => dispatch(setIsLocked(!isLocked))}
                    size="small"
                    sx={{
                        color: theme.palette.primary.main
                    }}
                >
                    {isLocked ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

export default SysAdminLockButton;
