import React from 'react';
import { Tooltip, styled } from '@mui/material';

/**
 * Custom styled tooltip with maxWidth and text wrapping
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The children of the tooltip.
 * @returns {React.ReactNode} The tooltip component.
 */
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: 200
  },
}));

/**
 * HeaderTooltip component
 * @param {string} title - The title of the tooltip.
 * @param {React.ReactNode} children - The children of the tooltip.
 * @returns {React.ReactNode} The tooltip component.
 */
const HeaderTooltip = ({ title, children }) => {
  return (
    <CustomTooltip title={title} placement="top" arrow followCursor>
      {children}
    </CustomTooltip>
  );
};

export default HeaderTooltip;