import { useGridApiContext } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';

/**
 * Custom hook for handling auto column width functionality
 * 
 * @param {Array} rows - Table data rows
 * @returns {Object} Methods for calculating optimal column width
 */
export const useAutoColumnWidth = (rows) => {
  const apiRef = useGridApiContext();
  const theme = useTheme();

  /**
   * Measures the width of the longest string in pixels
   * @param {string[]} strings - Array of strings to measure
   * @param {string} fontStyle - CSS font specification
   * @returns {object} Longest string and its width in pixels
   */
  const measureStringWidth = (strings, fontStyle) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    
    context.font = fontStyle;
    
    let maxWidth = 0;
    let longestString = "";
    
    strings.forEach(str => {
      if (str === null || str === undefined) return;
      
      const strValue = String(str);
      
      // Check if string contains newline characters
      if (strValue.includes('\n')) {
        // Split the string by newlines and measure each line separately
        const lines = strValue.split('\n');
        
        lines.forEach(line => {
          const metrics = context.measureText(line);
          const width = metrics.width;
          
          if (width > maxWidth) {
            maxWidth = width;
            longestString = line;
          }
        });
      } else {
        // For strings without newlines, measure as before
        const metrics = context.measureText(strValue);
        const width = metrics.width;
        
        if (width > maxWidth) {
          maxWidth = width;
          longestString = strValue;
        }
      }
    });
    
    return {
      string: longestString,
      width: maxWidth,
      unit: "px"
    };
  };

  /**
   * Extracts column values from rows data
   * @param {string} field - Column field name
   * @returns {Array} Array of values for the specified column
   */
  const getColumnValues = (field) => {
    return rows.map(row => row[field]);
  };

  /**
   * Calculates the optimal width for a column based on its content
   * @param {string} field - Column field name
   * @param {string} headerName - Column header text
   * @returns {number} Optimal column width in pixels
   */
  const calculateOptimalWidth = (field, headerName) => {
    console.log("calculateOptimalWidth", field, headerName)
    const columnValues = getColumnValues(field);
    columnValues.push(headerName);

    // Get the default font family and size from theme
    let font = theme.typography.fontFamily;
    let fontSize = `${theme.typography.p.fontSize}`;

    // Get the actual font used in the grid
    const gridRootElement = apiRef.current.rootElementRef?.current;
    
    if (gridRootElement) {
        // First try to get style from a cell
        let cell = gridRootElement.querySelector('.MuiDataGrid-cell');
        
        // If no cell is found (empty table), try to get style from header
        if (!cell) {
            console.log("No cell found, trying to get style from header")
            cell = gridRootElement.querySelector('.MuiDataGrid-columnHeader');
        }
        
        if (cell) {
            console.log("Cell found, getting style")
            const computedStyle = window.getComputedStyle(cell);
            font = computedStyle.fontFamily;
            fontSize = computedStyle.fontSize;
            console.log("passed style will be", `${fontSize} ${font}`)
        } else {
            console.log("No cell or header found, using default style")
        }
    }
    
    const { string, width } = measureStringWidth(columnValues, `${fontSize} ${font}`);
    const cleanWidth = Math.ceil(width);
    
    // Add padding - more if the header is the longest element
    if (string === headerName) {
      return cleanWidth + 40; // More padding for header to account for sort button
    } else {
      return cleanWidth + 20; // Standard padding for content plus a little breathing room
    }
  };

  return {
    calculateOptimalWidth
  };
};