import Papa from 'papaparse';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getDataArgs, getDisplayConfigsArgs } from "../../store/dashboardSlice";
import {
    stages,
    setAppsSubscriptions,
    setGetDataArgs, 
    setDisplayConfigsArgs, 
    setDashboardData, 
    setDisplayConfig, 
    processDashboard,
    setProcessedDashboard,
    resetDownloadJeCsvSlice
} from "../../store/dashboardDownloadJeCsvSlice";
import { config_options } from "../../store/dashboardConfigsSlice";
import DirectRequest from "./DirectRequest";
import * as Constants from "../../Constants";
import { setAlertNotificationArgs } from "../../store/alertsNotificationSlice";
import { generateExportNotificationArgs } from "../../components/AlertFeed/FeedTableUtils";

//////////////////////////////// GET SUBSCRIPTIONS //////////////////////////////////
// if sysadmin (companyUuid) -> get company subscriptions / otherwise just use appsSubscriptions
const GetSubscriptions = () => {
    const dispatch = useDispatch();
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    const appsSubscriptions = useSelector(state => state.appsSubscriptions.list);
    const [appsSubscriptionsArgs, setAppsSubscriptionsArgs] = useState(null);

    useEffect(() => {
        if (!companyUuid) {
            dispatch(setAppsSubscriptions(appsSubscriptions));
        } else {
            // get company subscriptions
            setAppsSubscriptionsArgs({ url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + companyUuid });
        }
    }, [companyUuid, appsSubscriptions])

    const handleCompanySubscriptions = (res) => {
        dispatch(setAppsSubscriptions(res));
    }

    return (
        <DirectRequest
            requestArgs={appsSubscriptionsArgs}
            afterProcess={handleCompanySubscriptions}
            handleError={err => console.warn("DownloadJeCsvRequest -> GetSubscriptions -> server error:", err)}
            handleCatchError={err => console.warn("DownloadJeCsvRequest -> GetSubscriptions -> client error:", err)}
        />
    )
}
//////////////////////////////// END GET SUBSCRIPTIONS //////////////////////////////////

//////////////////////////////// FETCH DASHBOARDS //////////////////////////////////
const GetDashboardArgs = () => {
    const dispatch = useDispatch();
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    const dashboardUuid = useSelector(state => state.dashboardDownloadJeCsv.dashboardUuidsToProcess[0]);// this an appUuid (from module.displayApps)
    const appsSubscriptions = useSelector(state => state.dashboardDownloadJeCsv.appsSubscriptions);

    useEffect(() => {
        if (dashboardUuid) {
            const customerSubscriptionUuid = appsSubscriptions.find(app => app.developerAppUuid === dashboardUuid)?.uuid;
            // TODO: handle case where no customerSubscriptionUuid is found
            const tempArgs = getDataArgs(dashboardUuid, customerSubscriptionUuid, companyUuid);
            dispatch(setGetDataArgs(tempArgs));
        }
    }, [dashboardUuid, appsSubscriptions, companyUuid])

    return null;
}

const GetDashboard = () => {
    const dispatch = useDispatch();
    const getDataArgs = useSelector(state => state.dashboardDownloadJeCsv.getDataArgs);
    const handleDashboardData = (res) => {
        // pass to slice - will either generate displayConfigArgs or shift dashboardUuidsToProcess or if its the last dashboard, set stage to DOWNLOAD
        const dashboardJson = JSON.parse(res.dashboardJson);
        const tables = res.tables;
        const payload = { dashboardJson, tables };
        dispatch(setDashboardData(payload));
    }

    return (
        <DirectRequest
            requestArgs={getDataArgs}
            afterProcess={handleDashboardData}
            handleError={err => console.warn("DownloadJeCsvRequest -> GetDashboard -> server error:", err)}
            handleCatchError={err => console.warn("DownloadJeCsvRequest -> GetDashboard -> client error:", err)}
        />
    )
}

const GetDisplayConfigsArgs = () => {
    const dispatch = useDispatch();
    const dashboardUuid = useSelector(state => state.dashboardDownloadJeCsv.dashboardUuidsToProcess[0]);
    const dashboardData = useSelector(state => state.dashboardDownloadJeCsv.dashboardData);
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    useEffect(() => {
        if (dashboardData) {
            const { dashboardJson, tables } = dashboardData;
            // find entryPeriod from dashboardJson - will be sent w/ displayConfigArgs - it will have the same value for each component in the dashboardJson, so just get it once
            let entryPeriod = null;
            Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
                if (entryPeriod) return;
                if (component.createEntryConfig && component.createEntryConfig.entryPeriod) {
                    entryPeriod = component.createEntryConfig.entryPeriod;
                } else if (component.overwriteColumnConfig && component.overwriteColumnConfig.displayConfigEntryPeriod) {
                    entryPeriod = component.overwriteColumnConfig.displayConfigEntryPeriod;
                }
            })
            const tempDisplayConfigsArgs = getDisplayConfigsArgs(dashboardJson, dashboardUuid, companyUuid, entryPeriod);
            // set displayConfigsArgs -> will trigger GetDisplayConfigs
            dispatch(setDisplayConfigsArgs(tempDisplayConfigsArgs));
        }
    }, [dashboardData, dashboardUuid, companyUuid])

    return null;
}

const GetDisplayConfigs = () => {
    const dispatch = useDispatch();
    const displayConfigsArgs = useSelector(state => state.dashboardDownloadJeCsv.displayConfigsArgs);
    const handleDisplayConfigs = (res) => {
        if (displayConfigsArgs.length === 1) {
            // final display config for this dashboard -> start processing dashboard/displayConfigs to generate entriesToExclude
            dispatch(processDashboard(res.displayConfigTable));
        } else {
            // not final display config for this dashboard -> set current displayConfig (will shift displayConfigsArgs to get next displayConfig)
            dispatch(setDisplayConfig(res.displayConfigTable));
        }
    }

    return (
        <DirectRequest
            requestArgs={displayConfigsArgs[0] ?? null}
            afterProcess={handleDisplayConfigs}
            handleError={err => console.warn("DownloadJeCsvRequest -> GetDisplayConfigs -> server error:", err)}
            handleCatchError={err => console.warn("DownloadJeCsvRequest -> GetDisplayConfigs -> client error:", err)}
        />
    )
}


const FetchDashboard = () => {
    return (
        <>
            <GetDashboardArgs/>
            <GetDashboard/>
            <GetDisplayConfigsArgs/>
            <GetDisplayConfigs/>
        </>
    )
}
//////////////////////////////// END FETCH DASHBOARDS //////////////////////////////////

//////////////////////////////// PROCESS DASHBOARDS //////////////////////////////////
/* 
What we're making:
const tempBody = {
    entriesToExclude: entriesToExclude,
    destination: destination,
    entryType: entryType,
    entryPeriod: createEntryConfig.entryPeriod,
    developerAppUuid: dashboardUuid,
    moduleUuid: moduleUuid
}
*/
const ProcessDashboard = () => {
    const dispatch = useDispatch();
    const dashboardData = useSelector(state => state.dashboardDownloadJeCsv.dashboardData);
    const displayConfigs = useSelector(state => state.dashboardDownloadJeCsv.displayConfigs);

    const { dashboardJson, tables } = dashboardData;
    const entriesToExclude = [];
    const hiddenEntriesToExclude = [];// used to show a modal to the user to let them know that there are hidden entries that will be excluded from the download
    displayConfigs.forEach((displayConfig) => {
        // get each displayConfig
        // find it's objectName
        const objectName = displayConfig.objectName;
        const sourceTable = displayConfig.sourceTableName;
        // get component definition for objectName
        const component = dashboardJson.components[objectName];
        // get statusTrackerConfig
        const statusTrackerConfig = component.statusTrackerConfig;
        // ok, now loop through each displayConfigTableRows
        displayConfig.displayConfigTableRows.forEach((displayConfigTableRow) => {
            // if displayConfigTableRow.create_entry
            const createdEntry = displayConfigTableRow.displayConfigOptions[config_options.create_entry]?.optionValue;
            if (createdEntry) { 
                const table = tables[sourceTable];
                const columns = table.data[0];
                const rows = table.data.slice(1);
                let excluded = false;
                rows.forEach((row) => {
                    if (excluded) return;
                    // find matching table row
                    const matchingTableRow = Object.entries(displayConfigTableRow.primaryKeyValueMap).every(([primaryKey, value]) => row[columns.indexOf(primaryKey)] === value);
                    if (matchingTableRow) {
                        // check statusTrackerConfig.overrideColName of row -> if truthy and row.create_entry -> add row.create_entry to entriesToExclude
                        const overridden = row[columns.indexOf(statusTrackerConfig.overrideColName)];
                        if (overridden) {
                            console.log(`overridden row found for created entry ${createdEntry}`, row);
                            excluded = true;
                            entriesToExclude.push(createdEntry);
                        }
                        // check if row is hidden -> if so, add row.create_entry to entriesToExclude
                        const hidden = !!displayConfigTableRow.displayConfigOptions[config_options.hide_row]?.optionValue;
                        if (!overridden && hidden) {
                            console.log(`hidden row found for created entry ${createdEntry}`, row);
                            excluded = true;
                            entriesToExclude.push(createdEntry);
                            hiddenEntriesToExclude.push(createdEntry);
                        }
                    }
                })  
            }
        })
    })
    // great, now we have entries to exclude
    // construct rest of args - these are "global" to the dashboard 
    // 'destination' and 'entryType' are read from rows off one of the accrual tables
    // 'entryPeriod' is read from one of the createEntryConfigs
    let argsTable;
    let createEntryConfig;
    displayConfigs.forEach((displayConfig) => {
        if (argsTable) return;
        const tempArgsTable = tables[displayConfig.sourceTableName]?.data;
        // console.log("tempArgsTable", tempArgsTable);
        if (tempArgsTable && tempArgsTable.length > 1) {
            argsTable = tempArgsTable;
            createEntryConfig = dashboardJson.components[displayConfig.objectName].createEntryConfig;
        }
    })
    
    const destinationIndex = argsTable?.[0]?.indexOf(createEntryConfig?.destinationColumn) ?? -1;
    const destination = argsTable?.[1]?.[destinationIndex] ?? "";

    const entryTypeIndex = argsTable?.[0]?.indexOf(createEntryConfig?.entryTypeColumn) ?? -1;
    const entryType = argsTable?.[1]?.[entryTypeIndex] ?? "";

    const tempPayload = {
        entriesToExclude: entriesToExclude,
        entryPeriod: createEntryConfig?.entryPeriod ?? "",
        // hiddenEntriesToExclude is used to show a modal to the user to let them know that there are hidden entries that will be excluded from the download
        hiddenEntriesToExclude: hiddenEntriesToExclude
    }

    if (destination) {
        tempPayload.destination = destination;
    } else {
        console.warn("DownloadJeCsvRequest -> ProcessDashboard -> destination not found");
    }

    if (entryType) {
        tempPayload.entryType = entryType;
    } else {
        console.warn("DownloadJeCsvRequest -> ProcessDashboard -> entryType not found");
    }

    dispatch(setProcessedDashboard(tempPayload));
    return null;
}
//////////////////////////////// END PROCESS DASHBOARDS //////////////////////////////////

//////////////////////////////// DOWNLOAD CSV //////////////////////////////////
const DownloadCSV = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.role.name);
    const isInternal = useSelector(state => state.role.isInternal);

    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    
    const tempDownloadArgs = useSelector(state => state.dashboardDownloadJeCsv.tempDownloadArgs);
    const [downloadCsvArgs, setDownloadCsvArgs] = useState(null);
    
    useEffect(() => {
        if (tempDownloadArgs) {
            const tempArgs = {
                url: companyUuid ? Constants.SERVER_SYSADMIN_POST_GET_JOURNAL_ENTRIES_URL + companyUuid : Constants.SERVER_POST_GET_JOURNAL_ENTRIES_URL,
                method: "POST",
                body: JSON.stringify(tempDownloadArgs)
            }
            setDownloadCsvArgs(tempArgs);
        }
    }, [tempDownloadArgs, companyUuid])

    const handleDownloadCsv = (res) => {
        console.log(`DownloadJeCsvRequest -> DownloadCSV -> handleDownloadCsv -> res`, res);
        // res = array of arrays
        // use PapaParse to turn it into a csv and download it to client machine
        // Convert the array of arrays (res) into CSV format using PapaParse
        const csv = Papa.unparse(res.file);

        // Create a Blob from the CSV string
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        // Create a link element to trigger the file download
        const link = document.createElement('a');

        // Check if the download attribute is supported by the browser
        if (link.download !== undefined) {
            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Set the href attribute of the link to the Blob URL
            link.setAttribute('href', url);

            // Set the download attribute to the desired file name
            link.setAttribute('download', 'journal_entries.csv');// TODO: name of export??

            // Append the link to the document (required for Firefox)
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the document after the download starts
            document.body.removeChild(link);
        }
        setDownloadCsvArgs(null);
        // send notification
        if (!companyUuid && !isInternal) {
            dispatch(setAlertNotificationArgs(generateExportNotificationArgs('journal_entries.csv', user, csv)));
        }
        dispatch(resetDownloadJeCsvSlice());
    }

    return (
        <DirectRequest
            requestArgs={downloadCsvArgs}
            afterProcess={handleDownloadCsv}
            handleError={err => console.warn("DownloadJeCsvRequest -> DownloadCSV -> server error:", err)}
            handleCatchError={err => console.warn("DownloadJeCsvRequest -> DownloadCSV -> client error:", err)}
        />
    )
}
//////////////////////////////// END DOWNLOAD CSV //////////////////////////////////


export const DownloadJeCsvRequest = () => {
    const stage = useSelector(state => state.dashboardDownloadJeCsv.stage);

    if (stage === stages.INITIALIZE) {// app subscriptions needed for getting dashboard data
        return (
            <GetSubscriptions/>
        )
    }

    if (stage === stages.FETCH) {// get dashboard and displayConfigs
        return (
            <FetchDashboard/>
        )
    }

    if (stage === stages.PROCESS) {// process dashboard and displayConfigs
        return (
            <ProcessDashboard/>
        )
    }

    if (stage === stages.DOWNLOAD) {
        return (
            <DownloadCSV/>
        )
    }

    return null;
}

export default DownloadJeCsvRequest;