import {
  overwriteColumnConfig_fields,
  overwriteColumn_fields,
  linkedMultiAppConfig_fields
} from "../../../../../store/dashboardConfigsSlice";
import { useOverwriteColumn } from "./hooks/useOverwriteColumn";
import { useSaveOverwriteColumn } from "./hooks/useSaveOverwriteColumn";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import DirectRequest from "../../../../../API/requests/DirectRequest";
/**
 * Validation functions for different column types
 * Each function returns an object with:
 * - isValid: boolean indicating if the value is valid
 * - validationErrorMessage: error message to display if invalid
 */
const validationFunctions = {
  STRING: (value) => {
    // console.log('STRING validation', value);
    // String validation - check if not empty
    const valid = !!value;
    return {
      isValid: valid,
      validationErrorMessage: valid ? '' : <span style={{ fontSize: "8px" }}>'Value cannot be empty'</span>
    };
  },
  // Add more validation functions for other types as needed
  // INT: (value) => { ... },
  // FLOAT: (value) => { ... },
  // DATE: (value) => { ... },
};

const validateValue = (value, columnType, inputType) => {
  // // For boolean and dropdown types, we don't need validation as they always have valid values
  // if (inputType === 'map_boolean' || inputType === 'map_dropdown') {
  //   return { isValid: true, validationErrorMessage: '' };
  // }
  
  // // For text inputs, use the appropriate validation function based on column type
  // const validationFunction = validationFunctions[columnType] || validationFunctions.STRING;
  // return validationFunction(value);

  // Eric 3/18/25: disable validation for now - all values are valid
  return { isValid: true, validationErrorMessage: '' }
};

/**
 * Renders the appropriate input component based on the column configuration type
 */
const renderInputByType = (inputConfig) => {
  const { inputType, overwriteValue, handleChange, dropdownDisplayValues, validationResult } = inputConfig;
  const { isValid, validationErrorMessage } = validationResult;
  switch (inputType) {
    case 'map_boolean':
      return (
        <FormControlLabel
          control={
            <Switch
              checked={overwriteValue === true || overwriteValue === 1 || overwriteValue === '1'}
              onChange={(e) => handleChange(e.target.checked)}
              color="primary"
              size="small"
            />
          }
          label={overwriteValue === true || overwriteValue === 1 || overwriteValue === '1' ? "Yes" : "No"}
          style={{ marginTop: '16px' }}
        />
      );
    
    case 'map_dropdown':
      return (
        <FormControl 
          fullWidth 
          variant="standard" 
          style={{ marginTop: '16px' }}
        >
          <InputLabel id="overwrite-select-label">Select Value</InputLabel>
          <Select
            labelId="overwrite-select-label"
            value={overwriteValue || ''}
            onChange={(e) => handleChange(e.target.value)}
            label="Select value"
            size="small"
            variant="standard"
          >
            {dropdownDisplayValues && dropdownDisplayValues.map((displayValue) => (
              <MenuItem key={displayValue} value={displayValue}>
                {displayValue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    
    case 'map_text':
    default:
      return (
        <TextField
          autoFocus
          margin="dense"
          label="New value"
          fullWidth
          value={overwriteValue || ''}
          onChange={(e) => handleChange(e.target.value)}
          style={{ marginTop: '12px' }}
          size="small"
          variant="standard"
          error={!isValid}
          helperText={validationErrorMessage}
          InputLabelProps={{
            shrink: true
          }}
        />
      );
  }
};

/**
 * Modal component for overwriting cell values
 */
const OverwriteCellModal = ({ 
  isOpen, 
  onClose, 
  value, 
  overwriteValue, 
  handleChange,
  headerName,
  onSave,
  columnType,
  inputType,
  dropdownOptions,
  dropdownDisplayValues,
  isLoading,
  isError,
  errorMessage
}) => {

  // Validate the current value based on column type
  const validationResult = validateValue(overwriteValue, columnType, inputType);
  // console.log('validationResult', validationResult);
  const { isValid } = validationResult;
  const inputConfig = {
    inputType,
    overwriteValue,
    handleChange,
    dropdownOptions,
    dropdownDisplayValues,
    validationResult
  }
  
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="overwrite-cell-modal-title"
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 0,
          position: 'relative'
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
            padding: "4px"
          }}
        >
          <CloseIcon />
        </IconButton>
        
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Overwrite column
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: "12px", fontWeight: 450 }}>{headerName}</Typography>
          </Grid>
          <Grid item>
            {renderInputByType(inputConfig)}
          </Grid>
          <Grid item></Grid>
          <Grid item container justifyContent="center">
            <Button 
              onClick={onSave} 
              variant="contained"
              color="primary"
              disabled={!isValid || isLoading}
              size="small"
              startIcon={isLoading ? <CircularProgress size={"1rem"} /> : <CheckIcon />}
            >
              Save
            </Button>
          </Grid>
          {isError && (
            <Grid item container justifyContent="center">
              <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "red" }}>{errorMessage}</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Modal>
  );
};

/**
 * Component for rendering overwritable cells
 */
const overwriteCellStyle = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%'
}

const disabledOverwriteCellStyle = {
  ...overwriteCellStyle,
  cursor: 'default',
  pointerEvents: 'none',
  opacity: 0.5
}

const DisplayConfigOverwriteColumn = ({ 
  params, 
  overwriteColumnConfig,
  displayConfigUuid,
  sourceTable, 
  disableActions
}) => {
  const theme = useTheme();
  const { row, field, value, colDef } = params;
  const overwriteConfig = overwriteColumnConfig?.[overwriteColumnConfig_fields.overwriteColumnList]?.find(
    col => col[overwriteColumn_fields.fieldName] === field
  );
  // console.log("overwriteConfig", overwriteConfig);
  const configName = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.multiAppConfigName] ?? "";
  
  const {
    openModal,
    closeModal,
    modalOpen,
    overwriteValue,
    handleChange,
    headerName,
    columnType,
    inputType,
    dropdownOptions,  
    dropdownDisplayValues,
    displayConfigOptions,
    isOverwritten
  } = useOverwriteColumn(
    field, 
    value, 
    colDef, 
    sourceTable, 
    overwriteConfig, 
    displayConfigUuid, 
    row
  )

  const {
    saveOverwriteColumn,
    nonSysAdminMultiAppConfigsArgs,// get all multiAppConfigs args
    onNonSysAdminMultiAppConfigsReturn,// get linkedMultiAppConfigInstance
    linkedMultiAppConfigInstanceArgs,// get linkedMultiAppConfigInstance args
    updateLinkedMultiAppConfigInstance,// update linkedMultiAppConfigInstance
    updateLinkedMultiAppConfigInstanceArgs,// update linkedMultiAppConfigInstance args
    onLinkedMultiAppConfigInstanceUpdate,// onLinkedMultiAppConfigInstanceUpdate
    handleError,
    handleOnClose,
    isLoading,
    isError,
    errorMessage
  } = useSaveOverwriteColumn(
    field, 
    configName,
    displayConfigUuid,
    row,
    overwriteConfig,
    displayConfigOptions,
    closeModal
  )
  
  const saveChanges = () => {
    saveOverwriteColumn(overwriteValue);
  };
  
  return (
    <>
      <DirectRequest
        requestArgs={nonSysAdminMultiAppConfigsArgs}
        afterProcess={onNonSysAdminMultiAppConfigsReturn}
        handleError={handleError}
        handleCatchError={handleError}
      />
      <DirectRequest
        requestArgs={linkedMultiAppConfigInstanceArgs}
        afterProcess={updateLinkedMultiAppConfigInstance}
        handleError={handleError}
        handleCatchError={handleError}
      />
      <DirectRequest
        requestArgs={updateLinkedMultiAppConfigInstanceArgs}
        afterProcess={onLinkedMultiAppConfigInstanceUpdate}
        handleError={handleError}
        handleCatchError={handleError}
      />
      <div 
        className="editable-cell"
        style={disableActions ? disabledOverwriteCellStyle : overwriteCellStyle}
        onClick={openModal}
      >
        <Typography variant="p" sx={{position: "relative"}}>
          {value}
          {isOverwritten && (
            <EditIcon 
              style={{
                fontSize: '12px', 
                color: `${theme.palette.primary.main}`,
                position: "absolute",
                top: "3",
                right: "-14"
              }} 
            />
          )}
        </Typography>
      </div>
      
      <style jsx>{`
        .editable-cell:hover {
          border: 1px solid ${theme.palette.primary.main};
        }
      `}</style>
      
      <OverwriteCellModal
        isOpen={modalOpen}
        onClose={handleOnClose}
        value={value}
        overwriteValue={overwriteValue}
        handleChange={handleChange}
        headerName={headerName}
        onSave={saveChanges}
        columnType={columnType}
        inputType={inputType}
        dropdownOptions={dropdownOptions}
        dropdownDisplayValues={dropdownDisplayValues}
        isLoading={isLoading}
        isError={isError}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default DisplayConfigOverwriteColumn;


