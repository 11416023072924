import * as Constants from '../../../../../../Constants';
import iso8601Timestamp from '../../../../../../helpers/iso8601Timestamp';

/**
 * @description
 * This function is used to send a notification to the team when the columns state is reset.
 * @param {string} user
 * @param {object} dashboardJson
 */
export const resetColumnsStateNotificationArgs = (user, dashboardJson) => {
    console.log("resetColumnsStateNotification", user, dashboardJson);
    const body = {
        "body": `${user} reset table settings for ${dashboardJson?.title} (${window.location.origin} - ${iso8601Timestamp()})`,
        "subject": `${user} reset table settings for ${dashboardJson?.title} (${window.location.origin} - ${iso8601Timestamp()})`,
        "from": "noreply@wiselayer.com",
        "toEmail": Constants.notificationList.join(","),
        "sendHTML": true
    }
    const args = {
        url: Constants.SERVER_SEND_EMAIL,
        method: "POST",
        body: JSON.stringify(body),
    }
    return args;
}