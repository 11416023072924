import { useState } from "react";
import * as Constants from "../../../../Constants"; 

export const databricksJobs_fields = {
    inProgress: "inProgress",
    queued: "queued",
    notReady: "notReady",
}

export const databricksJobs_fields_inProgress = {
    schema_path: "schema_path",
    developer_app_id: "developer_app_id",
    command_id: "command_id",
    company_id: "company_id",
    databricks_job_type: "databricks_job_type",
    company_uuid_table_name: "company_uuid_table_name",
    origin: "origin",
    source: "source",
    username: "username",
}

export const databricksJobs_fields_queued = {
    jobUuid: "jobUuid",
    source: "source",
    developerAppName: "developerAppName",
    developerAppUuid: "developerAppUuid",
    companyName: "companyName",
    companyUuid: "companyUuid",
    moduleId: "moduleId",
    parentAppIds: "parentAppIds",
    parentAppIdsSuccess: "parentAppIdsSuccess",
    parentAppIdsFailure: "parentAppIdsFailure",
    createdOn: "createdOn",
}

export const databricksJobs_fields_notReady = {
    jobUuid: "jobUuid",
    source: "source",
    developerAppName: "developerAppName",
    developerAppUuid: "developerAppUuid",
    companyName: "companyName",
    companyUuid: "companyUuid",
    moduleId: "moduleId",
    parentAppIds: "parentAppIds",
    parentAppIdsSuccess: "parentAppIdsSuccess",
    parentAppIdsFailure: "parentAppIdsFailure",
    createdOn: "createdOn",
}

const defaultDatabricksJobs = {
    inProgress: [],
    queued: [],
    notReady: [],
}

const jobsArgs = {
    url: Constants.SERVER_SYSADMIN_GET_DATABRICKS_JOB_RUNS_URL,
}

export const useDatabricksJobs = () => {
    const [databricksJobs, setDatabricksJobs] = useState(defaultDatabricksJobs);
    const [databricksJobsArgs, setDatabricksJobsArgs] = useState(jobsArgs);

    const handleDatabricksJobs = (res) => {
        setDatabricksJobs(res);
        setDatabricksJobsArgs(null);
    }

    const handleDatabricksJobsRefresh = () => {
        setDatabricksJobsArgs(jobsArgs);
    }

    return {
        databricksJobs,
        databricksJobsArgs,
        handleDatabricksJobs,
        handleDatabricksJobsRefresh,
    }
}

export default useDatabricksJobs;