import { useState } from "react";
import * as Constants from "../../../../Constants"; 

const reorderJobsArgs = {
    url: Constants.SERVER_SYSADMIN_POST_REORDER_DATABRICKS_JOB_RUNS_URL,
    method: "POST",
    // body: {jobUuids: []} -> need to pass body via function
}

const makeReorderJobsArgs = (jobUuid) => {
    return {
        ...reorderJobsArgs,
        body: JSON.stringify({jobUuids: [jobUuid]})
    }
}

const reorderModuleJobsArgs = {
    url: Constants.SERVER_SYSADMIN_POST_REORDER_MODULE_DATABRICKS_JOB_RUNS_URL,
    method: "POST",
    // body: {moduleUuids: []} -> need to pass body via function
}

const makeReorderModuleJobsArgs = (moduleUuid, companyUuid) => {
    const body = {moduleUuids: [moduleUuid]}
    if (companyUuid) {
        body.companyUuid = companyUuid;
    }
    return {
        ...reorderModuleJobsArgs,
        body: JSON.stringify(body)
    }
}

export const useModifyDatabricksJobs = (handleDatabricksJobsRefresh) => {
    const [modifyDatabricksJobsArgs, setModifyDatabricksJobsArgs] = useState(null);

    const handleReorderJobs = (jobUuid) => {
        setModifyDatabricksJobsArgs(makeReorderJobsArgs(jobUuid));
    }

    const handleReorderModuleJobs = (moduleUuid, companyUuid = null) => {
        setModifyDatabricksJobsArgs(makeReorderModuleJobsArgs(moduleUuid, companyUuid));
    }

    const handleSuccess = () => {
        setModifyDatabricksJobsArgs(null);
        handleDatabricksJobsRefresh();
    }

    return {
        modifyDatabricksJobsArgs,
        handleReorderJobs,
        handleReorderModuleJobs,
        handleSuccess
    }
}

export default useModifyDatabricksJobs;