import { useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';

/**
 * Custom hook to manage column sorting functionality
 * 
 * @param {string} field - Column field name
 * @returns {Object} Sorting state and handlers
 */
export const useColumnSorting = (field) => {
  const [showSortControls, setShowSortControls] = useState(false);
  const apiRef = useGridApiContext();

  /**
   * Gets the current sort direction for this column
   * @returns {string|null} Current sort direction (asc, desc, or null)
   */
  const getCurrentSortDirection = () => {
    if (!apiRef || !apiRef.current || !apiRef.current.getSortModel) {
      return null;
    }
    
    const sortModel = apiRef.current.getSortModel();
    const currentSortItem = sortModel.find(item => item.field === field);
    return currentSortItem ? currentSortItem.sort : null;
  };

  /**
   * Determines the next sort direction based on current state
   * @returns {string|null} Next sort direction (asc, desc, or null)
   */
  const getNextSortDirection = () => {
    const currentDirection = getCurrentSortDirection();
    
    if (!currentDirection) {
      return 'asc';
    }
    if (currentDirection === 'asc') {
      return 'desc';
    }
    if (currentDirection === 'desc') {
      return null;
    }
  };

  /**
   * Handles the sort action
   * @param {Event} event - Click event
   */
  const handleSort = (event) => {
    event.stopPropagation();
    
    if (!apiRef || !apiRef.current || !apiRef.current.setSortModel || !apiRef.current.getSortModel) {
      console.warn("DataGrid apiRef is not defined");
      return;
    }
    
    const nextDirection = getNextSortDirection();
    const sortModel = apiRef.current.getSortModel();
    
    if (nextDirection === null) {
      // Remove this field from sort model
      const filteredSortModel = sortModel.filter(item => item.field !== field);
      apiRef.current.setSortModel(filteredSortModel);
    } else {
      // Check if field is already in sortModel
      const fieldIndex = sortModel.findIndex(item => item.field === field);
      
      if (fieldIndex === -1) {
        // Add new sort condition
        apiRef.current.setSortModel([...sortModel, { field, sort: nextDirection }]);
      } else {
        // Update existing sort condition
        const updatedSortModel = sortModel.map(item => {
          if (item.field === field) {
            return { field, sort: nextDirection };
          }
          return item;
        });
        apiRef.current.setSortModel(updatedSortModel);
      }
    }
  };

  return {
    showSortControls,
    setShowSortControls,
    currentSortDirection: getCurrentSortDirection(),
    handleSort
  };
};