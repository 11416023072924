import { Autocomplete, TextField } from "@mui/material";
import { form_fields } from "./createEntryForm";

const PostingPeriodInput = ({ formState, setFormState }) => {
  return (
    <Autocomplete
      sx={{ width: "100%", mb: 2 }}
      value={formState.postingPeriod || Object.keys(formState[form_fields.periodNameToIdMap])[0]}
      disableClearable
      clearOnEscape={false}
      options={Object.keys(formState[form_fields.periodNameToIdMap])}
      onChange={(event, newValue) => {
        setFormState({
          ...formState,
          postingPeriod: newValue
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="standard"
        />
      )}
    />
  );
};

export default PostingPeriodInput; 