// needs to hold modal state
import { useSelector } from "react-redux";
import { useState } from "react";
import * as Constants from "../../../../../../Constants";
import { config_options, overwriteColumn_fields, linkedMultiAppConfig_fields, dashboardConfigsSelectors } from "../../../../../../store/dashboardConfigsSlice";

export const useOverwriteColumn = (field, value, colDef, sourceTable, overwriteConfig, displayConfigUuid, row) => {
    const headerName = colDef.headerName;
    const dashboardTables = useSelector(state => state.dashboard.tables);
    const columnType = dashboardTables?.[sourceTable]?.typeMap?.[field] ?? 'STRING';
    const inputType = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.type] ?? 'map_text';
    const dropdownOptions = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.mapDropdownValues] ?? [];
    const dropdownDisplayValues = overwriteConfig?.[overwriteColumn_fields.linkedMultiAppConfig]?.[linkedMultiAppConfig_fields.mapDropdownDisplayValues] ?? [];
    
    // Get access to the Redux state to find the displayConfigTableRow's overwrite_column displayConfigOption.optionValue for this field
    const displayConfigTableRows = useSelector((state) => dashboardConfigsSelectors.selectById(state, displayConfigUuid)?.displayConfigTableRows);
    const displayConfigTableRow = displayConfigTableRows?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap).every(([field, value]) => row[field] === value));
    const displayConfigOptions = displayConfigTableRow?.displayConfigOptions;
    const isOverwritten = !!(displayConfigOptions?.[config_options.overwrite_column]?.optionValue?.[field]) ?? false;
    
    const [state, setState] = useState({
        modalOpen: false,
        overwriteValue: value,
    })

    const openModal = () => {
        setState({...state, modalOpen: true, overwriteValue: value});
    }

    const closeModal = () => {
        setState({...state, modalOpen: false, overwriteValue: value});
    }

    const handleChange = (newValue) => {
        setState({...state, overwriteValue: newValue});
    }
    
    const { modalOpen, overwriteValue } = state;

    return {
        openModal,
        closeModal,
        modalOpen,
        handleChange,
        overwriteValue,
        headerName,
        columnType,
        inputType,
        dropdownOptions,
        dropdownDisplayValues,
        displayConfigOptions,
        isOverwritten
    }
}